package com.siriusxm.pia.views.unifiedaggregator.styles

import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*

/**
 * Some aggregator animation primitives to use
 */
object AggregatorAnimation : StyleSheet() {
    // Define keyframes for a full spin.
    @OptIn(ExperimentalComposeWebApi::class)
    val spinKeyframes by keyframes {
        from {
            transform {
                rotate(0.deg)
            }
        }
        to {
            transform {
                rotate(360.deg)
            }
        }
    }

    val spin by style {
        animation(spinKeyframes) {
            duration(1.s)
            timingFunction(AnimationTimingFunction.Linear)
            iterationCount(10000)
        }
    }
}