package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.*
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.isTypedEntity
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.unifiedaggregator.relationships.entityRelationships
import contentingestion.aggregator.*
import contentingestion.unifiedmodel.EntityType
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import kotlinx.browser.window
import kotlinx.coroutines.async
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.offsetAt
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.seconds

val accessControllable = listOf<String>() // listOf("league", "team", "show", "show-podcast")
val colorEditable = listOf("channel-linear", "channel-xtra", "team")

@Composable
fun aggregatorEntity(context: AggregatorService, id: String) {
    var entities by remember { mutableStateOf<List<Entity>?>(null) }
    var incoming by remember { mutableStateOf<IncomingEntityResult?>(null) }
    var outgoing by remember { mutableStateOf<JsonObject?>(null) }

    var typedEntityId by remember { mutableStateOf<String?>(null) }
    var atlasId by remember { mutableStateOf<String?>(null) }

    var hasEnhancers by remember { mutableStateOf(false) }

    val coroutineScope = rememberCoroutineScope()

    val datadogBaseUrl = context.context.configuration.datadogLookupAtlasBusById
    val allowRePublish = true // we want everyone to be able to republish a single item

    fun loadEntity(id: String) {
        coroutineScope.launch {
            entities = null

            try {
                val mapping = context.api.lookupSourceId(id)
                atlasId = mapping.atlasId
                typedEntityId = mapping.sourceId
            } catch (e: Exception) {
                atlasId = null
                typedEntityId = null
            }

            val loadedEntities = context.api.fetchEntityById(typedEntityId ?: id, "1").asEntities()
            val default = loadedEntities.maxByOrNull { it.version }

            val incomingJob =
                if (default != null) {
                    async {
                        context.api.incomingEntity(
                            default.id, default.type, default.version,
                            "base,partials,scheduled"
                        )
                    }
                } else null

            val outgoingJob = if (default != null) {
                async { context.api.outgoing(default.id, default.type, default.version) }
            } else {
                null
            }

            hasEnhancers = context.entityAugmenters.isNotEmpty()
            incoming = incomingJob?.await()
            outgoing = outgoingJob?.await()
            entities = loadedEntities
        }

    }

    fun reloadEntity(id: String) {
        coroutineScope.launch {
            // reset the state and reload
            entities = null
            incoming = null
            outgoing = null
            atlasId = null

            try {
                loadEntity(id)
            } catch (e: Exception) {
                console.log(e)
            }
        }
    }

    LaunchedEffect(id) {
        loadEntity(id)
    }

    val entity = entities?.firstOrNull()
    serviceContentView({
        title = entity?.label
        subTitle = entity?.type?.let {
            context.entityType(it)?.name
        }

        breadcrumbs {
            crumb("Aggregator", "aggregator")
            crumb("Entities", "aggregator/entities")
            val title = entities?.firstOrNull()?.label
            if (title != null) {
                crumb(title, null)
            }
        }

        if (!outgoing.isNullOrEmpty() && allowRePublish) {
            action {
                title = "Republish"
                primary = false
                showProgressOnAction = true
                action {
                    try {
                        outgoing?.let {
                            context.api.republish(
                                it.entityId!!,
                                it.entityType!!,
                                it.entityVersion!!,
                                updateTimestamp = true
                            )
                        }
                    } catch (t: Throwable) {
                        Application.notifications.showError("Republish failed: ${t.message}")
                    }
                }
            }
        }

    }) {
        if (entities?.isEmpty() == true) {
            messageBox(
                "No entity with id $id was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (entity == null) {
            spinner(size = Size.LARGE)
        } else {
            if (incoming != null) {
                val squareImage = entity.images[ImagePurpose.TILE]?.get(ImageAspectRatio.ASPECT_1X1)?.default

                box {
                    Div({
                        style {
                            display(DisplayStyle.Flex)
                        }
                    }) {
                        if (squareImage != null) {
                            Div({
                                style {
                                    marginRight(1.cssRem)
                                }
                            }) {
                                Img(squareImage.absoluteUrl(), attrs = {
                                    style {
                                        width(150.px)
                                    }
                                })
                            }
                        }
                        Div({
                            style {
                                flex(1)
                            }
                        }) {
                            detailGrid {
                                detail("ID", entity.id)
                                detail("Type") {
                                    A(href = "#aggregator/types/entities/${encodeURIComponent(entity.type)}") {
                                        Text(context.entityType(entity.type)?.name ?: entity.type)
                                    }
                                }
                                detail("Version", entity.version)
                            }

                            detailGrid {
                                detail("Data Sources") {
                                    val sources =
                                        listOfNotNull(incoming?.entity?.source) + (incoming?.partials?.mapNotNull { it.source }).orEmpty()
                                    val sourceProducers = sources.map { source ->
                                        context.producers.find { it.id == source } ?: ProducerDetails(source)
                                    }.distinctBy {
                                        it.id
                                    }.filter {
                                        it.id != "sxm:unified-aggregator:content-services-connector"
                                    }
                                    sourceProducers.forEach { producer ->
                                        Div {
                                            if (producer.name != null) {
                                                A(href = "#aggregator/producers/${encodeURIComponent(producer.id)}") {
                                                    Text(producer.name!!)
                                                }
                                            } else {
                                                Text(producer.name ?: producer.id)
                                            }
                                        }
                                    }
                                }

                                detail("Status") {
                                    val removedTs = incoming?.entity?.data?.getString("_removed")?.let {
                                        Instant.parse(it)
                                    }
                                    if (removedTs != null) {
                                        Text("Removed")
                                    } else {
                                        val controls = (outgoing?.get("accessControls")?.jsonObject?.get("default")
                                            ?: outgoing?.get("accessControl"))?.jsonObject?.let {
                                            Json.decodeFromJsonElement(EntityAccessControl.serializer(), it)
                                        }
                                        accessControlRenderer(controls)
                                    }
                                }

                                incoming?.entity?.ts?.let {
                                    detail("Updated") {
                                        Text(it.toLocalDateTimeString(true))
                                    }
                                }

                                if (outgoing != null) {
                                    detail("Links") {
                                        Div {
                                            val url =
                                                "https://siriusxm.com/player/${entity.type}/${entity.name?.slugify() ?: "slug"}/${outgoing?.entityId}"
                                            A(href = url, {
                                                target(ATarget.Blank)
                                                title("Open this entity in the SXM web player")
                                            }) {
                                                Img(src = "images/sxm-logo-blue.png", "SiriusXM") {
                                                    style {
                                                        width(20.px)
                                                        property("vertical-align", "middle")
                                                        marginRight(3.px)
                                                    }
                                                }
                                                Text("SiriusXM Web Player")
                                            }
                                        }
                                        if (entity.type == EntityType.CHANNEL_LINEAR.name || entity.type == EntityType.CHANNEL_XTRA.name) {
                                            Div {
                                                A(href = "#channelguide/channels/${atlasId}") {
                                                    icon("lists") {
                                                        size = IconSize.SMALL
                                                        style {
                                                            width(23.px)
                                                        }
                                                    }
                                                    Text("Channel Guide")
                                                }
                                            }
                                        }

                                        Div {
                                            val ddUrl = datadogBaseUrl.replace("{{ATLAS_ID}}", atlasId!!)
                                            A(href = ddUrl, {
                                                target(ATarget.Blank)
                                                title("The Datadog dashboard for this entity")
                                            }) {
                                                Img(src = "images/dd-logo-rgb.png", "DataDog") {
                                                    style {
                                                        width(20.px)
                                                        property("vertical-align", "middle")
                                                        marginRight(3.px)
                                                    }
                                                }
                                                Text("Datadog")
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            tabView {
                if (outgoing != null) {
                    tab("Atlas") {
                        jsonView(outgoing!!)
                    }
                }
                tab("Catalog (Internal)") {
                    jsonView(entity.data)
                }
                if (incoming != null) {
                    tab("Incoming") {
                        entityIncomingView(context, incoming!!) {
                            reloadEntity(id)
                        }
                    }

                    // in the case that the entity is removed
                    val removedData = incoming?.entity?.data?.get("_removedData")
                    val removedObject = if (removedData == null || removedData == JsonNull) {
                        null
                    } else {
                        removedData.jsonObject
                    }
                    if (removedObject != null) {
                        tab("Removed") {
                            Div {
                                jsonView(removedObject)
                            }
                        }
                    }
                }
                tab("Events") {
                    entityEvents(context, entity.id)
                }

                if (accessControllable.contains(entity.type)) {
                    tab("Access Control") {
                        entityAccessControls(context, entity)
                    }
                }

                if (context.relationships.any {
                        it.objectType.name == entity.type || it.subjectType.name == entity.type
                    }) {
                    tab("Relationships") {
                        entityRelationships(context, entity)
                    }
                }
                if (colorEditable.contains(entity.type)) {
                    if (Application.viewer.contentEditor) {
                        tab("Color") {
                            entityColors(context, entity) {
                                reloadEntity(id)
                            }
                        }
                    }
                }

                if (hasEnhancers) {
                    val enhancers = context.entityAugmenters
                    enhancers.forEach {
                        val entityType = EntityType.fromName(entity.type)
                        if (it.canAugment(entityType)) {
                            tab(it.name) {
                                val entityId = typedEntityId
                                if (entityId != null) {
                                    it.render(entityType, entityId)
                                } else {
                                    // TODO: render some sort of error
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

fun String.timestamp(): Instant? {
    return try {
        Instant.parse(this)
    } catch (t: Throwable) {
        null
    }
}

@Composable
fun copyContentIcon(content: String? = null) {
    if (!content.isNullOrBlank()) {
        Span({ classes(AggregatorStyles.copyContentIcon) }) {
            icon("content_copy") {
                size = IconSize.TINY

                action {
                    window.navigator.clipboard.writeText(content)
                }
            }
        }
    }
}

@Composable
fun renderElement(entity: JsonElement?, element: JsonElement, hideEmptyOrNull: Boolean = false) {
    when (element) {
        is JsonPrimitive -> {
            Div({ classes(AggregatorStyles.copyableContentContainer) }) {
                val content = element.content
                if (content.isTypedEntity()) {
                    A(href = "#aggregator/entity/${content}") {
                        Text(element.content)
                    }
                } else {
                    val ts = content.timestamp()
                    if (ts != null) {
                        Text(ts.toLocalDateTimeString() + " ${TimeZone.currentSystemDefault().offsetAt(ts)}")
                    } else {
                        Text(element.content)
                    }
                }
                copyContentIcon(element.content)

                if (content.isHexColorValue()) {
                    colorSwatch(content.toCSSColorValue(), height = 25, width = 25)
                }
            }
        }

        is JsonArray -> {
            element.forEach { el ->
                Div {
                    renderElement(element, el, hideEmptyOrNull)
                }
            }
        }

        is JsonObject -> {
            jsonTable(entity, element, hideEmptyOrNull)
        }
    }
}

interface ExpandingTableConfig<T> {
    var items: List<T>
    fun collapsedContent(block: @Composable (T) -> Unit)
    fun expanedContent(block: @Composable (T) -> Unit)

    /**
     * If true, renders the collapsed content even when expanded. The expanded
     * callback will then be called.
     */
    var alwaysShowCollapsed: Boolean
}

@Composable
fun <T> expandingRowTable(block: ExpandingTableConfig<T>.() -> Unit) {
    var collapsed: @Composable (T) -> Unit = { }
    var expandedRender: @Composable (T) -> Unit = { }
    var tableItems: List<T>? = null
    var expanded by remember { mutableStateOf<Set<T>>(emptySet()) }
    var alwaysShowCollapsed = false

    object : ExpandingTableConfig<T> {
        override var items: List<T>
            get() = tableItems.orEmpty()
            set(value) {
                tableItems = value
            }

        override fun collapsedContent(block: @Composable (T) -> Unit) {
            collapsed = block
        }

        override fun expanedContent(block: @Composable (T) -> Unit) {
            expandedRender = block
        }

        override var alwaysShowCollapsed: Boolean
            get() = alwaysShowCollapsed
            set(value) {
                alwaysShowCollapsed = value
            }
    }.block()

    table<T> {
        items(tableItems)
        column {
            width = 20.px
            style {
                property("vertical-align", "top")
                paddingRight(0.px)
                paddingLeft(0.px)
            }
            content { item ->
                if (expanded.contains(item)) {
                    icon("arrow_drop_down") {
                        action {
                            expanded = expanded - item
                        }
                    }
                } else {
                    icon("arrow_right") {
                        action {
                            expanded = expanded + item
                        }
                    }
                }
            }
        }
        column {
            style {
                paddingLeft(0.px)
            }
            content { item ->
                if (expanded.contains(item)) {
                    if (alwaysShowCollapsed) {
                        collapsed(item)
                    }
                    expandedRender(item)
                } else {
                    collapsed(item)
                }
            }
        }
    }
}

@Composable
fun entityIncomingView(
    aggregatorContext: AggregatorService,
    incomingEntity: IncomingEntityResult,
    reloadCb: suspend () -> Unit
) {

    var showDeleteDialogPartialId by remember { mutableStateOf<String?>(null) }
    var showDeleteSchedulePartialId by remember { mutableStateOf<String?>(null) }

    @Composable
    fun incomingItemSummary(label: String, source: String?) {
        Div({ style { margin(3.px, 0.px) } }) {
            Span({ style { fontWeight(700) } }) {
                Text(label)
            }

            Span {
                val producer = source?.let { aggregatorContext.producerById(it) }
                if (producer != null) {
                    Text(": ")
                    A(href = "#aggregator/producers/${encodeURIComponent(producer.id)}") {
                        Text(producer.name ?: producer.id)
                    }
                } else if (source != null) {
                    Text(": ")
                    Text(source)
                }
            }
        }
    }

    box({
        paddedContent = false
    }) {
        expandingRowTable<IncomingEvent> {
            items = listOfNotNull(incomingEntity.entity) + incomingEntity.partials.orEmpty()
            alwaysShowCollapsed = true

            collapsedContent { event ->
                if (event.partialId != null) {
                    val expiration = event.partialId.let { partialId ->
                        incomingEntity.scheduled?.filter { it.partial?.partialId == partialId }?.firstNotNullOfOrNull {
                            it.partial?.expirationTs
                        }
                    }
                    if (expiration != null) {
                        Div {
                            Text("Expires: ${expiration.toLocalDateTimeString()}")
                        }
                    }
                } else {
                    val expires = incomingEntity.scheduled?.firstNotNullOfOrNull {
                        it.update?.expirationTs
                    }

                    if (expires != null) {
                        Div {
                            Text("To be removed: ${expires.toLocalDateTimeString()}")
                        }
                    }
                }
                incomingItemSummary(event.partialId ?: "Base", event.source)
            }
            expanedContent { event ->

                val isPartial = event.partialId != null
                val id = event.partialId ?: "Base"
                Div {
                    jsonView(event.data)

                    // TODO: limit rights to those who are part of the producer group
                    if (Application.viewer.contentEditor) {
                        if (isPartial) {

                            Text("Remove this partial: ")
                            iconAction("delete") {
                                showDeleteDialogPartialId = id
                            }

                            if (showDeleteDialogPartialId == id) {
                                val entityId = event.data.getString("id")
                                deleteConfirmationDialog("Partial \"$id\" for $entityId",
                                    onCancelCb = {
                                        showDeleteDialogPartialId = null
                                    },
                                    onDeleteCb = {
                                        removePartial(aggregatorContext, id, incomingEntity)
                                        // we need to give time for the partial remove to take effect
                                        delay(3.seconds)
                                        reloadCb()
                                    }
                                )
                            }
                        }
                    }
                }
            }
        }
    }

    val scheduled = incomingEntity.scheduled?.filter {
        val publishTs = it.update?.publishTs ?: it.partial?.publishTs
        publishTs != null && publishTs > Clock.System.now()
    }.orEmpty()
    if (scheduled.isNotEmpty()) {
        box({
            title = "Scheduled"
            paddedContent = false
        }) {
            expandingRowTable<ScheduledContentUpdate> {
                items = scheduled
                alwaysShowCollapsed = true
                collapsedContent {
                    (it.partial?.publishTs ?: it.update?.publishTs)?.let {
                        Div {
                            Text(it.toLocalDateTimeString())
                        }
                    }

                    incomingItemSummary(it.partial?.partialId ?: "Base", it.source)
                }

                expanedContent {
                    Div {
                        val data = it.partial?.modification?.jsonObject
                            ?: it.update?.entity?.jsonObject
                            ?: buildJsonObject { }

                        val filtered = data.filterKeys {
                            it !in listOf("id", "type", "version")
                        }.let {
                            JsonObject(it)
                        }
                        jsonView(filtered)

                        // TODO: limit rights to those who are part of the producer group
                        if (Application.viewer.contentEditor) {
                            val partialId = it.partial?.partialId
                            val timestamp =  (it.partial?.publishTs ?: it.partial?.expirationTs)?.toString()
                            if (partialId != null && timestamp != null) {

                                Text("Remove this scheduled partial: ")
                                iconAction("delete") {
                                    showDeleteSchedulePartialId = partialId + "_" + timestamp
                                }

                                if (showDeleteSchedulePartialId == (partialId + "_" + timestamp)) {
                                    val entityId = data.getString("id")
                                    deleteConfirmationDialog("Scheduled Partial \"$partialId\" for $entityId",
                                        onCancelCb = {
                                            showDeleteSchedulePartialId = null
                                        },
                                        onDeleteCb = {
                                            removeScheduledPartial(
                                                aggregatorContext,
                                                partialId,
                                                incomingEntity,
                                                timestamp
                                            )
                                            // we need to give time for the partial remove to take effect
                                            delay(3.seconds)
                                            reloadCb()
                                        }
                                    )
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


private suspend fun removePartial(
    aggregatorContext: AggregatorService,
    id: String,
    incomingEntity: IncomingEntityResult
) {

    val viewerEmail = Application.viewer.email
    val entity = incomingEntity.entity

    val entityId = entity.data.getString("id")
    val entityType = entity.data.getString("type")
    val entityVersion = entity.data.getString("version")

    val partialUpdate = PartialUpdate(
        type = ContentUpdateType.REMOVE,
        timestamp = Clock.System.now(),
        partialId = id,
        modification = buildJsonObject {
            put("id", entityId)
            put("type", entityType)
            put("version", entityVersion)
        },
        auditContext = viewerEmail?.let {
            AuditContext(viewerEmail)
        }
    )

    aggregatorContext.api.partialUpdate(
        partialUpdate
    )

    aggregatorContext.context.info(
        "Deleted partialId: $id " +
                "for entity: ${incomingEntity.entity.data.getString("id")}.  " +
                "If the app doesn't reload, you may need to refresh the page."
    )
}

private suspend fun removeScheduledPartial(
    aggregatorContext: AggregatorService,
    partialId: String,
    incomingEntity: IncomingEntityResult,
    timestamp: String
) {
    val entity = incomingEntity.entity

    val entityId = entity.data.getString("id")
    val entityType = entity.data.getString("type")
    val entityVersion = entity.data.getString("version")


    if (entityId != null && entityType != null && entityVersion != null) {
        // We may need to escape
        aggregatorContext.api.deleteScheduledPartial(entityId, entityType, entityVersion, partialId, timestamp)

        aggregatorContext.context.info(
            "Deleted scheduled partialId: $partialId " +
                    "for entity: $entityId with timestamp $timestamp.  " +
                    "If the app doesn't reload, you may need to refresh the page."
        )
    } else {
        aggregatorContext.context.showError("Unable to delete Schedule partial.")
    }
}



/**
 * Get the absolute URL of an image, using the base URL from configuration.
 */
fun Image.absoluteUrl(): String {
    return "${Application.configuration.atlasImageServerBaseUrl}${url}"
}

/**
 * Convert a string to a slug.
 */
private fun String?.slugify(): String? {
    return (this ?: return null)
        .lowercase() // Convert to lowercase
        .replace(Regex("[^a-z0-9\\s-]"), "") // Remove non-alphanumeric characters (except spaces and hyphens)
        .replace(Regex("\\s+"), "-") // Replace spaces with hyphens
        .replace(Regex("-+"), "-") // Replace multiple hyphens with a single hyphen
        .trim('-') // Trim leading and trailing hyphens
}
