package com.siriusxm.pia.views.podcasts.utils

import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime

/**
 * Format given utc string into "yyyy month dd" i.e. `2025 Jan 01`
 */
fun formatUtcDate(utcDateString: String): String {
    val instant = Instant.parse(utcDateString)
    val localDateTime = instant.toLocalDateTime(TimeZone.UTC)

    // Extract year, month abbreviation (first 3 letters), and day
    val year = localDateTime.year
    val month = localDateTime.month.name.lowercase().replaceFirstChar { it.uppercase() }.take(3)
    val day = localDateTime.dayOfMonth

    return "$year $month $day"
}

/**
 * Podcast show types
 */
enum class ShowType {
    Free,
    Freemium,
    Premium
}

const val stitcherShowUrlPrefix = "https://www.stitcher.com/show/"
val emailRegex = "^[\\w.-]+@([\\w-]+\\.)+[\\w-]{2,4}\$".toRegex()
