package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.Composable
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.ResizeParams
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.unifiedcontent.getString
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * Render an entity list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun entityListItem(entity: Entity, includeLink: Boolean, includeId: Boolean = false, includeDate: Boolean = false, onClick: (() -> Unit)? = null) {
    @Composable
    fun entityContent() {
        Div({
            classes(AggregatorStyles.entityListItem)
        }) {
            Div({
                style {
                    width(64.px)
                    height(64.px)
                }
            }) {
                val imageUrl = entity.thumbnail?.let {
                    it.resizedUrl(
                        resize = ResizeParams.constrainToWidth(64,
                            it.width, it.height))
                }


                if (imageUrl != null) {
                    Img(src = imageUrl) {
                        style {
                            width(100.percent)
                            display(DisplayStyle.InlineBlock)
                        }
                    }
                }
            }

            Div {
                Div({ classes(AggregatorStyles.entitySubInfo) }) {
                    Text(entity.type)
                }
                Text(entity.label)
                if (includeId) {
                    Div({ classes(AggregatorStyles.entitySubInfo) }) {
                        Text(entity.id)
                    }
                }
                if (includeDate && entity.hasTimestamp()) {
                    Div({ classes(AggregatorStyles.entitySubInfo) }) {
                        val timestamp = entity.getTimestamp()?.let {
                            val instant = Instant.parse(it)
                            instant.toLocalDateTimeString()
                        } ?: ""
                        Text(timestamp)
                    }
                }
            }
        }
    }

    if (includeLink) {
        A(href = "#aggregator/entity/${entity.id}") {
            entityContent()
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            entityContent()
        }
    }

}

fun Entity.getTimestamp(): String? {
    return this.data.getString("originalAirTimestamp")  ?:
            this.data.getString("startTimestamp") ?:
            this.data.getString("publicationDate")
}

fun Entity.hasTimestamp(): Boolean {
    return this.data.getString("originalAirTimestamp") != null ||
        this.data.getString("startTimestamp") != null ||
        this.data.getString("publicationDate") != null
}

/**
 * Render an entity list.
 */
@Composable
fun entityList(
    entities: List<Entity>,
    includeLinks: Boolean = true,
    includeIds: Boolean = false,
    includeTimestamp: Boolean = false,
    onClick: ((Entity) -> Unit)? = null
) {
    Div({ classes(AggregatorStyles.entityList) }) {
        entities.forEach { entity ->
            entityListItem(entity, includeLinks && onClick == null, includeIds, includeTimestamp) {
                onClick?.invoke(entity)
            }
        }
    }
}