package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.client.ShowMetricsData
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.boxMessage
import com.siriusxm.pia.components.boxSpinner
import com.siriusxm.pia.components.table
import kotlinx.coroutines.launch
import kotlinx.datetime.*
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Text

@Composable
fun showMetrics(showId: String) {
    var metrics by remember { mutableStateOf<List<ShowMetricsData>?>(null) }

    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(true) {
        coroutineScope.launch {
            val now = Clock.System.now()
            val nowDate = now.toLocalDateTime(TimeZone.UTC).date
            Podcasts.api.query {
                show(showId) {
                    metrics {
                        range(nowDate.minus(DatePeriod(days = 30)).toString(), nowDate.toString()) {
                            adds
                            removes
                            updates
                            start
                        }
                    }
                }

            }.show.metrics.range.let {
                metrics = it
            }
        }
    }

    box({
        paddedContent = metrics.isNullOrEmpty()
    }) {
        if (metrics == null) {
            boxSpinner()
        } else if (metrics.isNullOrEmpty()) {
            boxMessage("This show has no recent updates")
        } else {
            table<ShowMetricsData> {
                items(metrics.orEmpty())
                column("Date") {
                    content {
                        Text(it.start)
                    }
                }

                column("Adds") {
                    width = 105.px
                    content {
                        Text(it.adds.toString())
                    }
                }

                column("Removes") {
                    width = 105.px
                    content {
                        Text(it.removes.toString())
                    }
                }

                column("Updates") {
                    width = 105.px
                    content {
                        Text(it.updates.toString())
                    }
                }
            }
        }
    }
}