package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import com.siriusxm.pia.SXMUI
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours

/**
 * A time range that can be either relative or absolute. Either
 * relative or (start,end) must be defined.
 */
data class TimeRange(
    val relative: Duration? = null,
    val start: Instant? = null,
    val end: Instant? = null,
)

data class RelativeDuration(
    val name: String,
    val duration: Duration
)

/**
 * A list of relative durations for display. We can likely build something more intelligent
 * so that the name is implied from the duration.
 */
val defaultRelativeDurations = listOf(
    RelativeDuration("1h", 1.hours),
    RelativeDuration("3h", 3.hours),
    RelativeDuration("6h", 6.hours),
    RelativeDuration("12h", 12.hours),
    RelativeDuration("1d", 24.hours),
    RelativeDuration("1w", 7.days)
)

/**
 * A component for selecting a time range that includes options for relative periods.
 */
@Composable
fun timeRangeSelect(
    periods: List<RelativeDuration> = defaultRelativeDurations,
    selected: TimeRange? = null,
    onUpdate: (TimeRange) -> Unit
) {
    Style(TimeRangeStyles)
    Div({
        classes(TimeRangeStyles.timePeriodSelectionBox)
    }) {
        periods.forEach { availablePeriod ->
            Span({
                classes(TimeRangeStyles.timePeriod)
                if (selected?.relative == availablePeriod.duration) {
                    classes(TimeRangeStyles.timePeriodSelected)
                } else {
                    onClick {
                        it.preventDefault()
                        onUpdate(TimeRange(availablePeriod.duration))
                    }
                }
            }) {
                Text(availablePeriod.name)
            }
        }
    }
}

object TimeRangeStyles : StyleSheet() {
    val timePeriodSelectionBox by style {
        display(DisplayStyle.Flex)
        alignItems(AlignItems.Center)
        gap(10.px)
        padding(6.px, 12.px)
        borderRadius(5.px)
        shadowBorder()
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        height(40.px)
        minWidth(180.px)
    }

    val timePeriod by style {
        display(DisplayStyle.InlineBlock)
        padding(8.px)

        self + hover style {
            backgroundColor(SXMUI.containerHeaderBackgroundColor.value())
            cursor("pointer")
        }
    }

    val timePeriodSelected by style {
        fontWeight(700)
        color(SXMUI.buttonBackgroundPrimary.value())
    }
}