package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.utils.toLocalDayVerbose
import com.siriusxm.pia.views.channelguide.aggregatorIconLink
import com.siriusxm.unifiedcontent.sports.CompetitionParticipant
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import contentingestion.unifiedmodel.ScoreState
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H3
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days

/**
 * The view of a league
 */
@Composable
fun leagueView(app: SportsApplication, leagueId: String) {
    var league by remember { mutableStateOf(app.leagueById(leagueId)) }
    var entities by remember { mutableStateOf<List<CompetitionParticipant>?>(null) }
    var liveEvents by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }
    var upcomingEvents by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }
    var previousEvents by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }

    var selectedDay by remember { mutableStateOf<String?>(null) }
    var selectedPreviousDay by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(leagueId) {
        league = app.client.getLeagues().entities.find { it.id == leagueId }
        entities = app.client.getLeagueParticipants(leagueId).entities.sortedBy { it.name }
        val liveAndUpcomingEvents = app.client.getLeagueCompetitions(
            leagueId,
            Clock.System.now().minus(1.days), Clock.System.now().plus(14.days), max = 250).entities.sortedBy { it.start }

        liveEvents = liveAndUpcomingEvents.filter { it.state == ScoreState.IN_PROGRESS }
        upcomingEvents = liveAndUpcomingEvents.filter { it.upcoming() }

        previousEvents = app.client.getLeagueCompetitions(
            leagueId,
            Clock.System.now().minus(14.days), Clock.System.now(), max = 250).entities.filter { it.completed() }.sortedByDescending { it.start }
    }

    serviceContentView({
        title = league?.name
        breadcrumbs {
            crumb("Sports", "sports") {
                crumb("League", null)
            }
        }
        actionArea {
            aggregatorIconLink(leagueId, IconSize.NORMAL, showOnlyOnHover = false)
        }
    }) {
        if (entities?.isEmpty() == true) {
            messageBox(
                "No entity with id $leagueId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (entities.isNullOrEmpty()) {
            spinner(size = Size.LARGE)
        } else {
//            league?.id?.let { aggregatorIconLink(it, IconSize.NORMAL) }

            tabView {
                if (!entities.isNullOrEmpty()) {
                    tab("Teams") {
                        entities?.let { teams ->
                            teamsGrid(app, teams) {
                                app.context.navigate("sports/team/${it.id}")
                            }
                        }
                    }
                }
                if (!upcomingEvents.isNullOrEmpty()) {
                    tab("Live Events ${liveEvents?.size?.let { "($it)"} ?: "" }") {
                        eventsGridAdvanced(app, liveEvents!!)
                    }
                }
                if (!upcomingEvents.isNullOrEmpty()) {
                    tab("Upcoming ${upcomingEvents?.size?.let { "($it)" } ?: ""}") {
                        val upcomingEventsByDay = upcomingEvents!!.filter { it.start != null }.groupBy { it.start!!.toLocalDateTime().date.toLocalDayVerbose() }
                        val optionTextMap = upcomingEventsByDay.map { it.key to "${it.key} (${it.value.size})" }.toMap()

                        eventsGridAdvanced(app, upcomingEvents!!, upcomingEventsByDay, selectedDay, optionTextMap) {
                            selectedDay = it
                        }
                    }
                }
                if (!previousEvents.isNullOrEmpty()) {
                    tab("Completed ${previousEvents?.size?.let { "($it)" } ?: ""}") {
                        val previousEventsByDay = previousEvents!!.filter {
                            it.start != null
                        }.groupBy {
                            it.start!!.toLocalDateTime().date.toLocalDayVerbose()
                        }
                        val optionTextMap = previousEventsByDay.map { it.key to "${it.key} (${it.value.size})" }.toMap()

                        eventsGridAdvanced(
                            app,
                            previousEvents!!,
                            previousEventsByDay,
                            optionTextMap = optionTextMap,
                            selectedKey = selectedPreviousDay,
                            sortFn = { events -> events.sortedByDescending { it.start }}) {
                            selectedPreviousDay = it
                        }
                    }
                }
            }
        }
    }
}

/**
 * A team card displaying basic information about the team.
 */
@Composable
fun teamCard(app: SportsApplication, team: CompetitionParticipant, onClick: (() -> Unit)? = null) {
    Div({
        classes(SportsStyles.teamCard)
        onClick {
            onClick?.invoke()
        }
    }) {
        Div({
            classes(SportsStyles.entityImage)
        }) {
            val preferredImage = getPreferredTeamImage(team)
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(app.context, preferredImage))
            }
        }

        Div {
            tooltip({
                Text("Team ID: ${team.id}")
            }) {
                H3 {
                    Text(getTeamName(team))
                }
            }
        }
    }

}

fun getDefaultTeamImages(team: CompetitionParticipant): List<ImageDetails> {
    val images = mutableListOf<ImageDetails>()
    team.images?.entries?.map {
        val purpose = it.key
        val aspectRatioMap = it.value
        aspectRatioMap.entries.map {
            val aspectRadio = it.key
            val defaultImage = it.value.default
            if (defaultImage != null) {
                images.add(ImageDetails(purpose, aspectRadio, defaultImage))
            }
        }
    }
    return images
}

data class ImageDetails(val purpose: ImagePurpose, val aspectRatio: ImageAspectRatio, val image: Image)

fun getPreferredTeamImage(team: CompetitionParticipant): Image? {
    return team.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: team.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: team.images?.get(ImagePurpose.LOGO)?.get(ImageAspectRatio.ASPECT_1X1)?.default
}

/**
 * Get the team logo, hopefully without a background.
 */
fun getPreferredTeamLogo(team: CompetitionParticipant): Image? {
    return team.images?.get(ImagePurpose.LOGO)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: team.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: team.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
}

fun getTeamName(team: CompetitionParticipant): String {
    return listOfNotNull(team.name, team.nickname).joinToString(" ")
}

/**
 * Render a teams grid.
 */
@Composable
fun teamsGrid(
    app: SportsApplication,
    entities: List<CompetitionParticipant>,
    onClick: ((CompetitionParticipant) -> Unit)? = null
) {
    Div({
        classes(SportsStyles.gridList)
    }) {
        entities.forEach { item ->
            teamCard(app, item) {
                onClick?.invoke(item)
            }
        }
    }
}