package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import kotlinx.datetime.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Label
import org.jetbrains.compose.web.dom.Text

object DialogStyles : StyleSheet() {
    val dialog by style {
        maxWidth(800.px)

        className(NotificationStyles.box).style {
            marginBottom(1.em)
        }
    }
    val field by style {
        marginBottom(20.px)
        type("label").style {
            fontWeight("bold")
        }
        type("input[type=text]").style {
            width(100.percent)
        }
        type("input[type=password]").style {
            width(100.percent)
        }
        type("textarea").style {
            width(100.percent)
        }
    }

    val checkbox by style {
        display(DisplayStyle.Flex)
        lineHeight((1.7).cssRem)
        type("label").style {
            fontWeight("normal")
            marginLeft((0.5).cssRem)
        }
        type("input").style {
            marginTop((0.33).cssRem)
        }
    }

    val fieldInstruction by style {
        fontSize((0.8).cssRem)
        marginTop((0.5).cssRem)
        color(SXMUI.subtleTextColor.value())
    }

    val fieldContent by style {
        paddingTop((0.5).cssRem)
    }

    val radioGroupOption by style {
        display(DisplayStyle.Flex)
        marginBottom((0.4).cssRem)
        gap((0.4).cssRem)
    }

    val radioGroupOptionDescription by style {
        fontSize((0.8).cssRem)
        marginTop(3.px)
        color(SXMUI.subtleTextColor.value())
    }

    val buttonRadioGroup by style {
        display(DisplayStyle.Flex)
        marginTop((0.2).cssRem)
        marginBottom((0.4).cssRem)
        justifyContent(JustifyContent.Right)
    }

    val buttonRadioGroupButton by style {
        border(1.px, LineStyle.Solid, SXMUI.buttonBorderNormal.value(Color.black))
        backgroundColor(SXMUI.buttonBackgroundNormal.value())
        color(SXMUI.buttonForegroundNormal.value())
        fontWeight("bold")
        padding((0.4).cssRem, (1.5).cssRem, (0.2).cssRem)
        cursor("pointer")
        fontSize((0.9).cssRem)
    }
    val buttonRadioGroupSelected by style {
        backgroundColor(SXMUI.buttonBackgroundPrimary.value())
        color(SXMUI.buttonForegroundPrimary.value())
    }
    val scrollableCheckBoxSelector by style {
        maxHeight(200.px)
        overflowY("auto")
        border(color = Color.black, width = 1.px)
        backgroundColor(Color.white)
        borderRadius(4.px)
        padding(8.px)
        marginBottom(16.px) // Spacing between sections
        shadowBorder()
    }
}

interface ErrorHandler {
    fun clearAll()
    fun error(message: String)
}

class DialogViewConfiguration(
    val errors: ErrorHandler
) : EntityViewConfiguration()

internal class Error(
    val message: String
)

@Composable
fun dialogView(title: String, builder: DialogViewConfiguration.() -> Unit) {
    var errors by remember { mutableStateOf(emptyList<Error>()) }

    val errorHandler = object : ErrorHandler {
        override fun error(message: String) {
            println("adding error: $message")
            errors = errors + Error(message)
        }

        override fun clearAll() {
            errors = emptyList()
        }
    }

    val config = DialogViewConfiguration(errorHandler).apply {
        this.title = title
        type = EntityViewType.DIALOG
        builder()
    }

    Div({
        classes(DialogStyles.dialog)
    }) {
        val content = config.content
        config.content {
            if (errors.isNotEmpty()) {
                errors.forEach {
                    notificationBox(Notification(NotificationStyles.errorBox, it.message, null)) {
                        errors = errors - it
                    }
                }
            }
            content?.invoke()
        }
        entityView(config)
    }
}

@Composable
fun dialogField(title: String?, instruction: String? = null, content: @Composable () -> Unit) {
    Div({ classes(DialogStyles.field) }) {
        if (title != null) {
            Label {
                Text(title)
            }
        }
        Div({ classes(DialogStyles.fieldContent) }) {
            content()
        }
        if (instruction != null) {
            Div({ classes(DialogStyles.fieldInstruction) }) {
                Text(instruction)
            }
        }
    }
}

@Composable
fun simpleTextField(value: String?, update: (String?) -> Unit) {
    Input(InputType.Text) {
        value(value.orEmpty())
        onInput {
            val newValue = it.value.ifBlank { null }
            update(newValue)
        }
    }
}


/**
 * A date/time selector
 */
@Composable
fun dateTimePicker(value: LocalDateTime?, update: (LocalDateTime?) -> Unit) {
    Input(InputType.DateTimeLocal) {
        value(value?.toString().orEmpty())
        onInput {
            println(it.value)
            val newValue = it.value.ifBlank { null }?.let {
                LocalDateTime.parse(it)
            }
            update(newValue)
        }
    }
}

/**
 * A date/time selector that takes and returns instants. uses the system
 * timezone, so the picker is shown in the user's local time.
 */
@Composable
fun instantPicker(value: Instant?, tz: TimeZone = TimeZone.currentSystemDefault(), update: (Instant?) -> Unit) {
    dateTimePicker(value?.toLocalDateTime(tz)) {
        update(it?.toInstant(tz))
    }
}


@Composable
fun simpleCheckBox(label: String, checked: Boolean, enabled: Boolean = true, update: (Boolean) -> Unit) {
    Div({ classes(DialogStyles.checkbox) }) {
        Input(InputType.Checkbox) {
            this.checked(checked)
            this.onInput {
                update(it.value)
            }
            if (!enabled) {
                this.disabled()
            }
        }

        Label {
            Text(label)
        }
    }
}

@Composable
fun <T> checkBoxSelector(
    items: List<T>,
    selectedItems: Set<String>,
    itemLabel: (T) -> String,
    itemIdentifier: (T) -> String,
    onSelectionChange: (Set<String>) -> Unit
) {
    items.forEach { item ->
        simpleCheckBox(
            itemLabel(item),
            selectedItems.contains(itemIdentifier(item))
        ) { selected ->
            val newSelectedItems =
                if (selected) {
                    selectedItems + itemIdentifier(item)
                } else {
                    selectedItems - itemIdentifier(item)
                }
            onSelectionChange(newSelectedItems)
        }
    }
}

@Composable
fun <T> scrollableCheckBoxSelector(
    items: List<T>,
    selectedItems: Set<String>,
    itemLabel: (T) -> String,
    itemIdentifier: (T) -> String,
    onSelectionChange: (Set<String>) -> Unit
) {
    Div(
        attrs = {
            classes(DialogStyles.scrollableCheckBoxSelector)
        }
    ) {
        checkBoxSelector(items = items, selectedItems = selectedItems, itemLabel = itemLabel, itemIdentifier = itemIdentifier, onSelectionChange = onSelectionChange)
    }
}

interface Validation {
    suspend fun assertField(check: Boolean, message: String)
    suspend fun assertField(check: suspend () -> Boolean, message: String)
    suspend fun orElse(block: suspend () -> Unit)

    val failed: Boolean
    val success: Boolean
}

suspend fun DialogViewConfiguration.validate(
    checkAll: Boolean = false,
    block: suspend Validation.() -> Unit
): Validation {
    var failed = false

    val validation = object : Validation {
        override val failed: Boolean get() = failed
        override val success: Boolean get() = !failed

        override suspend fun assertField(check: Boolean, message: String) {
            assertField({ check }, message)
        }

        override suspend fun assertField(check: suspend () -> Boolean, message: String) {
            if (!failed || checkAll) {
                if (!check()) {
                    failed = true
                    errors.error(message)
                }
            }
        }

        override suspend fun orElse(block: suspend () -> Unit) {
            if (!failed) {
                block()
            }
        }
    }
    validation.block()

    return validation
}