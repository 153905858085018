package com.siriusxm.pia

import com.siriusxm.content.management.console.applications.transcription.TranscriptionApplication
import com.siriusxm.pia.cognito.CognitoClient
import com.siriusxm.pia.components.AudioPlayer
import com.siriusxm.pia.components.NotificationsConfig
import com.siriusxm.pia.utils.HashNavigator
import com.siriusxm.pia.utils.Navigator
import com.siriusxm.pia.utils.navigator
import com.siriusxm.pia.views.channelguide.EPG
import com.siriusxm.pia.views.homeDashboard
import com.siriusxm.pia.views.mddb.MDDBApplication
import com.siriusxm.pia.views.podcasts.PodcastsApplication
import com.siriusxm.pia.views.sports.SportsApplication
import com.siriusxm.pia.views.unifiedaggregator.AggregatorApplication
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.withContext
import kotlinx.serialization.json.Json
import org.jetbrains.compose.web.css.Style

/**
 * The main application.
 */
object Application : Navigator {
    lateinit var configuration: Configuration
    lateinit var cognito: CognitoClient
    lateinit var notifications: NotificationsConfig
    var player: AudioPlayer? = null

    private val origin = window.location.origin

    val context: ApplicationContext by lazy {
        object : ApplicationContext {
            override val accessToken: String?
                get() = cognito.accessToken
            override val configuration: Configuration
                get() = Application.configuration
            override val refreshToken: String?
                get() = cognito.refreshToken

            override fun navigate(path: String) {
                navigator.navigate(path)
            }

            override fun notification(type: NotificationType, message: String, description: String?) {
                when (type) {
                    NotificationType.INFO -> notifications.info(message, description)
                    NotificationType.ERROR -> notifications.showError(message, description)
                    NotificationType.WARNING -> notifications.showError(message, description)
                }
            }

            override val viewer: Viewer
                get() = this@Application.viewer

            override val path: String
                get() = navigation.path

            override fun push(newPath: String, title: String?, render: Boolean) {
                navigation.push(newPath, title, render)
            }
        }
    }

    private val navigation: HashNavigator = HashNavigator {
        Style(AppStylesheet)
        renderPage(it) { route ->
            route.switch {
                select(Regex(".+")) {
                    getApplication(match)?.route(this)
                }
                default {
                    homeDashboard(this@Application)
                }
            }
        }
    }

    init {
        navigator = navigation
    }

    private lateinit var applications: List<InstalledApplication>

    /**
     * Access to the API
     */
    lateinit var api: IngestionAPI


    suspend fun run() {
        configuration = config()
        val pkceEnabled = configuration.pkceEnabled.toBooleanStrictOrNull() ?: false
        cognito = CognitoClient(
            configuration.authUrl,
            configuration.clientId,
            origin,
            "sxm",
            "openid email Transcription/rest-api DynamicRadio/visibility-flags MDDBEventBus/rest-api UnifiedCatalogAPI/sxm:unified-catalog:entities:read EPG/sxm:unified-catalog:entities:read UnifiedCatalogAPI/sxm:unified-catalog:entities:publish UnifiedCatalogAPI/sxm:unified-catalog:management:read UnifiedCatalogAPI/sxm:unified-catalog:management:write UnifiedCatalogAPI/sxm:unified-catalog:backfill:start Sports/sxm:unified-catalog:entities:read",
            pkceEnabled
        )
        cognito.init()

        initApplications(context, configuration.applications.orEmpty())

        if (!cognito.isLoggedIn()) {
            withContext(Dispatchers.Main) {
                cognito.login()
            }
        } else {
            navigation.run()
        }

    }

    private fun initApplications(
        context: ApplicationContext,
        applications: List<ApplicationDescriptor>
    ) {
        this.applications = applications.map { descriptor ->
            when (descriptor.path) {
                "aggregator" -> AggregatorApplication()
                "mddb" -> MDDBApplication()
                "sports" -> SportsApplication()
                "channelguide" -> EPG()
                "podcasts" -> PodcastsApplication()
                "transcriptions" -> TranscriptionApplication()
                else -> DynamicApplication()
            }.let { app ->
                InstalledApplication(descriptor, app)
            }
        }

        this.applications.forEach {
            if (it.descriptor.initOnStartup) {
                it.initialize(context)
            }
        }
    }

    /**
     * Load configuration and applications.
     */
    private suspend fun config(): Configuration {
        return coroutineScope {
            val httpClient = HttpClient {
                install(ContentNegotiation) {
                    json(Json { isLenient = true; ignoreUnknownKeys = true })
                }
            }
            val configJob = async {
                httpClient.get("$origin/config.json").body<Configuration>()
            }

            // applications are loaded separately.
            val applicationJob = async {
                httpClient.get("$origin/applications.json").body<List<ApplicationDescriptor>>()
            }

            configJob.await().copy(
                applications = applicationJob.await()
            )
        }
    }

    /**
     * Get the viewer of the application.
     */
    val viewer: Viewer by lazy {
        Viewer(cognito.idToken)
    }

    /**
     * Get the initialized application
     */
    fun getApplication(key: String): ConsoleApplication? {
        return applications.find { it.descriptor.path == key }?.also {
            it.initialize(context)
        }?.application
    }

    override fun navigate(path: String) {
        navigation.navigate(path)
    }

    override fun push(newPath: String, title: String?, render: Boolean) {
        navigation.push(newPath, title, render)
    }
}

class InstalledApplication(
    val descriptor: ApplicationDescriptor,
    val application: ConsoleApplication
) {
    private var initialized: Boolean = false

    fun initialize(context: ApplicationContext) {
        if (!initialized) {
            application.initialize(context)
        }
    }
}