package com.siriusxm.pia

import androidx.compose.runtime.Composable
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.artistradio.artistRadio
import com.siriusxm.pia.views.epg.epgDashboard
import com.siriusxm.pia.views.homeDashboard
import com.siriusxm.pia.views.mddb.mddb
import com.siriusxm.pia.views.podcasts.podcasts
import com.siriusxm.pia.views.transcription.transcriptions
import com.siriusxm.pia.views.unifiedaggregator.contentAggregator
import com.siriusxm.pia.views.sports.sportsDashboard

@Composable
fun applicationRouting(route: Route) {
    route.switch {
        select("podcasts") {
            podcasts()
        }

        select("mddb") {
            this.mddb()
        }

        select("artist-radio") {
            artistRadio()
        }

        select("transcriptions") {
            transcriptions()
        }

        select("aggregator") {
            this.contentAggregator(Application.aggregator)
        }

        select("sports") {
            sportsDashboard(Application.sportsAPI, Application.aggregator)
        }

        select("epg") {
            epgDashboard(Application.epgAPI, Application.aggregator)
        }

        default {
            homeDashboard()
        }
    }
}