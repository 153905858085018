package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.FormatParams
import com.siriusxm.pia.rest.unifiedaggregator.ImageRenderingHelper
import com.siriusxm.pia.rest.unifiedaggregator.ResizeParams
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.utils.toLocalDayString
import com.siriusxm.unifiedcontent.sports.CompetitionLeague
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import kotlinx.datetime.Clock
import kotlinx.datetime.LocalDate
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days

/**
 * The main sports dashboard page.
 */

@Composable
fun sportsDashboardPage(app: SportsApplication) {
    val leagues by app.leagues.collectAsState()

    var eventsByDayByLeague by remember { mutableStateOf<Map<LocalDate, Map<String, List<CompetitiveEvent>>>?>(null) }
    var eventsByDay by remember { mutableStateOf<Map<LocalDate, List<CompetitiveEvent>>?>(null) }

    var selectedLeague by remember { mutableStateOf<String?>(null) }

    val today = Clock.System.now().toLocalDateTime().date

    LaunchedEffect("leagues") {
        val byDayByLeague = mutableMapOf<LocalDate, MutableMap<String, List<CompetitiveEvent>>>()
        val byDayAllEvents = mutableMapOf<LocalDate, MutableList<CompetitiveEvent>>()

        //TODO: once we have a way to fetch events across leagues on the server, we can remove this
        leagues?.forEach {
            val eventsByDate = app.client.leagueCompetitions(
                it.id,
                Clock.System.now().minus(1.days), Clock.System.now().plus(7.days)).entities.groupBy {
                    it.start?.toLocalDateTime()?.date
            }
            if (eventsByDate.isNotEmpty()) {
                eventsByDate.entries.forEach { (date, events) ->
                    var leagueEvents = byDayByLeague[date]
                    if (leagueEvents == null) {
                        leagueEvents = mutableMapOf()
                        byDayByLeague[date!!] = leagueEvents
                    }
                    leagueEvents[it.name] = events

                    var dayEvents = byDayAllEvents[date]
                    if (dayEvents == null) {
                        dayEvents = mutableListOf()
                        byDayAllEvents[date!!] = dayEvents
                    }
                    dayEvents.addAll(events)
                }
            }
        }
        eventsByDayByLeague = byDayByLeague
        eventsByDay = byDayAllEvents
    }

    serviceContentView({
        title = "Leagues"
    }) {
        if (leagues == null) {
            spinner(size = Size.LARGE)
        } else {
            tabView {
                tab("Leagues") {
                    leaguesGrid(app, leagues!!)
                }
                eventsByDayByLeague?.entries?.sortedBy { it.key }?.forEach { (date, eventsByLeague) ->
                    val allDaysEvents = eventsByDay?.let { it[date] }
                    val tabName = if (date != today) {
                        "${date.toLocalDayString()} ${(allDaysEvents?.size?.let { "($it)" }) ?: ""}"
                    } else {
                        "Today ${(allDaysEvents?.size?.let { "($it)" }) ?: ""}"
                    }
                    if (date >= today) {
                        tab(tabName) {
                            val optionTextMap = eventsByLeague.map { it.key to "${it.key} (${it.value.size})" }.toMap()
                            eventsGridAdvanced(
                                app, allDaysEvents!!, eventsByLeague, selectedLeague,
                                optionTextMap,  keySortFn = null,
                                sortFn =
                                { events: List<CompetitiveEvent> ->
                                    val sorted = mutableListOf<CompetitiveEvent>()
                                    sorted.addAll(events.filter { it.liveAndUpcoming() })
                                    sorted.addAll(events.filter { it.completed() })
                                    sorted.addAll(events.filter { it.future() })
                                    sorted
                                }) {
                                selectedLeague = it
                            }
                        }
                    }
                }
            }
        }
    }
}

@Composable
fun iconLink(url: String, iconName: String, tooltip: String, iconSize: IconSize = IconSize.NORMAL) {
    A(href = url) {
        icon(iconName) {
            type = IconType.SYMBOLS
            size = iconSize
            title = tooltip

            style {
                property("vertical-align", "top")
            }
        }
    }
}

@Composable
fun leagueItem(app: SportsApplication, league: CompetitionLeague, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    @Composable
    fun leagueContent() {
        Div({ classes(SportsStyles.listItem) }) {
            Div {
                val preferredImage =
                    league.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
                if (preferredImage != null) {
                    Img(
                        src = getAbsoluteUrl(
                            app.context, preferredImage,
                            FormatParams("png"), ResizeParams(100, 100)
                        )
                    ) {
                        style {
                            width(100.px)
                            height(100.px)
                        }
                    }
                }
            }

            Div({ classes(SportsStyles.metadata) }) {
//                Div({ classes(SportsStyles.entitySubInfo) }) {
//                    Text(league.sport ?: "")
//                }
                Div({ classes(SportsStyles.entityInfo) }) { Text(league.name) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(league.id)
                }
            }
        }
    }

    if (includeLink) {
        A("#sports/league/${league.id}") {
            leagueContent()
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            leagueContent()
        }
    }
}

@Composable
fun leaguesGrid(
    app: SportsApplication,
    entities: List<CompetitionLeague>,
    includeLinks: Boolean = true,
    onClick: ((CompetitionLeague) -> Unit)? = null
) {
    Div({
        classes(SportsStyles.gridList)
    }) {
        entities.forEach { entity ->
            leagueItem(app, entity, includeLinks && onClick == null) {
                onClick?.invoke(entity)
            }
        }
    }
}

// TODO: make this not a duplicate code
fun getAbsoluteUrl(
    context: ApplicationContext, preferredImage: Image,
    formatParams: FormatParams = FormatParams("png"),
    resizeParams: ResizeParams = ResizeParams(600, 600)
): String {
    return ImageRenderingHelper.getImageUrl(
        context.configuration.atlasImageServerBaseUrl,
        preferredImage.url,
        resizeParams,
        formatParams
    )
}

/**
 * Sports Routing
 */
@Composable
fun Route.sportsDashboard(app: SportsApplication) {
    switch {
        select("league") {
            switch {
                select(Regex(".+")) {
                    val leagueId = this.match
                    leagueView(app, leagueId)
                }
            }
        }

        select("team") {
            switch {
                select(Regex(".+")) {
                    val teamId = this.match
                    teamById(app, teamId)
                }
            }
        }

        select("event") {
            switch {
                select(Regex(".+")) {
                    val eventId = this.match
                    val autoPolling = parameter("autoPolling")
                    val isAutoPolling = "true" == autoPolling
                    val returnUrl = parameter("returnUrl")
                    scoresForEvent(app, eventId, isAutoPolling, returnUrl)
                }
            }
        }

        default {
            sportsDashboardPage(app)
        }
    }
}