package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.unifiedmodel.*
import kotlinx.serialization.Serializable

@Serializable
data class FlattenedImage(
    val purpose: ImagePurpose,
    val ratio: ImageAspectRatio,
    val image: Image,
    val related: RelatedImages? = null,
    val imageVariantType: EntityImageVariant
)

fun flattenImages(images: Map<ImagePurpose, Map<ImageAspectRatio, ImageVariant>>): List<FlattenedImage> {
    return images.flatMap {
        val purpose = it.key
        val purposeImages = mutableListOf<FlattenedImage>()
        it.value.forEach {
            val ratio = it.key
            val defaultImage = it.value.default?.let { default ->
                FlattenedImage(purpose, ratio, default,
                    it.value.related, EntityImageVariant.DEFAULT
                )
            }
            if (defaultImage != null) {
                purposeImages.add(defaultImage)
            }
        }

        it.value.forEach {
            val ratio = it.key
            val autoImage = it.value.auto?.let { auto ->
                FlattenedImage(purpose, ratio, auto,
                    it.value.related, EntityImageVariant.AUTO
                )
            }
            if (autoImage != null) {
                purposeImages.add(autoImage)
            }
        }

        it.value.forEach {
            val ratio = it.key
            val childImage = it.value.child?.let { child ->
                FlattenedImage(purpose, ratio,
                    child, it.value.related, EntityImageVariant.CHILD
                )
            }
            if (childImage != null) {
                purposeImages.add(childImage)
            }
        }
        purposeImages
    }
}

fun Map<ImagePurpose, Map<ImageAspectRatio, ImageVariant>>.flatten(): List<FlattenedImage> {
    return flattenImages(this)
}


@Serializable
class MappingResponse(
    val sourceId: String,
    val atlasId: String,
)