package com.siriusxm.pia.views.podcasts.configuration

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.ContentFlagUpdateInput
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.dialogField
import com.siriusxm.pia.components.dialogView
import com.siriusxm.pia.components.simpleTextField
import com.siriusxm.pia.views.podcasts.Podcasts
import org.jetbrains.compose.web.dom.TextArea

@Composable
fun addContentFlag() {
    var id by remember { mutableStateOf<String?>(null) }
    var description by remember { mutableStateOf<String?>(null) }

    dialogView("Add Content Flag") {
        content {
            box {
                dialogField("ID") {
                    simpleTextField(id) {
                        id = it?.lowercase()?.let {
                            Regex("[^a-z]").replace(it, "")
                        }
                    }
                }

                dialogField("Description") {
                    TextArea(description) {
                        onInput {
                            description = it.value.ifBlank { null }
                        }
                    }
                }
            }
        }

        suspend fun save() {
            if (id.isNullOrBlank() || id!!.length < 4) {
                this.errors.error("The ID must be at least 4 characters")
                return
            }
            try {
                Podcasts.api.mutate {
                    flags {
                        addFlag(id!!, ContentFlagUpdateInput(description?.takeIf { it.isNotBlank() })) {
                            this.flag
                        }
                    }
                }.flags.addFlag.flag

                Podcasts.navigate("flags")
            } catch (e: Throwable) {
                Podcasts.context.showError("The flag failed to save.")
            }
        }

        action {
            title = "Save"
            primary = true
            showProgressOnAction = true
            action {
                save()
            }
        }

        action("Cancel") {
            Podcasts.navigate("flags")
        }
    }
}