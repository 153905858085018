package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.client.Feed
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toAMPMTimeString
import kotlinx.coroutines.launch
import kotlinx.datetime.Instant
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Text

@Composable
fun feed(feedUrl: String) {
    var show by remember { mutableStateOf<Show?>(null) }
    var feed by remember { mutableStateOf<Feed?>(null) }
    val coroutineScope = rememberCoroutineScope()

    LaunchedEffect(true) {
        coroutineScope.launch {
            Podcasts.api.query {
                feed(feedUrl) {
                    url
                    rssUrl
                    bundles {
                        id
                    }
                    moves {
                        url
                        date
                    }
                    active
                    nextFetch
                    show {
                        id
                        title

                        csShow {
                            csId
                        }
                    }
                }
            }.feed.let {
                feed = it
                show = it.show
            }
        }
    }

    if (feed != null) {
        entityView({
            title = "Feed"
            subTitle = feed?.url

            action("Refresh") {
                Podcasts.api.mutate {
                    feed(feed!!.url!!) {
                        reset(true)
                    }
                }
            }

            if (Application.viewer.contentEditor) {
                action("Edit") {
                    Podcasts.push(
                        "podcasts/feeds/${encodeURIComponent(feed!!.url!!)}?action=edit&return=${
                            encodeURIComponent(
                                "podcasts/feeds/${encodeURIComponent(feed!!.url!!)}"
                            )
                        }", "Edit Feed", true
                    )
                }
            }
        }) {
            box("Details") {
                detailGrid {
                    show?.let {
                        detail("Show") {
                            A(href = "#podcasts/show/${it.id}") {
                                Text(it.titleOrUnknown())
                            }
                        }
                    }

                    feed?.nextFetch?.let {
                        val dateTime = Instant.parse(it).toLocalDateTime(TimeZone.currentSystemDefault())
                        detail("Next Scheduled Fetch", "${dateTime.date} ${dateTime.toAMPMTimeString()}")
                    }

                    feed?.active?.let {
                        detail("Status", if (it) "Active" else "Inactive")
                    }
                }
            }

            tabView {
                feed?.rssUrl?.let { rssUrl ->
                    tab("Content") {
                        feedContent(rssUrl)
                    }
                }
            }
        }
    } else {
        spinner()
    }
}