package com.siriusxm.pia.views.podcasts.configuration

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.client.ContentFlag
import com.siriusxm.pia.components.*
import com.siriusxm.pia.views.podcasts.Podcasts
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Text

@Composable
fun contentFlags() {
    var contentFlags by remember { mutableStateOf<List<ContentFlag>?>(null) }

    LaunchedEffect(true) {
        Podcasts.api.query {
            flags {
                this.flags {
                    this.flag
                    this.description
                }
            }
        }.flags.flags.let {
            contentFlags = it
        }
    }

    entityView({
        title = "Content Flags"
        breadcrumbs {
            crumb("Home", "") {
                crumb("Flags", null)
            }
        }

        action {
            title = "Add"
            primary = true
            action {
                Podcasts.navigate("podcasts/flags/add")
            }
        }

    }) {
        if (contentFlags == null) {
            spinner()
        } else {
            box({
                paddedContent = false
            }) {
                if (contentFlags?.isEmpty() == true) {
                    boxMessage("There are no content flags configured")
                } else {
                    table<ContentFlag> {
                        items(contentFlags)
                        column {
                            content {
                                A(href = "#flags/${it.flag}") {
                                    Text(it.flag)
                                }
                            }
                        }

                        column {
                            content {
                                Text(it.description.orEmpty())
                            }
                        }
                    }
                }
            }
        }
    }
}
