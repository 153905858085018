package com.siriusxm.pia.views.unifiedaggregator.relationships

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.asEntities
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import com.siriusxm.pia.views.unifiedaggregator.entityList
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

/**
 * A view to show all relationships that this entity is a part of.
 */
@Composable
fun entityRelationships(context: AggregatorService, entity: Entity) {
    val availableRelationships = context.relationships.filter {
        it.subjectType.name == entity.type || it.objectType.name == entity.type
    }
    var selectedRelationship by remember { mutableStateOf(availableRelationships.firstOrNull()) }
    var relationshipEntities by remember { mutableStateOf<List<Entity>?>(null) }

    LaunchedEffect(selectedRelationship) {
        relationshipEntities = null
        selectedRelationship?.let { relationship ->
            val isSubject = relationship.subjectType.name == entity.type

            relationshipEntities = if (isSubject) {
                context.api.relationshipObjects(relationship.id, entity.id, true).entities.asEntities()
            } else {
                context.api.relationshipSubjects(relationship.id, entity.id, true).entities.asEntities()
            }
        }
    }

    if (availableRelationships.isEmpty()) {
        messageBox("This entity type is not eligible for any relationships.")
    } else {
        box({
            paddedContent = false
            this.header {
                Div({
                    style { paddingRight(1.em) }
                }) {
                    Div {
                        Select({
                            style { width(100.percent) }
                            onChange { update ->
                                selectedRelationship = availableRelationships.find { it.id == update.value }
                            }
                        }) {
                            availableRelationships.sortedBy { it.name.lowercase() }.forEach {
                                Option(it.id, {
                                    if (it == selectedRelationship) selected()
                                }) {
                                    Text(it.name)
                                }
                            }
                        }
                    }

                    selectedRelationship?.let {
                        Div({
                            style {
                                marginTop(1.em)
                            }
                        }) {
                            Text(it.description)
                        }
                    }
                }
            }
            if (Application.viewer.contentEditor && selectedRelationship?.editable == true) {
                action("New Relationship", true) {
                    context.navigate(
                        "relationships/edit?relationship=${
                            encodeURIComponent(
                                selectedRelationship!!.id
                            )
                        }&entity=${encodeURIComponent(entity.id)}"
                    )
                }
            }
        }) {
            if (relationshipEntities == null) {
                boxSpinner("Loading entities")
            } else if (relationshipEntities?.isEmpty() == true) {
                boxContent {
                    Div({ classes(BoxStyles.boxMessage) }) {
                        Text("No relationships found.")
                    }
                }
            } else if (!relationshipEntities.isNullOrEmpty()) {
                entityList(relationshipEntities.orEmpty(), includeTimestamp = true)
            }
        }
    }
}