package com.siriusxm.pia.views.sports

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.ConsoleApplication
import com.siriusxm.pia.components.waitUntilNonNull
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.rest.unifiedaggregator.unifiedAggregatorClient
import com.siriusxm.pia.utils.Route
import com.siriusxm.unifiedcontent.sports.CompetitionLeague
import com.siriusxm.unifiedcontent.sports.SportsService
import io.ktor.client.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.serialization.json.Json

/**
 * The sports application
 */
class SportsApplication : ConsoleApplication {
    internal lateinit var aggregator: UnifiedAggregatorClient
    internal lateinit var context: ApplicationContext
    internal lateinit var client: SportsService

    private val _leagues = MutableStateFlow<List<CompetitionLeague>?>(null)
    val leagues = _leagues.asStateFlow()

    override fun initialize(context: ApplicationContext) {
        this.context = context

        client = SportsService(context.configuration.sportsApiUrl!!, HttpClient {
            install(ContentNegotiation) {
                json(Json {
                    isLenient = true
                    ignoreUnknownKeys = true
                })
            }
            expectSuccess = true
            context.installAuth(this)
        })
        aggregator = unifiedAggregatorClient(context)
    }

    fun leagueById(id: String): CompetitionLeague? {
        return leagues.value?.find { it.id == id }
    }

    @Composable
    override fun route(route: Route) {
        LaunchedEffect(Unit) {
            _leagues.value = client.getLeagues().entities
        }

        val routeLeagues by leagues.collectAsState(null)
        waitUntilNonNull(routeLeagues) {
            route.sportsDashboard(this)
        }
    }

    /**
     * Navigate to the path (relative to the sports path, so don't start with "sports")
     */
    fun navigate(path: String) {
        context.navigate("sports/${path.removePrefix("sports/")}")
    }
}