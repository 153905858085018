package com.siriusxm.pia.views.sports

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.boxTable
import com.siriusxm.pia.components.buildSelect
import com.siriusxm.pia.utils.toAMPMTimeString
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.unifiedcontent.sports.Airing
import com.siriusxm.unifiedcontent.sports.CompetitionParticipant
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.unifiedmodel.BoxScore
import contentingestion.unifiedmodel.ScoreState
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.*

@Composable
fun eventsGridAdvanced(
    app: SportsApplication,
    allEvents: List<CompetitiveEvent>,
    selectorMap: Map<String, List<CompetitiveEvent>>? = null,
    selectedKey: String? = null,
    optionTextMap: Map<String, String>? = null,
    keySortFn: ((Set<String>) -> Set<String>)? = null,
    sortFn: ((List<CompetitiveEvent>) -> List<CompetitiveEvent>)? = null,
    cb: (filter: String?) -> Unit = {}
) {
    if (selectorMap != null) {
        Div({
            style {
                marginTop(10.px)
            }
        }) {
            buildSelect(
                selectedKey,
                selectorMap.keys,
                optionTextMap = optionTextMap,
                sortSelectOptions = false
            ) {
                cb(it)
            }
        }
    }

    val keys = if (keySortFn != null && selectorMap?.keys != null) {
        keySortFn(selectorMap.keys)
    } else {
        selectorMap?.keys
    } ?: listOf()

    if (keys.size == 0) {
        renderSelectedItems(app, allEvents, optionTextMap, null, selectedKey, sortFn)
    } else {
        keys.forEach {
            val selectedItems = selectorMap?.get(it)
            renderSelectedItems(app, selectedItems, optionTextMap, it, selectedKey, sortFn)
        }
    }
}

@Composable
private fun renderSelectedItems(
    app: SportsApplication,
    selectedItems: List<CompetitiveEvent>?,
    optionTextMap: Map<String, String>?,
    key: String? = null,
    selectedKey: String? = null,
    sortFn: ((List<CompetitiveEvent>) -> List<CompetitiveEvent>)?
) {
    if (selectedItems != null && selectedItems.size > 0) {
        val header = optionTextMap?.get(key)

        if (selectedKey == null || selectedKey == key) {
            if (header != null) {
                Div({ classes(SportsStyles.subheader) }) {
                    Text(header)
                }
            }

            val sortedEvents = if (sortFn != null) {
                sortFn(selectedItems)
            } else {
                selectedItems
            }

            box({
                paddedContent = false
            }) {
                sortedEvents.forEach { event ->
                    eventSummaryRow(app, event)
//                    eventItem(app, event)
                }
            }
        }
    }
}

/**
 * Displays an event summary.
 */
@Composable
fun eventSummaryRow(app: SportsApplication, event: CompetitiveEvent) {
    Div({
        classes(SportsStyles.eventRow)
    }) {
        val status = if (event.upcoming()) {
            event.start?.toLocalDateTime()?.toAMPMTimeString(false)
        } else if (event.live()) {
            event.status?.period ?: "In Progress"
        } else {
            event.state.name
        }

        if (status != null) {
            Div({ classes(SportsStyles.eventStatus) }) {
                A(href = "#sports/event/${event.id}") {
                    Text(status)
                }
            }
        }

        Div({
            classes(SportsStyles.eventItem)
        }) {
            Div({ classes(SportsStyles.teamsInfo) }) {
                val boxScoreRows = event.toBoxScoreRows(app)
                boxScoreRows.forEach {
                    Div({
                        classes(SportsStyles.eventRowTeamInfo)
                        val winningScore = boxScoreRows.maxOf { it.finalValue }
                        if (it.finalValue == winningScore && event.state == ScoreState.FINAL) {
                            classes(SportsStyles.winner)
                        }
                    }) {
                        Div({
                            classes(SportsStyles.evenRowTeamImage)
                        }) {
                            if (it.imageUrl != null) {
                                A("#sports/team/${it.teamId}") {
                                    Img(it.imageUrl)
                                }
                            }
                        }
                        Div({ classes(SportsStyles.teamName) }) {
                            Div({ classes(SportsStyles.entityInfo) }) {
                                A("#sports/team/${it.teamId}") {
                                    Text(it.teamName)
                                }
                            }
                        }

                        // display the score if we're live or completed
                        if (event.live() || event.completed()) {
                            Div({
                                classes(SportsStyles.entityInfo)
                                style {
                                    width(25.px)
                                }
                            }) { Text(it.finalValue) }
                        }
                    }
                }
            }
            if (event.live() || event.upcoming()) {
                val airings = event.distinctAirings()
                if (airings != null) {
                    AiringsTable(airings)
                }
            }
        }
    }
}


@Composable
fun AiringsTable(airings: List<Airing>) {
    Span({
        style {
            width(300.px)
        }
    }) {
        boxTable<Airing> {
            items(airings.sortedBy { it.channelNumber })
            column {
                width = 250.px
                content {
                    A("#channelguide/channels/${it.channelId}") {
                        Text(it.channelName ?: "")
                    }
                }
            }
            column {
                content {
                    Text(it.channelNumber?.toString() ?: "")
                }
            }
            column {
                content {
                    Text(it.coverageType.name)
                }
            }
        }
    }
}

fun CompetitiveEvent.toBoxScoreRows(app: SportsApplication, boxScore: BoxScore? = null): MutableList<BoxScoreRow> {
    val awayTeam = awayTeam()
    val homeTeam = homeTeam()

    return listOfNotNull(awayTeam, homeTeam).map { team ->
        BoxScoreRow(
            team.competitor.id,
            team.competitor.name,
            getPreferredTeamLogo(team.competitor)?.let { getAbsoluteUrl(app.context, it) },
            boxScore?.periods?.map {
                if (team == awayTeam) {
                    it.awayScore?.points?.toString()
                } else {
                    it.homeScore?.points?.toString()
                }?: ""
            } ?: listOf(),
            team.value?.toString() ?: ""
        )
    }.toMutableList()
}

/**
 * Get the list of participants in the event
 */
fun CompetitiveEvent.participants(): List<CompetitionParticipant> {
    return status?.scores?.map {
        it.competitor
    }.orEmpty()
}
