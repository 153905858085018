package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.utils.navigator
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object ButtonStyles : StyleSheet() {
    val button by style {
        position(Position.Relative)
        display(DisplayStyle.LegacyInlineFlex)
        backgroundColor(SXMUI.buttonBackgroundNormal.value())
        color(SXMUI.buttonForegroundNormal.value())
        border(0.px, LineStyle.Solid, SXMUI.buttonBorderNormal.value(Color.black))
        fontWeight("bold")
        padding((0.4).cssRem, (1.5).cssRem, (0.2).cssRem)
        cursor("pointer")
        fontSize((0.9).cssRem)

        borderRadius(3.px)
        shadowBorder()

        self + hover style {
            backgroundColor(SXMUI.buttonBackgroundNormalHover.value())
            color(SXMUI.buttonForegroundNormalHover.value())
        }

        adjacent(self, self).style {
            marginLeft(10.px)
        }
    }

    val primaryButton by style {
        backgroundColor(SXMUI.buttonBackgroundPrimary.value())
        color(SXMUI.buttonForegroundPrimary.value())
        border(1.px, LineStyle.Solid, SXMUI.buttonBackgroundPrimary.value(Color.black))
        self + hover style {
            backgroundColor(SXMUI.buttonBackgroundPrimaryHover.value())
            color(SXMUI.buttonForegroundPrimary.value())
            border(1.px, LineStyle.Solid, SXMUI.buttonBackgroundPrimaryHover.value(Color.black))
        }
    }
    val disabledButton by style {
        color(SXMUI.disabledTextColor.value())
        cursor("inherit")

        border(1.px, LineStyle.Solid, SXMUI.disabledTextColor.value(Color.black))

        self + hover style {
            backgroundColor(SXMUI.buttonBackgroundNormal.value())
            color(SXMUI.disabledTextColor.value())
        }
    }
}

/**
 * Defines the button configuration
 */
class ButtonConfiguration(
    var title: String? = null,
    var primary: Boolean = false,
    var action: suspend () -> Unit = {}
) {
    /**
     * If true, when the action is called, an indeterminate progress icon is
     * displayed on the button that will be removed when the action handler returns.
     * Since the action block is suspended, it should not return until the operation
     * is complete.
     */
    var showProgressOnAction: Boolean = false

    /**
     * Renders the button as a link
     */
    var link: String? = null

    var enabled: Boolean = true

    fun action(block: suspend () -> Unit) {
        action = block
    }
}

/**
 * Render a button with a configuration callback block.
 */
@Composable
fun button(block: ButtonConfiguration.() -> Unit) {
    val config = ButtonConfiguration().apply(block)
    button(config)
}

/**
 * Render a button with a provided button configuration.
 */
@Composable
fun button(config: ButtonConfiguration) {
    var isProcessingAction by mutableStateOf(false)
    val coroutineScope = rememberCoroutineScope()

    Button({
        classes(ButtonStyles.button)
        if (!config.enabled) {
            classes(ButtonStyles.disabledButton)
        } else if (config.primary) {
            classes(ButtonStyles.primaryButton)
        }
        onClick {
            it.preventDefault()
            if (config.enabled && !isProcessingAction) { // don't allow multiple clicks
                if (config.link != null) {
                    navigator.navigate(config.link!!)
                } else {
                    coroutineScope.launch {
                        isProcessingAction = true
                        config.action()
                        isProcessingAction = false
                    }
                }
            }
        }
    }) {
        Span {
            config.title?.let {
                Text(it)
            }
            if (config.showProgressOnAction && isProcessingAction) {
                Span({
                    style {
                        position(Position.Absolute)
                        top(1.px)
                        right(1.px)
                    }
                }) {
                    spinner(
                        style = if (config.primary) Style.LIGHT else Style.DARK,
                        size = Size.SMALL
                    )
                }
            }
        }
    }
}

/**
 * Simplified button rendered where a title and action is provided.
 */
@Composable
fun button(title: String, primary: Boolean = false, action: suspend () -> Unit) {
    button {
        this.title = title
        this.primary = primary
        this.action(action)
    }
}