package com.siriusxm.pia.views.mddb

import androidx.compose.runtime.Composable
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.ConsoleApplication
import com.siriusxm.pia.components.serviceView
import com.siriusxm.pia.utils.Route

/**
 * The MDDB application definition.
 */
class MDDBApplication : ConsoleApplication {
    lateinit var mddbAPI: MddbAPI
    lateinit var context: ApplicationContext

    override fun initialize(context: ApplicationContext) {
        this.context = context
        mddbAPI = MddbAPI(context.configuration.mddbApiUrl, context)
    }

    @Composable
    override fun route(route: Route) {
        serviceView {
            mddbDashboardPage(context, mddbAPI)
        }
    }
}