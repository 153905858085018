package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.*
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.coroutines.delay
import kotlinx.serialization.json.JsonElement
import kotlinx.serialization.json.JsonNull
import kotlinx.serialization.json.JsonObject
import kotlinx.serialization.json.jsonObject
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours

val eventPeriods = listOf(
    Period("1d", 24.hours, 1440),
    Period("3d", 3.days, 4320),
    Period("1w", 7.days, 10080),
    Period("2w", 12.days, 20160),
    Period("1mo", 31.days, 43200),
    Period("3mo", 93.days, 129600),
)

fun getHumanReadableEventType(event: CloudEvent): String {
    return when (event.type) {
        "Content Update" -> {
            val updateType = event.tracingcontext?.getString("updateType")
            when (updateType) {
                "ADD" -> "Add Request"
                "REMOVE" -> "Remove Request"
                else -> "Update Request"
            }
        }

        "com.siriusxm.unifiedcontentbus.add" -> "Add Published"
        "com.siriusxm.unifiedcontentbus.update" -> "Update Published"
        "com.siriusxm.unifiedcontentbus.remove" -> "Remove Published"
        else -> event.type
    }
}

/**
 * Displays events for an entity.
 */
@Composable
fun entityEvents(aggregatorContext: AggregatorService, entityId: String) {
    var loading by remember { mutableStateOf(false) }
    var result by remember { mutableStateOf<EventSearchStatus?>(null) }
    var failureMessage by remember { mutableStateOf<String?>(null) }
    var expanded by remember { mutableStateOf<Set<String>>(emptySet()) }

    var eventsPeriod by remember { mutableStateOf<Period?>(null) }

    var onlyShowDiffs by remember { mutableStateOf(false) }
    var cloudEventChangeMap by remember { mutableStateOf<Map<String, CloudEventChanges>?>(null) }


    LaunchedEffect(entityId, eventsPeriod) {
        if (eventsPeriod != null) {
            loading = true
            val startTime = eventsPeriod!!.start()
            var query = aggregatorContext.api.entityEvents(entityId, null, startTime)
            try {
                while (query.state == SearchStatusState.IN_PROGRESS) {
                    delay(500)
                    query = aggregatorContext.api.entityEvents(entityId, query.id, startTime)
                }

                if (query.state == SearchStatusState.COMPLETED) {
                    result = query
                }

            } catch (e: Throwable) {
                failureMessage = e.message ?: "The event search failed for an unknown reason."
            }
            // after we have loaded, we want to save off the processing of the diffs
            val cloudEventChangesMap = mutableMapOf<String, CloudEventChanges>()
            val publishedCloudEvents =
                result?.results.orEmpty().filter { getHumanReadableEventType(it).contains("Published") }
            publishedCloudEvents.windowed(size = 2, step = 1) { it[0] to it[1] }.forEach {
                val cloudEventChanges = getCloudEventChanges(it.first, it.second)
                if (cloudEventChanges.changedKeys.size > 0) {
                    cloudEventChangesMap[it.first.id] = cloudEventChanges
                    console.log(it.first.id + " ", cloudEventChanges)
                }
            }

            cloudEventChangeMap = cloudEventChangesMap
            loading = false
        }
    }

    Div({
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.FlexEnd)
            width(100.percent)
            padding(5.px, 0.px)
        }
    }) {
        Div {
            timeRangeSelect(periods = eventPeriods.map {
                RelativeDuration(it.name, it.duration)
            }, selected = TimeRange(eventsPeriod?.duration)) { range ->
                eventsPeriod = eventPeriods.find { it.duration == range.relative }
            }

            if (eventsPeriod != null && result?.results?.isNotEmpty() == true) {
                Div({
                    classes(AggregatorStyles.entitySubInfo)
                }) {
                    simpleCheckBox("Only show differences", onlyShowDiffs) {
                        onlyShowDiffs = it
                    }
                }
            }
        }
    }

    box({
        paddedContent = false
//        header({
//            actionContent {
//                timeRangeSelect(periods = eventPeriods.map {
//                    RelativeDuration(it.name, it.duration)
//                }, selected = TimeRange(eventsPeriod?.duration)) {
//                    eventsPeriod = it.period()
//                }
//
//                if (eventsPeriod != null) {
//                    Div({
//                        classes(AggregatorStyles.entitySubInfo)
//                    }) {
//                        simpleCheckBox("Only show diferences", onlyShowDiffs) {
//                            onlyShowDiffs = it
//                        }
//                    }
//                }
//            }
//        }) {}
    }) {
        if (loading) {
            boxSpinner("Loading events... (this can take a few seconds)")
        } else if (failureMessage != null) {
            boxMessage(failureMessage!!)
        } else if (eventsPeriod == null) {
            boxMessage("Please select a time period. The loading time will vary with each time period")
        } else {
            val totalResultCount = result?.results.orEmpty().size
            val filteredResults = result?.results.orEmpty().filter {
                !onlyShowDiffs || cloudEventChangeMap?.containsKey(it.id) == true
            }

            if (filteredResults.size != totalResultCount) {
                Div({
                    classes(AggregatorStyles.entitySubInfo)
                }) {
                    Text("Showing ${filteredResults.size} of $totalResultCount")
                }
            }

            if (filteredResults.isNotEmpty()) {
                renderCloudEventTable(filteredResults, expanded, onlyShowDiffs, cloudEventChangeMap) {
                    expanded = it
                }
            } else {
                boxMessage("No events were found")
            }
        }
    }
}

@Composable
private fun renderCloudEventTable(
    filteredResults: List<CloudEvent>,
    expanded: Set<String>,
    onlyShowDiffs: Boolean,
    cloudEventChangeMap: Map<String, CloudEventChanges>?,
    expandCb: (Set<String>) -> Unit
) {
    table<CloudEvent> {
        items(filteredResults)

        column {
            width = 20.px
            style {
                property("vertical-align", "top")
            }
            content { event ->
                if (expanded.contains(event.id)) {
                    icon("arrow_drop_down") {
                        action {
                            expandCb(expanded - event.id)
                        }
                    }
                } else {
                    icon("arrow_right") {
                        action {
                            expandCb(expanded + event.id)
                        }
                    }
                }
            }
        }

        column {
            content { event ->
                Div {
                    Div({ style { margin(3.px, 0.px) } }) {
                        Span({
                            style {
                                fontStyle("italic")
                                opacity(.8)
                            }
                        }) {
                            Text("${event.time.toLocalDateTimeString()}:")
                        }
                        Span({
                            style {
                                marginLeft(5.px)
                                fontWeight(700)
                            }
                        }) {
                            Text(getHumanReadableEventType(event))
                        }

                        if (onlyShowDiffs) {
                            Span({
                                style {
                                    marginLeft(5.px)
                                    fontWeight(700)
                                }
                            }) {
                                Text(event.id)
                            }

                            if (cloudEventChangeMap?.containsKey(event.id) == true) {
                                Span({
                                    style {
                                        marginLeft(5.px)
                                        fontWeight(700)
                                    }
                                }) {
                                    val changedKeys =
                                        cloudEventChangeMap.get(event.id)?.changedKeys ?: listOf()
                                    Text("Fields changed: (${changedKeys.joinToString(", ")})")
                                }
                            }
                        }
                    }
                    if (expanded.contains(event.id)) {
                        if (!onlyShowDiffs) {
                            Div {
                                event.data?.let {
                                    Div({
                                        style {
                                            backgroundColor(SXMUI.containerContentBackgroundColor.value())
                                        }
                                    }) {
                                        jsonTable(null, it.jsonObject, true)
                                    }
                                }
                            }
                        } else {
                            Div {
                                event.data?.let {
                                    val changes = cloudEventChangeMap?.get(event.id)
                                    val changedKeys = changes?.changedKeys ?: listOf()
                                    val diffs = it.jsonObject.filter { changedKeys.contains(it.key) }
                                    val diffsJson = JsonObject(diffs)

                                    Div({
                                        style {
                                            backgroundColor(SXMUI.containerContentBackgroundColor.value())
                                        }
                                    }) {
                                        Div {
                                            Text("New")
                                        }
                                        jsonTable(null, diffsJson, false)
                                    }

                                    Div({
                                        style {
                                            backgroundColor(SXMUI.containerContentBackgroundColor.value())
                                        }
                                    }) {
                                        val previousMap = mutableMapOf<String, JsonElement>()
                                        changedKeys.forEach {
                                            previousMap[it] = (changes?.previousValues?.get(it) ?: JsonNull)
                                        }
                                        val previousJson = JsonObject(previousMap)
                                        Div {
                                            Text("Previous")
                                        }
                                        jsonTable(null, previousJson, false)
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}