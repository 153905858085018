@file:Suppress("unused", "UNUSED_CHANGED_VALUE", "PropertyName", "FunctionName", "ClassName")

package com.siriusxm.pia.client.api.client

import com.siriusxm.pia.client.api.AWSDate
import com.siriusxm.pia.client.api.AWSDateTime
import com.siriusxm.pia.client.api.AWSURL
import com.siriusxm.pia.client.api.CSShowUpdate
import com.siriusxm.pia.client.api.ContentFlagMatcherInput
import com.siriusxm.pia.client.api.ContentFlagUpdateInput
import com.siriusxm.pia.client.api.DomainUpdateInput
import com.siriusxm.pia.client.api.FeedInput
import com.siriusxm.pia.client.api.FeedLicenseInput
import com.siriusxm.pia.client.api.FeedOwnerInput
import com.siriusxm.pia.client.api.ImageSpecInput
import com.siriusxm.pia.client.api.ShowUpdateInput
import com.siriusxm.pia.client.api.ShowsRequest
import com.siriusxm.pia.client.api.ValidateFeedsInput
import com.siriusxm.pia.client.api.json
import com.steamstreet.graphkt.GraphKtQuery
import com.steamstreet.graphkt.client.GraphQLClient
import com.steamstreet.graphkt.client.QueryWriter
import kotlin.Boolean
import kotlin.Int
import kotlin.String
import kotlin.Suppress
import kotlin.Unit
import kotlin.collections.List
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.builtins.serializer

@GraphKtQuery
class _QueryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun feed(url: String, block: _FeedQuery.() -> Unit) {
    writer.print("feed")
    writer.print("(")
    writer.print("url: \$${writer.variable("url", "AWSURL!", AWSURL.serializer(), url)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _FeedQuery(it).block()
    }
    writer.println("}")
  }

  fun show(id: String, block: _ShowQuery.() -> Unit) {
    writer.print("show")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "String!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun catalogShow(typedEntityId: String, block: _ShowQuery.() -> Unit) {
    writer.print("catalogShow")
    writer.print("(")
    writer.print("typedEntityId: \$${writer.variable("typedEntityId", "String!", typedEntityId)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun showBySlug(slug: String, block: _ShowQuery.() -> Unit) {
    writer.print("showBySlug")
    writer.print("(")
    writer.print("slug: \$${writer.variable("slug", "String!", slug)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun shows(input: ShowsRequest, block: _ShowsResponseQuery.() -> Unit) {
    writer.print("shows")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "ShowsRequest!", ShowsRequest.serializer(),
        input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowsResponseQuery(it).block()
    }
    writer.println("}")
  }

  fun showsByOwner(ownerEmail: String, block: _ShowsResponseQuery.() -> Unit) {
    writer.print("showsByOwner")
    writer.print("(")
    writer.print("ownerEmail: \$${writer.variable("ownerEmail", "String!", ownerEmail)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowsResponseQuery(it).block()
    }
    writer.println("}")
  }

  fun showsByDirectory(
    directory: String,
    token: String?,
    limit: Int?,
    block: _ShowsResponseQuery.() -> Unit
  ) {
    writer.print("showsByDirectory")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("directory: \$${writer.variable("directory", "String!", directory)}")
    if (count++ > 0) writer.print(", ")
    if (token != null) {
      writer.print("token: \$${writer.variable("token", "String", token)}")
    } else {
      writer.print("token: \$${writer.variable("token", "String", null)}")
    }
    if (count++ > 0) writer.print(", ")
    if (limit != null) {
      writer.print("limit: \$${writer.variable("limit", "Int", limit)}")
    } else {
      writer.print("limit: \$${writer.variable("limit", "Int", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowsResponseQuery(it).block()
    }
    writer.println("}")
  }

  fun contentService(block: _ContentServiceQuery.() -> Unit) {
    writer.print("contentService")
    writer.println(" {")
    writer.indent {
      _ContentServiceQuery(it).block()
    }
    writer.println("}")
  }

  fun flags(block: _ContentFlagsQuery.() -> Unit) {
    writer.print("flags")
    writer.println(" {")
    writer.indent {
      _ContentFlagsQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _MutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun show(id: String, block: _ShowMutationQuery.() -> Unit) {
    writer.print("show")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "String!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowMutationQuery(it).block()
    }
    writer.println("}")
  }

  fun feed(url: String, block: _FeedMutationQuery.() -> Unit) {
    writer.print("feed")
    writer.print("(")
    writer.print("url: \$${writer.variable("url", "AWSURL!", AWSURL.serializer(), url)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _FeedMutationQuery(it).block()
    }
    writer.println("}")
  }

  fun addFeed(inputs: List<FeedInput>, block: _FeedQuery.() -> Unit) {
    writer.print("addFeed")
    writer.print("(")
    writer.print("inputs: \$${writer.variable("inputs", "[FeedInput!]!",
        ListSerializer(FeedInput.serializer()), inputs)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _FeedQuery(it).block()
    }
    writer.println("}")
  }

  fun addShow(
    feed: FeedInput,
    show: ShowUpdateInput,
    block: _ShowQuery.() -> Unit
  ) {
    writer.print("addShow")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("feed: \$${writer.variable("feed", "FeedInput!", FeedInput.serializer(), feed)}")
    if (count++ > 0) writer.print(", ")
    writer.print("show: \$${writer.variable("show", "ShowUpdateInput!",
        ShowUpdateInput.serializer(), show)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun resetFeeds(feeds: List<FeedInput>) {
    writer.print("resetFeeds")
    writer.print("(")
    writer.print("feeds: \$${writer.variable("feeds", "[FeedInput!]!",
        ListSerializer(FeedInput.serializer()), feeds)}")
    writer.print(")")
  }

  fun refreshFeeds(inputs: List<FeedInput>) {
    writer.print("refreshFeeds")
    writer.print("(")
    writer.print("inputs: \$${writer.variable("inputs", "[FeedInput!]!",
        ListSerializer(FeedInput.serializer()), inputs)}")
    writer.print(")")
  }

  fun validateFeeds(input: ValidateFeedsInput, block: _FeedValidationResponseQuery.() -> Unit) {
    writer.print("validateFeeds")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "ValidateFeedsInput!",
        ValidateFeedsInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _FeedValidationResponseQuery(it).block()
    }
    writer.println("}")
  }

  fun contentService(block: _ContentServiceMutatorQuery.() -> Unit) {
    writer.print("contentService")
    writer.println(" {")
    writer.indent {
      _ContentServiceMutatorQuery(it).block()
    }
    writer.println("}")
  }

  fun domain(domain: String, block: _DomainMutationQuery.() -> Unit) {
    writer.print("domain")
    writer.print("(")
    writer.print("domain: \$${writer.variable("domain", "String!", domain)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _DomainMutationQuery(it).block()
    }
    writer.println("}")
  }

  fun updateFeedLicense(input: FeedLicenseInput) {
    writer.print("updateFeedLicense")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "FeedLicenseInput!",
        FeedLicenseInput.serializer(), input)}")
    writer.print(")")
  }

  fun updateFeedOwner(input: FeedOwnerInput) {
    writer.print("updateFeedOwner")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "FeedOwnerInput!",
        FeedOwnerInput.serializer(), input)}")
    writer.print(")")
  }

  fun flags(block: _ContentFlagsMutationQuery.() -> Unit) {
    writer.print("flags")
    writer.println(" {")
    writer.indent {
      _ContentFlagsMutationQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowMutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun feeds(block: _FeedMutationQuery.() -> Unit) {
    writer.print("feeds")
    writer.println(" {")
    writer.indent {
      _FeedMutationQuery(it).block()
    }
    writer.println("}")
  }

  fun update(input: ShowUpdateInput, block: _ShowQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "ShowUpdateInput!",
        ShowUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun transcribe(episodeGuid: String?) {
    writer.print("transcribe")
    writer.print("(")
    if (episodeGuid != null) {
      writer.print("episodeGuid: \$${writer.variable("episodeGuid", "String", episodeGuid)}")
    } else {
      writer.print("episodeGuid: \$${writer.variable("episodeGuid", "String", null)}")
    }
    writer.print(")")
  }
}

@GraphKtQuery
class _FeedMutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val url: Unit
    get() {
      writer.println("url")
    }

  fun reset(refreshNow: Boolean) {
    writer.print("reset")
    writer.print("(")
    writer.print("refreshNow: \$${writer.variable("refreshNow", "Boolean!", refreshNow)}")
    writer.print(")")
  }

  fun refresh(reset: Boolean, sync: Boolean) {
    writer.print("refresh")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("reset: \$${writer.variable("reset", "Boolean!", reset)}")
    if (count++ > 0) writer.print(", ")
    writer.print("sync: \$${writer.variable("sync", "Boolean!", sync)}")
    writer.print(")")
  }

  fun update(input: FeedInput) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "FeedInput!", FeedInput.serializer(),
        input)}")
    writer.print(")")
  }

  fun detach(showId: String) {
    writer.print("detach")
    writer.print("(")
    writer.print("showId: \$${writer.variable("showId", "String!", showId)}")
    writer.print(")")
  }
}

@GraphKtQuery
class _PodswagQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val url: Unit
    get() {
      writer.println("url")
    }
}

@GraphKtQuery
class _FeedQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val url: Unit
    get() {
      writer.println("url")
    }

  val bundleId: Unit
    get() {
      writer.println("bundleId")
    }

  val rssUrl: Unit
    get() {
      writer.println("rssUrl")
    }

  val normalizedUrl: Unit
    get() {
      writer.println("normalizedUrl")
    }

  val active: Unit
    get() {
      writer.println("active")
    }

  val nextFetch: Unit
    get() {
      writer.println("nextFetch")
    }

  fun show(block: _ShowQuery.() -> Unit) {
    writer.print("show")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun bundles(block: _BundleQuery.() -> Unit) {
    writer.print("bundles")
    writer.println(" {")
    writer.indent {
      _BundleQuery(it).block()
    }
    writer.println("}")
  }

  fun normalized(block: _ShowQuery.() -> Unit) {
    writer.print("normalized")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun moves(block: _FeedMoveQuery.() -> Unit) {
    writer.print("moves")
    writer.println(" {")
    writer.indent {
      _FeedMoveQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _FeedMoveQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val url: Unit
    get() {
      writer.println("url")
    }

  val date: Unit
    get() {
      writer.println("date")
    }

  val moveLocation: Unit
    get() {
      writer.println("moveLocation")
    }
}

@GraphKtQuery
class _BundleQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }
}

@GraphKtQuery
class _ShowQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val title: Unit
    get() {
      writer.println("title")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val explicit: Unit
    get() {
      writer.println("explicit")
    }

  val language: Unit
    get() {
      writer.println("language")
    }

  val content: Unit
    get() {
      writer.println("content")
    }

  val slugs: Unit
    get() {
      writer.println("slugs")
    }

  val added: Unit
    get() {
      writer.println("added")
    }

  fun owner(block: _PartyQuery.() -> Unit) {
    writer.print("owner")
    writer.println(" {")
    writer.indent {
      _PartyQuery(it).block()
    }
    writer.println("}")
  }

  fun authors(block: _PartyQuery.() -> Unit) {
    writer.print("authors")
    writer.println(" {")
    writer.indent {
      _PartyQuery(it).block()
    }
    writer.println("}")
  }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun episodes(
    startDate: String?,
    endDate: String?,
    limit: Int,
    token: String?,
    block: _EpisodesResultQuery.() -> Unit
  ) {
    writer.print("episodes")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    if (startDate != null) {
      writer.print("startDate: \$${writer.variable("startDate", "AWSDateTime",
          AWSDateTime.serializer(), startDate)}")
    } else {
      writer.print("startDate: \$${writer.variable("startDate", "AWSDateTime", null)}")
    }
    if (count++ > 0) writer.print(", ")
    if (endDate != null) {
      writer.print("endDate: \$${writer.variable("endDate", "AWSDateTime", AWSDateTime.serializer(),
          endDate)}")
    } else {
      writer.print("endDate: \$${writer.variable("endDate", "AWSDateTime", null)}")
    }
    if (count++ > 0) writer.print(", ")
    writer.print("limit: \$${writer.variable("limit", "Int!", limit)}")
    if (count++ > 0) writer.print(", ")
    if (token != null) {
      writer.print("token: \$${writer.variable("token", "String", token)}")
    } else {
      writer.print("token: \$${writer.variable("token", "String", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EpisodesResultQuery(it).block()
    }
    writer.println("}")
  }

  fun episode(guid: String?, block: _EpisodeQuery.() -> Unit) {
    writer.print("episode")
    writer.print("(")
    if (guid != null) {
      writer.print("guid: \$${writer.variable("guid", "String", guid)}")
    } else {
      writer.print("guid: \$${writer.variable("guid", "String", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _EpisodeQuery(it).block()
    }
    writer.println("}")
  }

  fun keywords(block: _KeywordQuery.() -> Unit) {
    writer.print("keywords")
    writer.println(" {")
    writer.indent {
      _KeywordQuery(it).block()
    }
    writer.println("}")
  }

  fun categories(block: _CategoryQuery.() -> Unit) {
    writer.print("categories")
    writer.println(" {")
    writer.indent {
      _CategoryQuery(it).block()
    }
    writer.println("}")
  }

  fun csShow(block: _ContentServiceShowQuery.() -> Unit) {
    writer.print("csShow")
    writer.println(" {")
    writer.indent {
      _ContentServiceShowQuery(it).block()
    }
    writer.println("}")
  }

  fun configuration(block: _ShowConfigurationQuery.() -> Unit) {
    writer.print("configuration")
    writer.println(" {")
    writer.indent {
      _ShowConfigurationQuery(it).block()
    }
    writer.println("}")
  }

  fun feeds(block: _FeedQuery.() -> Unit) {
    writer.print("feeds")
    writer.println(" {")
    writer.indent {
      _FeedQuery(it).block()
    }
    writer.println("}")
  }

  fun sources(block: _FeedQuery.() -> Unit) {
    writer.print("sources")
    writer.println(" {")
    writer.indent {
      _FeedQuery(it).block()
    }
    writer.println("}")
  }

  fun metrics(block: _ShowMetricsQuery.() -> Unit) {
    writer.print("metrics")
    writer.println(" {")
    writer.indent {
      _ShowMetricsQuery(it).block()
    }
    writer.println("}")
  }

  fun episodeSummary(block: _EpisodeSummaryQuery.() -> Unit) {
    writer.print("episodeSummary")
    writer.println(" {")
    writer.indent {
      _EpisodeSummaryQuery(it).block()
    }
    writer.println("}")
  }

  fun podswag(block: _PodswagQuery.() -> Unit) {
    writer.print("podswag")
    writer.println(" {")
    writer.indent {
      _PodswagQuery(it).block()
    }
    writer.println("}")
  }

  fun audit(block: _ShowAuditQuery.() -> Unit) {
    writer.print("audit")
    writer.println(" {")
    writer.indent {
      _ShowAuditQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _EpisodesResultQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val token: Unit
    get() {
      writer.println("token")
    }

  fun episodes(block: _EpisodeQuery.() -> Unit) {
    writer.print("episodes")
    writer.println(" {")
    writer.indent {
      _EpisodeQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _EpisodeSummaryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val count: Unit
    get() {
      writer.println("count")
    }

  fun seasons(block: _SeasonSummaryQuery.() -> Unit) {
    writer.print("seasons")
    writer.println(" {")
    writer.indent {
      _SeasonSummaryQuery(it).block()
    }
    writer.println("}")
  }

  fun years(block: _YearSummaryQuery.() -> Unit) {
    writer.print("years")
    writer.println(" {")
    writer.indent {
      _YearSummaryQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _SeasonSummaryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val number: Unit
    get() {
      writer.println("number")
    }

  val count: Unit
    get() {
      writer.println("count")
    }
}

@GraphKtQuery
class _YearSummaryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val year: Unit
    get() {
      writer.println("year")
    }

  val count: Unit
    get() {
      writer.println("count")
    }
}

@GraphKtQuery
class _DirectoryShowInformationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val showId: Unit
    get() {
      writer.println("showId")
    }

  val showUrl: Unit
    get() {
      writer.println("showUrl")
    }

  fun directory(block: _ShowDirectoryQuery.() -> Unit) {
    writer.print("directory")
    writer.println(" {")
    writer.indent {
      _ShowDirectoryQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowAuditQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun entries(
    token: String?,
    max: Int?,
    block: _ShowAuditEntriesQuery.() -> Unit
  ) {
    writer.print("entries")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    if (token != null) {
      writer.print("token: \$${writer.variable("token", "String", token)}")
    } else {
      writer.print("token: \$${writer.variable("token", "String", null)}")
    }
    if (count++ > 0) writer.print(", ")
    if (max != null) {
      writer.print("max: \$${writer.variable("max", "Int", max)}")
    } else {
      writer.print("max: \$${writer.variable("max", "Int", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowAuditEntriesQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowAuditEntriesQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val token: Unit
    get() {
      writer.println("token")
    }

  fun entries(block: _ShowAuditEntryQuery.() -> Unit) {
    writer.print("entries")
    writer.println(" {")
    writer.indent {
      _ShowAuditEntryQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowAuditEntryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val type: Unit
    get() {
      writer.println("type")
    }

  val message: Unit
    get() {
      writer.println("message")
    }

  val ts: Unit
    get() {
      writer.println("ts")
    }

  val note: Unit
    get() {
      writer.println("note")
    }

  fun updater(block: _PartyQuery.() -> Unit) {
    writer.print("updater")
    writer.println(" {")
    writer.indent {
      _PartyQuery(it).block()
    }
    writer.println("}")
  }

  fun old(block: _ShowConfigurationQuery.() -> Unit) {
    writer.print("old")
    writer.println(" {")
    writer.indent {
      _ShowConfigurationQuery(it).block()
    }
    writer.println("}")
  }

  fun new(block: _ShowConfigurationQuery.() -> Unit) {
    writer.print("new")
    writer.println(" {")
    writer.indent {
      _ShowConfigurationQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowDirectoryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val directoryId: Unit
    get() {
      writer.println("directoryId")
    }

  val directoryName: Unit
    get() {
      writer.println("directoryName")
    }
}

@GraphKtQuery
class _ShowMetricsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun range(
    start: String?,
    end: String?,
    block: _ShowMetricsDataQuery.() -> Unit
  ) {
    writer.print("range")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    if (start != null) {
      writer.print("start: \$${writer.variable("start", "AWSDate", AWSDate.serializer(), start)}")
    } else {
      writer.print("start: \$${writer.variable("start", "AWSDate", null)}")
    }
    if (count++ > 0) writer.print(", ")
    if (end != null) {
      writer.print("end: \$${writer.variable("end", "AWSDate", AWSDate.serializer(), end)}")
    } else {
      writer.print("end: \$${writer.variable("end", "AWSDate", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowMetricsDataQuery(it).block()
    }
    writer.println("}")
  }

  fun errors(
    start: String?,
    end: String?,
    block: _ShowErrorQuery.() -> Unit
  ) {
    writer.print("errors")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    if (start != null) {
      writer.print("start: \$${writer.variable("start", "AWSDateTime", AWSDateTime.serializer(),
          start)}")
    } else {
      writer.print("start: \$${writer.variable("start", "AWSDateTime", null)}")
    }
    if (count++ > 0) writer.print(", ")
    if (end != null) {
      writer.print("end: \$${writer.variable("end", "AWSDateTime", AWSDateTime.serializer(), end)}")
    } else {
      writer.print("end: \$${writer.variable("end", "AWSDateTime", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ShowErrorQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowErrorQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val ts: Unit
    get() {
      writer.println("ts")
    }

  val message: Unit
    get() {
      writer.println("message")
    }

  val resourceArn: Unit
    get() {
      writer.println("resourceArn")
    }

  val resourceName: Unit
    get() {
      writer.println("resourceName")
    }

  val details: Unit
    get() {
      writer.println("details")
    }

  val type: Unit
    get() {
      writer.println("type")
    }
}

@GraphKtQuery
class _ShowMetricsDataQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val start: Unit
    get() {
      writer.println("start")
    }

  val end: Unit
    get() {
      writer.println("end")
    }

  val adds: Unit
    get() {
      writer.println("adds")
    }

  val removes: Unit
    get() {
      writer.println("removes")
    }

  val updates: Unit
    get() {
      writer.println("updates")
    }
}

@GraphKtQuery
class _EpisodeQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val guid: Unit
    get() {
      writer.println("guid")
    }

  val title: Unit
    get() {
      writer.println("title")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  val date: Unit
    get() {
      writer.println("date")
    }

  val season: Unit
    get() {
      writer.println("season")
    }

  val seasonEpisodeNumber: Unit
    get() {
      writer.println("seasonEpisodeNumber")
    }

  fun images(block: _ImageQuery.() -> Unit) {
    writer.print("images")
    writer.println(" {")
    writer.indent {
      _ImageQuery(it).block()
    }
    writer.println("}")
  }

  fun media(block: _MediaQuery.() -> Unit) {
    writer.print("media")
    writer.println(" {")
    writer.indent {
      _MediaQuery(it).block()
    }
    writer.println("}")
  }

  fun keywords(block: _KeywordQuery.() -> Unit) {
    writer.print("keywords")
    writer.println(" {")
    writer.indent {
      _KeywordQuery(it).block()
    }
    writer.println("}")
  }

  fun categories(block: _CategoryQuery.() -> Unit) {
    writer.print("categories")
    writer.println(" {")
    writer.indent {
      _CategoryQuery(it).block()
    }
    writer.println("}")
  }

  fun typedEntityId(showId: String) {
    writer.print("typedEntityId")
    writer.print("(")
    writer.print("showId: \$${writer.variable("showId", "String!", showId)}")
    writer.print(")")
  }
}

@GraphKtQuery
class _MediaQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val url: Unit
    get() {
      writer.println("url")
    }

  val duration: Unit
    get() {
      writer.println("duration")
    }

  val bitrate: Unit
    get() {
      writer.println("bitrate")
    }

  val encoder: Unit
    get() {
      writer.println("encoder")
    }

  val contentType: Unit
    get() {
      writer.println("contentType")
    }

  val sourceGuid: Unit
    get() {
      writer.println("sourceGuid")
    }

  val sourceDate: Unit
    get() {
      writer.println("sourceDate")
    }

  fun bundles(block: _BundleQuery.() -> Unit) {
    writer.print("bundles")
    writer.println(" {")
    writer.indent {
      _BundleQuery(it).block()
    }
    writer.println("}")
  }

  fun transcription(block: _TranscriptionQuery.() -> Unit) {
    writer.print("transcription")
    writer.println(" {")
    writer.indent {
      _TranscriptionQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _FeedValidationResponseQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val url: Unit
    get() {
      writer.println("url")
    }

  fun issues(block: _FeedValidationIssueQuery.() -> Unit) {
    writer.print("issues")
    writer.println(" {")
    writer.indent {
      _FeedValidationIssueQuery(it).block()
    }
    writer.println("}")
  }

  fun show(block: _ShowQuery.() -> Unit) {
    writer.print("show")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun matches(block: _ShowMatchQuery.() -> Unit) {
    writer.print("matches")
    writer.println(" {")
    writer.indent {
      _ShowMatchQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowMatchQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val confidence: Unit
    get() {
      writer.println("confidence")
    }

  fun show(block: _ShowQuery.() -> Unit) {
    writer.print("show")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _TranscriptionQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val vtt: Unit
    get() {
      writer.println("vtt")
    }
}

@GraphKtQuery
class _ShowConfigurationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val active: Unit
    get() {
      writer.println("active")
    }

  val transcribe: Unit
    get() {
      writer.println("transcribe")
    }

  val transcriptionsPublished: Unit
    get() {
      writer.println("transcriptionsPublished")
    }

  val directories: Unit
    get() {
      writer.println("directories")
    }

  val license: Unit
    get() {
      writer.println("license")
    }

  val streamOnly: Unit
    get() {
      writer.println("streamOnly")
    }

  val audioBook: Unit
    get() {
      writer.println("audioBook")
    }

  val linkedEntities: Unit
    get() {
      writer.println("linkedEntities")
    }

  fun directoryDetails(block: _DirectoryShowInformationQuery.() -> Unit) {
    writer.print("directoryDetails")
    writer.println(" {")
    writer.indent {
      _DirectoryShowInformationQuery(it).block()
    }
    writer.println("}")
  }

  fun owner(block: _PartyQuery.() -> Unit) {
    writer.print("owner")
    writer.println(" {")
    writer.indent {
      _PartyQuery(it).block()
    }
    writer.println("}")
  }

  fun sources(block: _FeedQuery.() -> Unit) {
    writer.print("sources")
    writer.println(" {")
    writer.indent {
      _FeedQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _FeedValidationIssueQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val type: Unit
    get() {
      writer.println("type")
    }

  val id: Unit
    get() {
      writer.println("id")
    }

  val note: Unit
    get() {
      writer.println("note")
    }

  val element: Unit
    get() {
      writer.println("element")
    }
}

@GraphKtQuery
class _PartyQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val email: Unit
    get() {
      writer.println("email")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val uri: Unit
    get() {
      writer.println("uri")
    }

  val identifier: Unit
    get() {
      writer.println("identifier")
    }
}

@GraphKtQuery
class _ImageQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val src: Unit
    get() {
      writer.println("src")
    }

  val color: Unit
    get() {
      writer.println("color")
    }

  fun sizes(input: List<ImageSpecInput>) {
    writer.print("sizes")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "[ImageSpecInput!]!",
        ListSerializer(ImageSpecInput.serializer()), input)}")
    writer.print(")")
  }
}

@GraphKtQuery
class _KeywordQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val value: Unit
    get() {
      writer.println("value")
    }

  val namespace: Unit
    get() {
      writer.println("namespace")
    }
}

@GraphKtQuery
class _CategoryQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val name: Unit
    get() {
      writer.println("name")
    }

  val namespace: Unit
    get() {
      writer.println("namespace")
    }

  fun subs(block: _CategoryQuery.() -> Unit) {
    writer.print("subs")
    writer.println(" {")
    writer.indent {
      _CategoryQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ShowsResponseQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val token: Unit
    get() {
      writer.println("token")
    }

  fun shows(block: _ShowQuery.() -> Unit) {
    writer.print("shows")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentServiceQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun show(csId: String, block: _ContentServiceShowQuery.() -> Unit) {
    writer.print("show")
    writer.print("(")
    writer.print("csId: \$${writer.variable("csId", "String!", csId)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ContentServiceShowQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentServiceMutatorQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun show(csId: String?, block: _ContentServiceShowMutatorQuery.() -> Unit) {
    writer.print("show")
    writer.print("(")
    if (csId != null) {
      writer.print("csId: \$${writer.variable("csId", "String", csId)}")
    } else {
      writer.print("csId: \$${writer.variable("csId", "String", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ContentServiceShowMutatorQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentServiceShowQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val csId: Unit
    get() {
      writer.println("csId")
    }

  val sync: Unit
    get() {
      writer.println("sync")
    }

  fun show(block: _ShowQuery.() -> Unit) {
    writer.print("show")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentServiceShowMutatorQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun update(input: CSShowUpdate) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "CSShowUpdate!", CSShowUpdate.serializer(),
        input)}")
    writer.print(")")
  }

  fun sync(force: Boolean, block: _ContentServiceSyncResultQuery.() -> Unit) {
    writer.print("sync")
    writer.print("(")
    writer.print("force: \$${writer.variable("force", "Boolean!", force)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ContentServiceSyncResultQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentServiceSyncResultQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val adds: Unit
    get() {
      writer.println("adds")
    }

  val updates: Unit
    get() {
      writer.println("updates")
    }

  val backfills: Unit
    get() {
      writer.println("backfills")
    }
}

@GraphKtQuery
class _DomainMutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val domain: Unit
    get() {
      writer.println("domain")
    }

  val mediaLock: Unit
    get() {
      writer.println("mediaLock")
    }

  fun update(input: DomainUpdateInput, block: _DomainMutationQuery.() -> Unit) {
    writer.print("update")
    writer.print("(")
    writer.print("input: \$${writer.variable("input", "DomainUpdateInput!",
        DomainUpdateInput.serializer(), input)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _DomainMutationQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentFlagsQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun flags(block: _ContentFlagQuery.() -> Unit) {
    writer.print("flags")
    writer.println(" {")
    writer.indent {
      _ContentFlagQuery(it).block()
    }
    writer.println("}")
  }

  fun flag(id: String, block: _ContentFlagQuery.() -> Unit) {
    writer.print("flag")
    writer.print("(")
    writer.print("id: \$${writer.variable("id", "String!", id)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ContentFlagQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentFlagQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val flag: Unit
    get() {
      writer.println("flag")
    }

  val description: Unit
    get() {
      writer.println("description")
    }

  fun matchers(block: _ContentFlagMatcherQuery.() -> Unit) {
    writer.print("matchers")
    writer.println(" {")
    writer.indent {
      _ContentFlagMatcherQuery(it).block()
    }
    writer.println("}")
  }

  fun matches(token: String?, block: _ContentFlagMatchResultQuery.() -> Unit) {
    writer.print("matches")
    writer.print("(")
    if (token != null) {
      writer.print("token: \$${writer.variable("token", "String", token)}")
    } else {
      writer.print("token: \$${writer.variable("token", "String", null)}")
    }
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ContentFlagMatchResultQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentFlagMatcherQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val regex: Unit
    get() {
      writer.println("regex")
    }

  fun flag(block: _ContentFlagQuery.() -> Unit) {
    writer.print("flag")
    writer.println(" {")
    writer.indent {
      _ContentFlagQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentFlagMatchResultQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val token: Unit
    get() {
      writer.println("token")
    }

  fun matches(block: _ContentFlagMatchQuery.() -> Unit) {
    writer.print("matches")
    writer.println(" {")
    writer.indent {
      _ContentFlagMatchQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentFlagMatchQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  val match: Unit
    get() {
      writer.println("match")
    }

  fun flag(block: _ContentFlagQuery.() -> Unit) {
    writer.print("flag")
    writer.println(" {")
    writer.indent {
      _ContentFlagQuery(it).block()
    }
    writer.println("}")
  }

  fun show(block: _ShowQuery.() -> Unit) {
    writer.print("show")
    writer.println(" {")
    writer.indent {
      _ShowQuery(it).block()
    }
    writer.println("}")
  }

  fun episode(block: _EpisodeQuery.() -> Unit) {
    writer.print("episode")
    writer.println(" {")
    writer.indent {
      _EpisodeQuery(it).block()
    }
    writer.println("}")
  }
}

@GraphKtQuery
class _ContentFlagsMutationQuery(
  private val writer: QueryWriter
) {
  val __typename: Unit
    get() {
      writer.println("__typename")
    }

  fun addFlag(
    flag: String,
    update: ContentFlagUpdateInput,
    block: _ContentFlagQuery.() -> Unit
  ) {
    writer.print("addFlag")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("flag: \$${writer.variable("flag", "String!", flag)}")
    if (count++ > 0) writer.print(", ")
    writer.print("update: \$${writer.variable("update", "ContentFlagUpdateInput!",
        ContentFlagUpdateInput.serializer(), update)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ContentFlagQuery(it).block()
    }
    writer.println("}")
  }

  fun addMatcher(
    flag: String,
    update: ContentFlagMatcherInput,
    block: _ContentFlagMatcherQuery.() -> Unit
  ) {
    writer.print("addMatcher")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("flag: \$${writer.variable("flag", "String!", flag)}")
    if (count++ > 0) writer.print(", ")
    writer.print("update: \$${writer.variable("update", "ContentFlagMatcherInput!",
        ContentFlagMatcherInput.serializer(), update)}")
    writer.print(")")
    writer.println(" {")
    writer.indent {
      _ContentFlagMatcherQuery(it).block()
    }
    writer.println("}")
  }

  fun removeMatcher(flag: String, matcherRegex: String) {
    writer.print("removeMatcher")
    writer.print("(")
    var count = 0
    if (count++ > 0) writer.print(", ")
    writer.print("flag: \$${writer.variable("flag", "String!", flag)}")
    if (count++ > 0) writer.print(", ")
    writer.print("matcherRegex: \$${writer.variable("matcherRegex", "String!", matcherRegex)}")
    writer.print(")")
  }
}

suspend fun GraphQLClient.query(name: String? = null, block: _QueryQuery.() -> Unit): Query {
  val result = executeAndParse(name, json, ::Query) {
    this.type = "query"
    _QueryQuery(this).block()
  }
  return result
}

suspend fun GraphQLClient.mutation(name: String? = null, block: _MutationQuery.() -> Unit):
    Mutation {
  val result = executeAndParse(name, json, ::Mutation) {
    this.type = "mutation"
    _MutationQuery(this).block()
  }
  return result
}
