package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.Size
import com.siriusxm.pia.components.primaryContentArea
import com.siriusxm.pia.components.serviceContentView
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import contentingestion.unifiedmodel.League
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * The main sports dashboard page.
 */

@Composable
fun sportsDashboardPage(sportsContext: SportsService) {

    var leagues by remember { mutableStateOf(emptyList<League>()) }

    LaunchedEffect("leagues") {
        leagues = sportsContext.api.fetchAllLeagues().filterNot { it.id == "SL:" }.sortedBy { it.id }
        console.log("leagues", leagues)
    }

    serviceContentView({
        title = "Leagues"
    }) {
        if (leagues.isEmpty()) {
            spinner(size = Size.LARGE)
        } else {
            leaguesGrid(leagues)
        }
    }
}

@Composable
fun leagueItem(league: League, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    @Composable
    fun leagueContent() {
        Div({ classes(SportsStyles.listItem) }) {
            Div {
                val preferredImage = league.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
                if (preferredImage != null) {
                    Img(src = getAbsoluteUrl(preferredImage)) {
                        style {
                            width(100.px)
                            height(100.px)
                        }
                    }
                }
            }

            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(league.sport ?: "")
                }
                Div({ classes(SportsStyles.entityInfo) }) { Text(league.name) }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(league.id)
                }
            }
        }
    }

    if (includeLink) {
        A("#sports/teams-by-league/${league.id}") {
            leagueContent()
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            leagueContent()
        }
    }
}

@Composable
fun leaguesGrid(
    entities: List<League>,
    includeLinks: Boolean = true,
    onClick: ((League) -> Unit)? = null
) {
    Div({
        classes(SportsStyles.gridList)
    }) {
        entities.forEach { entity ->
            leagueItem(entity, includeLinks && onClick == null) {
                onClick?.invoke(entity)
            }
        }
    }
}

// TODO: make this not a duplicate code
fun getAbsoluteUrl(preferredImage: Image): String {
    return "${Application.configuration.atlasImageServerBaseUrl}${preferredImage.url}"
}

/**
 * Sports Routing
 */
@Composable
fun Route.sportsDashboard(sportsAPI: SportsAPI, aggregatorClient: UnifiedAggregatorClient ) {
    var index by remember { mutableStateOf(1) }
    val sportsContext by remember {
        mutableStateOf(
            SportsService(
                api = sportsAPI,
                reloader = {
                    index++
                }
            )
        )
    }
    val aggregatorContext by remember {
        mutableStateOf(
            AggregatorService(
                api = aggregatorClient,
                reloader =  {
                    index++
                }
            )
        )
    }

    switch {
        select("teams-by-league") {
            switch {
                select(Regex(".+")) {
                    val leagueId = this.match
                    teamsForLeague(sportsContext, leagueId)
                }
            }
        }
        select("events-by-team") {
            switch {
                select(Regex(".+")) {
                    val teamId = this.match
                    eventsForTeam(sportsContext, teamId)
                }
            }
        }

        select("team-by-id") {
            switch {
                select(Regex(".+")) {
                    val teamId = this.match
                    teamById(sportsContext, aggregatorContext, teamId)
                }
            }
        }

        select("scores-for-event") {
            switch {
                select(Regex(".+")) {
                    val eventId = this.match
                    val autoPolling = parameter("autoPolling")
                    val isAutoPolling = "true" == autoPolling
                    scoresForEvent(sportsContext, eventId, isAutoPolling)
                }
            }
        }

        default {
            primaryContentArea {
                sportsDashboardPage(sportsContext)
            }
        }
    }
}