package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.client.api.ShowUpdateInput
import com.siriusxm.pia.components.*
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text

/**
 * The Linked Entities edit page.
 */

@Composable
fun editContentLinks(showId: String, contentLinks: List<String>?, returnPath: String? = null) {

    val coroutineScope = rememberCoroutineScope()
    var showName by remember { mutableStateOf<String?>(null) }

    var loading by mutableStateOf(false)

    var activeContentLinks by remember { mutableStateOf(contentLinks) }
    var activeItem by remember { mutableStateOf<String?>(null) }

    var editingIndex by remember { mutableStateOf<Int?>(null) }
    var editingItem by remember { mutableStateOf<String?>(null) }

    var saving by mutableStateOf(false)
    var savingJob by mutableStateOf<Job?>(null)


    LaunchedEffect(showId) {
        try {
            loading = true
            console.log("Begin looking up content links by id")
            Podcasts.api.query {
                show(showId) {
                    id
                    title

                    configuration {
                        active
                        linkedEntities
                    }
                }

            }.show.let {
                activeContentLinks = it.configuration.linkedEntities?.filterNotNull()
                showName = it.title
            }
            console.log("Finished looking up content links by id")

        } catch (e: CancellationException) {
            // ignored
            console.log("Canceled exception: $e")
        } catch (t: Throwable) {
            console.log("Got an exception: $t")
            activeContentLinks = mutableListOf()
        } finally {
            loading = false
        }
    }


    suspend fun saveContentLinks(showId: String, contentLinks: List<String>) {
        try {
            saving = true

            val input = ShowUpdateInput(
                linkedEntities = contentLinks
            )
            try {
                Podcasts.api.mutate("UpdateConfiguration") {
                    show(showId) {
                        update(input) {
                            id
                        }
                    }
                }.show.update!!.id
                Podcasts.navigate(returnPath ?: "podcasts/show/${showId}/configuration")
            } catch (t: Throwable) {
                Podcasts.context.showError(
                    "When save was attempted, an error was returned.",
                    t.message
                )
            }
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            Podcasts.context.showError(
                "When save was attempted, an error was returned.",
                t.message
            )
        } finally {
            saving = false
        }
    }

    dialogView("Edit Content Links") {
        val links = activeContentLinks
        content {
            box({
                paddedContent = links.isNullOrEmpty() || saving
                header({
                    title = "Content Links:  $showId"
                })
            }) {

                table<String> {

                    items(links)

                    column {
                        title = "Content link"
                        width = 600.px
                        content { phrase ->
                            val index = links?.indexOf(phrase) ?: -1
                            if (editingIndex == null || editingIndex != index) {
                                Div {
                                    Text(phrase)
                                }
                            } else {
                                Input(InputType.Text) {
                                    this.value(editingItem ?: "")
                                    onInput {
                                        editingItem = it.value.ifBlank { null }
                                    }
                                }
                            }
                        }
                    }

                    column {
                        title = "Action"
                        width = 250.px
                        content { phrase ->
                            val index = links?.indexOf(phrase) ?: -1
                            if (editingIndex == null || editingIndex != index) {
                                iconAction("edit") {
                                    val editIndex = links?.indexOf(phrase) ?: -1
                                    console.log("Edit object: $phrase index: $editIndex")

                                    editingIndex = editIndex
                                    editingItem = phrase
                                }

                                iconAction("delete") {
                                    console.log("Remove object: $phrase")
                                    val contentList = mutableListOf<String>()
                                    links?.forEach { item ->
                                        if (item != phrase) {
                                            contentList.add(item)
                                        }
                                    }
                                    activeItem = null
                                    activeContentLinks = contentList
                                }
                            } else {
                                button("Save") {
                                    val item = editingItem
                                    if (item != null) {
                                        val contentList = mutableListOf<String>()
                                        links?.forEachIndexed { index, v ->
                                            if (index != editingIndex) {
                                                contentList.add(v)
                                            } else {
                                                contentList.add(item)
                                            }
                                        }
                                        editingItem = null
                                        editingIndex = null

                                        activeContentLinks = contentList
                                    }
                                }
                                button("Cancel") {
                                    editingItem = null
                                    editingIndex = null
                                }
                            }
                        }
                    }
                }

                /// this means we are not in-line editing a row
                if (editingIndex == null) {
                    dialogField("") {
                        Div {
                            Input(InputType.Text) {
                                this.value(activeItem ?: "")
                                onInput {
                                    activeItem = it.value.ifBlank { null }
                                }
                            }
                            button("Add") {
                                val item = activeItem
                                if (item != null) {
                                    // need to validate the input item upon save
                                    val MAX_WORD_CHARS_COUNT = 50
                                    val id = item.split("\\s+".toRegex())
                                    val tooLongId = id.filter { it.length > MAX_WORD_CHARS_COUNT }

                                    if (links != null && links.contains(item)) {
                                        Application.notifications.showError(
                                            "The Entity '$item' is already linked to this show."
                                        )
                                    } else if (item.isEmpty()) {
                                        Application.notifications.showError(
                                            "The Linked Entity item cannot be empty."
                                        )
                                    } else if (!Regex("^[SH: | LC:].{9,}-.*$").matches(item)) {
                                        Application.notifications.showError(
                                            "The provided ID '$item' is invalid; ID's must start with SH: or LC: followed by a guid"
                                        )
                                    } else if (tooLongId.isNotEmpty()) {
                                        Application.notifications.showError(
                                            "The provided ID '$item' is invalid; The following ID (${
                                                tooLongId.joinToString(
                                                    ","
                                                )
                                            }) violates the id-length constraint; ID cannot be longer than $MAX_WORD_CHARS_COUNT characters."
                                        )
                                    } else {
                                        val linkList = mutableListOf<String>()
                                        links.let {

                                            console.log("it", it)
                                            it?.let { it1 -> linkList.addAll(it1) }

                                        }
                                        linkList.add(item)
                                        activeItem = null
                                        activeContentLinks = linkList
                                    }
                                }
                            }
                        }
                    }
                }
            }

            button("Save") {
                savingJob = coroutineScope.launch {
                    saveContentLinks(showId, activeContentLinks!!)
                }
            }

            button("Clear All") {
                activeItem = null
                activeContentLinks = mutableListOf()
            }

            button("Cancel") {
                Podcasts.navigate(returnPath ?: "podcasts/show/${showId}/configuration")
            }
        }
    }
}


