package com.siriusxm.pia.views.sports

import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.border
import com.siriusxm.pia.components.roundedBox
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.selectors.Nth

object SportsStyles : StyleSheet() {

    val gridList by style {
        marginTop(1.em)
        display(DisplayStyle.Grid)
        gridTemplateColumns("repeat(auto-fill, minmax(${175.px}, 1fr))")
        gap(1.em) // Adjust spacing
        justifyContent(JustifyContent.Center)
    }

    val teamsInfo by style { }
    val eventStatus by style {
        fontWeight(800)
    }
    val teamName by style { }
    val entityImage by style { }

    val fullEvent by style {
        display(DisplayStyle.Flex)

        className(entityImage) style {
            height(128.px)
            type("img") style {
                width(128.px)
                height(128.px)
                property("-webkit-filter", "drop-shadow(0px 0px 2px rgba(0,0,0,.3))")
            }
        }
    }

    val eventItem by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        marginRight(10.px)
        minHeight(120.px)
        alignItems(AlignItems.Center)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())

        className(teamsInfo) style {
            marginRight(20.px)
        }
    }

    val subheader by style {
        margin(10.px)
        fontWeight("bold")
    }

    val metadata by style {
        marginLeft(10.px)
    }

    val entityInfo by style {
        fontSize((1.2.cssRem))
        marginBottom(3.px)
        whiteSpace("no-wrap")
    }

    val imageBox by style {
        display(DisplayStyle.InlineBlock)
        margin(10.px)
        padding(10.px)
    }

    val imageContainer by style {
        width(200.px)
        height(200.px)
        lineHeight(200.px)

        type("img") style {
            maxWidth(200.px)
            maxHeight(200.px)
            property("vertical-align", "middle")
        }
    }

    val imageLabel by style {
        padding(10.px)
        textAlign("center")
    }

    val teamCard by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        alignItems(AlignItems.Stretch)
        padding(1.em)
        textAlign("center")
        cursor("pointer")
        roundedBox()
        backgroundColor(SXMUI.containerContentBackgroundColor.value())

        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
        }

        type("h3") style {
            fontWeight(700)
            minHeight(40.px)
        }

        className(entityImage) style {
            width(100.percent)
            height(100.px)
            property("-webkit-filter", "drop-shadow(0px 0px 5px rgba(0,0,0,.4))")

            type("img") style {
                width(100.px)
                height(100.px)
            }
        }
    }

    val eventRow by style {
        padding(10.px)
        width(100.percent)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        gap(5.px)
        border(0.px, 0.px, 1.px, 0.px, SXMUI.defaultDivider.value())
    }

    val winner by style { }

    val eventRowTeamInfo by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        alignItems(AlignItems.Center)

        className(teamName) style {
            width(250.px)
        }

        self + className(winner) style {
            fontWeight(800)
        }
    }

    val evenRowTeamImage by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Row)
        alignItems(AlignItems.Center)
        width(80.px)

        type("img") style {
            width(64.px)
            height(64.px)
            display(DisplayStyle.InlineBlock)
            property("-webkit-filter", "drop-shadow(0px 0px 2px rgba(0,0,0,.3))")
        }
    }

    val scoreValue by style {}
    val scoreStatus by style {}
    val scoresListItem by style {}
    val timestamp by style {}
    val unchanged by style {}

    val scoresList by style {
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        width(100.percent)

        className(scoreValue) style {
            textAlign("center")
            fontWeight(800)
            fontSize(24.px)


            self + className(unchanged) style {
                color(SXMUI.disabledTextColor.value())
                fontWeight(400)
            }
        }
        className(scoreStatus) style {
            textAlign("center")

        }
        className(scoresListItem) style {
            padding(5.px)
            display(DisplayStyle.Grid)
            gridTemplateColumns("1fr 150px 1fr")
            width(100.percent)
            border(0.px, 0.px, 1.px, 0.px, SXMUI.defaultDivider.value())
            alignItems(AlignItems.Center)
        }
        child(self, universal) style {

        }
        child(self, nthChild(Nth.Odd)) style {
            backgroundColor(SXMUI.containerHeaderBackgroundColor.value())
        }

        className(timestamp) style {
            fontSize(10.px)
        }
    }
}