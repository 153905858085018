package com.siriusxm.pia.utils


import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.setValue
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable
import org.w3c.dom.HashChangeEvent
import org.w3c.dom.Location

interface Navigator {
    /**
     * Navigate to a path
     */
    fun navigate(path: String)

    /**
     * Push a path to the history, without necessarily updating rendering.
     */
    fun push(newPath: String, title: String? = null, render: Boolean = true)
}

/**
 * Encapsulates the page URL, allowing for an easy definition of routing rules to
 * ensure that the correct content is displayed.
 */
interface Route : Navigator {
    val parent: Route?

    /**
     * The full path (typically just window.location.pathname
     */
    val fullPath: String

    /**
     * The path that this route is evaluating
     */
    val subPath: String

    /**
     * The current 'match', which is matched from the most recent select statement.
     */
    val match: String

    /**
     * The part of the path that is still remaining to be evaluated.
     */
    val remainingPath: String

    /**
     * Match a path component. By default, if multiple select calls match, they will
     * all be executed.
     */
    @Composable
    fun select(match: String, route: @Composable Route.() -> Unit)

    /**
     * Match a path component to a regular expression
     */
    @Composable
    fun select(regex: Regex, route: @Composable Route.() -> Unit)

    /**
     * Get the child route for this route
     */
    fun child(): Route?

    /**
     * Define a switch. This changes the behavior of the select by executing only
     * the first select that matches.
     */
    @Composable
    fun switch(statement: @Composable SwitchableRoute.() -> Unit)

    /**
     * Get a query parameter. Returns null if no parameter of that name.
     */
    fun parameter(param: String): String?

    override fun navigate(path: String) {
        val resolvedPath = if (path.startsWith("/")) {
            path
        } else {
            "$subPath/$path".replace("//", "/")
        }
        parent?.navigate(resolvedPath)
    }

    override fun push(newPath: String, title: String?, render: Boolean) {
        val resolvedPath = if (newPath.startsWith("/")) {
            newPath
        } else {
            "$subPath/$newPath".replace("//", "/")
        }
        parent?.push(resolvedPath)
    }
}

open class DefaultRoute(
    final override val parent: Route?,
    final override val fullPath: String,
    final override val subPath: String,
    final override val remainingPath: String,
    val query: String,
    final override val match: String
) : Route {
    private val component = decodeURIComponent(remainingPath.substringBefore("/"))

    @Composable
    override fun select(match: String, route: @Composable Route.() -> Unit) {
        if (isMatch(match)) {
            executeRoute(route)
        }
    }

    @Composable
    override fun select(regex: Regex, route: @Composable Route.() -> Unit) {
        if (regex.matches(component)) {
            executeRoute(route)
        }
    }

    override fun child(): Route? {
        val newPath = remainingPath.substringAfter("/", "")
        return DefaultRoute(this, fullPath, "${subPath}/${component}", newPath, query, component)
    }

    @Composable
    private fun executeRoute(route: @Composable Route.() -> Unit) {
        child()?.let {
            route(it)
        }
    }

    @Composable
    override fun switch(statement: @Composable SwitchableRoute.() -> Unit) {
        SwitchableRoute(this).statement()
    }

    private fun isMatch(select: String): Boolean {
        if (select.startsWith("{") && select.endsWith("}")) {
            val regex = Regex(select.substring(1..select.length - 2))
            return (regex.matches(component))
        } else {
            return select == component
        }
    }

    override fun parameter(param: String): String? {
        return query.split("&").map {
            it.split("=")
        }.filter {
            it.size == 2
        }.map {
            val key = decodeURIComponent(it[0])
            val value = decodeURIComponent(it[1])
            val interpretedValue = if (value.equals("null") == true) {
                null
            } else {
                value
            }
            key to interpretedValue
        }.toMap()[param]
    }
}

class RootRoute(
    location: Location,
    val basePath: String? = null,
    val navigationHandler: ((String) -> Unit)? = null
) : DefaultRoute(
    null,
    location.pathname,
    "",
    location.pathname.removePrefix(basePath ?: "").removePrefix("/"),
    location.search.drop(1),
    ""
) {
    override fun navigate(path: String) {
        val fullPath = if (basePath != null) {
            basePath + path
        } else {
            path
        }
        navigationHandler?.invoke(fullPath)
    }

    override fun push(newPath: String, title: String?, render: Boolean) {
        if (render) {
            navigate(newPath)
        }
    }
}

/**
 * A route that will select only one route, and will stop processing after
 * there is a match.
 */
class SwitchableRoute(val route: DefaultRoute) :
    DefaultRoute(route.parent, route.fullPath, route.subPath, route.remainingPath, route.query, route.match) {
    private var isMatched = false

    @Composable
    override fun select(match: String, route: @Composable Route.() -> Unit) {
        if (!isMatched) {
            super.select(match) {
                isMatched = true
                this.route()
            }
        }
    }

    @Composable
    override fun select(regex: Regex, route: @Composable Route.() -> Unit) {
        if (!isMatched) {
            super.select(regex) {
                isMatched = true
                this.route()
            }
        }
    }

    @Composable
    override fun switch(statement: @Composable SwitchableRoute.() -> Unit) {
        this.statement()
    }

    @Composable
    fun default(route: @Composable Route.() -> Unit) {
        if (!isMatched) {
            super.select("{.*}", route)
        }
    }

    override fun navigate(path: String) {
        this.route.navigate(path)
    }
}

/**
 * Manages navigation for a browser using the hash value.
 */
class HashNavigator(
    private val renderer: @Composable ((Route) -> Unit)
) : Navigator {
    private lateinit var root: DefaultRoute
    var path: String by mutableStateOf("")

    init {
        window.addEventListener("hashchange", { event ->
            val newHash = (event as HashChangeEvent).newURL.substringAfter("#", "")
            path = newHash
        })
    }

    /**
     * Start the rendering process.
     */
    fun run(rootElementId: String = "root") {
        path = window.location.hash.substring(1)
        renderComposable(rootElementId) {
            val query = path.substringAfter("?")
            val pathWithoutQuery = path.substringBefore("?")
            val root = object : DefaultRoute(
                null,
                path,
                "",
                pathWithoutQuery,
                query,
                ""
            ) {
                override fun navigate(path: String) {
                    navigate(path)
                }
            }
            renderer(root)
        }
    }

    /**
     * Push a new hash state to the history. Optionally render the page.
     */
    override fun push(newPath: String, title: String?, render: Boolean) {
        val basePath = window.location.href.substringBefore("#")
        window.history.pushState(null, title ?: window.name, "$basePath#$newPath")

        if (render) {
            path = newPath
        }
    }

    override fun navigate(path: String) {
        push(path)
    }
}

lateinit var navigator: HashNavigator