@file:OptIn(ExperimentalComposeWebApi::class)

package com.siriusxm.pia.components

import androidx.compose.runtime.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.LineStyle.Companion.Solid
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object Icons : StyleSheet() {
    @OptIn(ExperimentalComposeWebApi::class)
    val spin by keyframes {
        each(100.percent) {
            transform {
                rotate(360.deg)
            }
        }
    }

    val spinner by style {
        display(DisplayStyle.InlineBlock)
        position(Position.Relative)
        animation(spin) {
            duration(1.s)
            iterationCount(null)
            timingFunction(AnimationTimingFunction.Linear)
        }

        type("span").style {
            display(DisplayStyle.InlineBlock)
            lineHeight(40.px)
            width(40.px)
            textAlign("center")
        }
    }

    val loading by style {
        display(DisplayStyle.InlineBlock)
        width(20.px)
        height(20.px)
        border(3.px, Solid, rgba(0, 0, 0, .3))
        borderRadius(50.percent)
        property("border-top-color", "#000")
        animation(spin) {
            duration(1.s)
            timingFunction(AnimationTimingFunction.EaseInOut)
            iterationCount(null)
        }
    }

    val loadingLight by style {
        border(3.px, Solid, rgba(255, 255, 255, .3))
        property("border-top-color", "#fff")
    }

    val loadingSmall by style {
        width(10.px)
        height(10.px)
    }

    val loadingLarge by style {
        width(50.px)
        height(50.px)
    }


    @ExperimentalComposeWebApi
    val logoSpinnerAnimation by keyframes {
        from {
            transform {
                rotateY(0.deg)
            }
        }
        to {
            transform {
                rotateY(360.deg)
            }
        }
    }
}

enum class IconType {
    ICONS,
    SYMBOLS
}

class IconConfiguration(val icon: String) {
    internal var styles: StyleScope.() -> Unit = {}
    internal var action: (suspend () -> Unit)? = null

    var size: IconSize = IconSize.NORMAL
    var title: String? = null
    var type: IconType = IconType.ICONS
    var cursor: String? = null

    fun style(builder: StyleScope.() -> Unit) {
        styles = builder
    }

    fun action(block: suspend () -> Unit) {
        this.action = block
    }
}

enum class IconSize(val selector: String?) {
    TINY("tiny"),
    SMALL("small"),
    NORMAL(null),
    LARGE("large"),
    GIANT("giant")
}

@Composable
fun iconAction(name: String, action: (suspend () -> Unit)) {
    icon(name) {
        this.action(action)
    }
}

@Composable
fun icon(config: IconConfiguration) {
    val coroutineScope = rememberCoroutineScope()

    Span({
        if (config.type == IconType.ICONS) {
            classes("material-icons")
        } else {
            classes("material-symbols-outlined")
        }

        config.title?.let {
            title(it)
        }

        config.size.selector?.let {
            classes(it)
        }

        style {
            property("vertical-align", "middle")
        }

        style {
            if (config.cursor != null) {
                cursor(config.cursor!!)
            } else if (config.action != null) {
                cursor("pointer")
            }
            config.styles(this)
        }
        if (config.action != null) {
            onClick {
                it.stopPropagation()
                it.preventDefault()
                coroutineScope.launch {
                    config.action?.invoke()
                }
            }
        }
    }) {
        Text(config.icon)
    }
}

@Composable
fun icon(name: String, init: IconConfiguration.() -> Unit = {}) {
    icon(IconConfiguration(name).apply(init))
}

@Composable
fun helpIcon() {
    icon("help") {
        size = IconSize.TINY
        cursor = "default"
    }
}

/**
 * Render a Material Symbol (from the larger collection)
 */
@Composable
fun symbol(name: String, init: IconConfiguration.() -> Unit = {}) {
    icon(name) {
        type = IconType.SYMBOLS
        init()
    }
}

enum class Style {
    LIGHT,
    DARK
}

enum class Size {
    SMALL,
    NORMAL,
    LARGE
}

@Composable
fun delayedRender(delay: Long, content: @Composable (Boolean) -> Unit) {
    var visible: Boolean by remember { mutableStateOf(delay == 0L) }

    LaunchedEffect(true) {
        if (delay > 0) {
            delay(delay)
        }
        visible = true
    }
    content(visible)
}

@Composable
fun sxmSpinner(delay: Long = 0) {
    delayedRender(delay) { visible ->
        Div({
            style {
                this.property("visibility", if (visible) "visible" else "hidden")
            }
        }) {
            Img(src = "images/sxm-logo-blue.png", attrs = {
                style {
                    width(100.px)
                    animation(Icons.logoSpinnerAnimation) {
                        timingFunction(AnimationTimingFunction.EaseInOut)
                        iterationCount(Int.MAX_VALUE)
                        duration(2.s)
                    }
                }
            })
        }
    }
}

@Composable
fun spinner(style: Style = Style.DARK, size: Size = Size.NORMAL, delay: Long = 0) {
    delayedRender(delay) { visible ->
        Div({
            classes(Icons.loading)

            if (style == Style.LIGHT) {
                classes(Icons.loadingLight)
            }

            when (size) {
                Size.SMALL -> classes(Icons.loadingSmall)
                Size.LARGE -> classes(Icons.loadingLarge)
                else -> {}
            }

            style {
                if (visible) {
                    this.property("visibility", "visible")
                } else {
                    this.property("visibility", "hidden")
                }
            }
        })
    }
}

@Composable
fun <T : Any> waitUntilNonNull(data: T?, size: Size = Size.NORMAL, delay: Long = 200, block: @Composable (T) -> Unit) {
    if (data == null) {
        Div({ style { padding(10.px) } }) {
            spinner(size = size, delay = delay)
        }
    } else {
        block(data)
    }
}

