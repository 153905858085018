package com.siriusxm.pia

import androidx.compose.runtime.Composable
import com.siriusxm.pia.utils.Navigator
import com.siriusxm.pia.utils.Route

/**
 * Holds data about the environment in which the application is running.
 */
interface ApplicationContext : TokenAccess, Navigator {
    val configuration: Configuration
    val viewer: Viewer

    /**
     * The current path
     */
    val path: String

    fun notification(type: NotificationType, message: String, description: String? = null)

    fun showError(message: String, description: String? = null) {
        notification(
            NotificationType.ERROR,
            message, description
        )
    }

    fun info(message: String, description: String? = null) {
        notification(
            NotificationType.INFO,
            message, description
        )
    }
}

/**
 * Show an error that's come from an exception.
 */
fun ApplicationContext.showError(message: String, throwable: Throwable) {
    showError(message, throwable.message)
}


/**
 * The type of notification to display
 */
enum class NotificationType {
    INFO,
    WARNING,
    ERROR
}

/**
 * Defines the interface for a console application.
 */
interface ConsoleApplication {

    /**
     * Initialize the console application. Until this is called, applications
     * should perform any processing.
     */
    fun initialize(context: ApplicationContext)

    /**
     * Render the given route.
     */
    @Composable
    fun route(route: Route)
}

class DynamicApplication() : ConsoleApplication {
    override fun initialize(context: ApplicationContext) {
    }

    @Composable
    override fun route(route: Route) {
    }
}