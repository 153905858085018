package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.Composable
import com.siriusxm.content.management.console.applications.transcription.TranscriptionAPI
import com.siriusxm.pia.Application.cognito
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.ConsoleApplication
import com.siriusxm.pia.IngestionAPI
import com.siriusxm.pia.IngestionAuditAPI
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.rest.unifiedaggregator.unifiedAggregatorClient
import com.siriusxm.pia.utils.Navigator
import com.siriusxm.pia.utils.Route

/**
 * A global Podcasts application. Not ideal, but slowly moving to something more modular.
 */
lateinit var Podcasts: PodcastsApplication

/**
 * The main podcasts application
 */
class PodcastsApplication : ConsoleApplication, Navigator {
    init {
        Podcasts = this
    }

    lateinit var transcriptionApi: TranscriptionAPI
    lateinit var aggregator: UnifiedAggregatorClient
    lateinit var api: IngestionAPI
    lateinit var context: ApplicationContext

    /**
     * Access to podcast ingestion audit API
     */
    lateinit var ingestionAuditAPI: IngestionAuditAPI


    override fun initialize(context: ApplicationContext) {
        this.context = context
        api = IngestionAPI(context.configuration.apiUrl) {
            cognito.accessToken!!
        }

        ingestionAuditAPI = IngestionAuditAPI(context.configuration.ingestionAuditUrl) {
            cognito.accessToken!!
        }

        transcriptionApi = TranscriptionAPI(context.configuration.transcriptionApiUrl) {
            context.accessToken!!
        }

        aggregator = unifiedAggregatorClient(context)
    }

    override fun navigate(path: String) {
        context.navigate(path)
    }

    override fun push(newPath: String, title: String?, render: Boolean) {
        context.push(newPath, title, render)
    }

    @Composable
    override fun route(route: Route) {
        route.podcasts()
    }
}