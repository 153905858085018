package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.client.SeasonSummary
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.client.api.client.YearSummary
import com.siriusxm.pia.components.*
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * Renders details about a show (description, categories, etc.)
 */
@Composable
fun showContent(showId: String) {
    var show by remember { mutableStateOf<Show?>(null) }

    LaunchedEffect(showId) {
        Podcasts.api.query {
            show(showId) {
                id
                title
                description
                explicit
                language
                owner {
                    email
                    name
                    uri
                }
                authors {
                    email
                    name
                    uri
                }
                keywords {
                    namespace
                    value
                }
                categories {
                    name
                    namespace
                }

                episodeSummary {
                    years {
                        year
                        count
                    }
                    seasons {
                        number
                        count
                    }
                }
            }

        }.show.let {
            show = it
        }
    }

    if (show == null) {
        spinner()
    } else {
        show?.description?.let {
            box("Description") {
                Div {
                    DisposableEffect(show?.description ?: "") {
                        scopeElement.innerHTML = show?.description ?: ""

                        onDispose { }
                    }
                }
            }
        }

        if (show?.owner != null || show?.authors?.isNotEmpty() == true) {
            box("Ownership") {
                detailGrid {
                    show?.owner?.let { owner ->
                        detail("Owner") {
                            Div {
                                owner.name?.let {
                                    Div {
                                        Text(it)
                                    }
                                }
                                owner.email?.let {
                                    Div {
                                        Text(it)
                                    }
                                }
                                owner.uri?.let {
                                    Div {
                                        Text(it)
                                    }
                                }
                            }
                        }
                    }
                    show?.authors?.takeIf { it.isNotEmpty() }?.let { authors ->
                        detail("Authors") {
                            Div {
                                authors.forEach { author ->
                                    Div {
                                        Text(listOfNotNull(author.name, author.email, author.uri).joinToString(", "))
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        keywordsAndCategoriesBox(show?.keywords, show?.categories)

        if (!show?.episodeSummary?.seasons.isNullOrEmpty()) {
            boxTable<SeasonSummary> {
                items(show?.episodeSummary?.seasons.orEmpty())

                column("Season") {
                    width = 200.px
                    content {
                        Text(it.number.toString())
                    }
                }
                column("Episodes") {
                    content {
                        Text(it.count.toString())
                    }
                }
            }
        }

        if (!show?.episodeSummary?.years.isNullOrEmpty()) {
            boxTable<YearSummary> {
                items(show?.episodeSummary?.years.orEmpty())

                column("Year") {
                    width = 200.px
                    content {
                        Text(it.year.toString())
                    }
                }
                column("Episodes") {
                    content {
                        Text(it.count.toString())
                    }
                }
            }
        }
    }
}