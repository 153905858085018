package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.asEntity
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.unifiedaggregator.backfill.backfill
import com.siriusxm.pia.views.unifiedaggregator.backfill.backfills
import com.siriusxm.pia.views.unifiedaggregator.backfill.startBackfill
import com.siriusxm.pia.views.unifiedaggregator.batch.batchJob
import com.siriusxm.pia.views.unifiedaggregator.management.consumerEditor
import com.siriusxm.pia.views.unifiedaggregator.management.consumers
import com.siriusxm.pia.views.unifiedaggregator.management.producerEditor
import com.siriusxm.pia.views.unifiedaggregator.management.producers
import com.siriusxm.pia.views.unifiedaggregator.relationships.relationshipEditor
import com.siriusxm.pia.views.unifiedaggregator.relationships.relationships
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * Routing for the aggregator functionality.
 */
@Composable
fun Route.contentAggregator(
    application: AggregatorApplication
) {
    val aggregatorService by application.aggregatorContext()

    if (aggregatorService == null) {
        serviceView({}) {
            Div({
                style {
                    textAlign("center")
                    fontWeight(700)
                    marginTop(2.em)
                    fontSize(1.3.em)
                }
            }) {
                delayedRender(200) {
                    sxmSpinner()
                    Div {
                        Text("Preparing aggregator access")
                    }
                }
            }
        }
    } else {
        val aggregatorContext = aggregatorService!!
        serviceView({
            simpleNavSidebar(
                true, "Unified Aggregator", listOfNotNull(
                    NavigationItem("Entities", "aggregator"),
                    NavigationItem("Types", "aggregator/types"),
                    NavigationItem("Backfill", "aggregator/backfill"),
                    NavigationItem("Relationships", "aggregator/relationships"),
                    NavigationItem("Producers", "aggregator/producers"),
                    NavigationItem("Consumers", "aggregator/consumers"),
                    NavigationItem("Features", "aggregator/features")
                )
            )
        }) {
            switch {
                select("entity") {
                    switch {
                        select(Regex(".+")) {
                            val entityId = this.match

                            switch {
                                select("partials") {
                                    switch {
                                        serviceContentView {
                                            select(Regex(".*")) {
                                                partialEditor(aggregatorContext, entityId, match.ifBlank { null })
                                            }
                                        }
                                    }
                                }
                                // Select a specific tab
                                select(Regex(".*")) {
                                    aggregatorEntity(aggregatorContext, entityId, match.ifBlank { null })
                                }
                            }
                        }
                    }
                }

                select("types") {
                    dataTypes(aggregatorContext, this)
                }

                select("relationships") {
                    switch {
                        select("edit") {
                            var entity by remember { mutableStateOf<Entity?>(null) }
                            val entityId = parameter("entity")
                            LaunchedEffect(entityId) {
                                if (entityId != entity?.id) {
                                    entity = if (entityId != null) {
                                        aggregatorContext.api.fetchEntityById(entityId).firstOrNull()?.asEntity()
                                    } else {
                                        null
                                    }
                                }
                            }

                            if (entityId != entity?.id) {
                                spinner(size = Size.LARGE)
                            } else {
                                serviceContentView({}) {
                                    relationshipEditor(
                                        aggregatorContext,
                                        schema = parameter("relationship")?.let { relationshipId ->
                                            aggregatorContext.relationships.find { it.id == relationshipId }
                                        },
                                        entity = entity,
                                        returnPath = parameter("return")
                                    )
                                }
                            }
                        }
                        select(Regex(".*")) {
                            relationships(aggregatorContext, match.ifBlank { null })
                        }
                    }
                }

                select("producers") {
                    switch {
                        if (aggregatorContext.isAdmin()) {
                            select("edit") {
                                serviceContentView({}) {
                                    producerEditor(aggregatorContext)
                                }
                            }
                        }
                        select(Regex(".*")) {
                            val producer = aggregatorContext.producerById(match)
                            switch {
                                if (aggregatorContext.isAdmin()) {
                                    select("edit") {
                                        serviceContentView({}) {
                                            producerEditor(aggregatorContext, producer)
                                        }
                                    }
                                }
                                default {
                                    producers(aggregatorContext, producer?.id)
                                }
                            }

                        }
                    }
                }

                select("consumers") {
                    switch {
                        if (aggregatorContext.isAdmin()) {
                            select("edit") {
                                serviceContentView({}) {
                                    consumerEditor(aggregatorContext)
                                }
                            }
                        }
                        select(Regex(".*")) {
                            val consumer = aggregatorContext.consumerById(match)
                            switch {
                                if (aggregatorContext.isAdmin()) {
                                    select("edit") {
                                        serviceContentView({}) {
                                            consumerEditor(aggregatorContext, consumer)
                                        }
                                    }
                                }
                                default {
                                    consumers(aggregatorContext, consumer?.id)
                                }
                            }
                        }
                    }
                }

                select("backfill") {
                    switch {
                        select("new") {
                            serviceContentView {
                                startBackfill(aggregatorContext,
                                    parameter("target"),
                                    parameter("role"),
                                    parameter("types")?.split(",")?.mapNotNull { type ->
                                        aggregatorContext.entityTypes.find { it.type == type }
                                    })
                            }
                        }

                        select("recent") {
                            backfills(aggregatorContext)
                        }

                        select(Regex(".+")) {
                            backfill(aggregatorContext, match)
                        }

                        default {
                            backfills(aggregatorContext)
                        }
                    }
                }

                select("search") {
                    entities(
                        aggregatorContext, this.parameter("query"),
                        this.parameter("type")
                    )
                }

                select("batch") {
                    batchJob(aggregatorContext)
                }

                select("features") {
                    serviceContentView {
                        featureFlags(aggregatorContext, Application.viewer.aggregatorAdmin)
                    }
                }

                default {
                    entities(aggregatorContext)
                }
            }
        }
    }
}