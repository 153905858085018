package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.ShowsRequest
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.paginator
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.components.table
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.Job
import kotlinx.coroutines.cancel
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

enum class ShowType {
    Free,
    Freemium,
    Premium
}

const val SHOWS_FETCH_SIZE = 25

val Show.showType: ShowType
    get() {
        return sources.orEmpty().let {
            if (it.find { it.bundles.isNullOrEmpty() } != null) {
                if (it.find { it.bundles?.isNotEmpty() == true } != null) {
                    ShowType.Freemium
                } else {
                    ShowType.Free
                }
            } else {
                ShowType.Premium
            }
        }
    }

private const val stitcherShowUrlPrefix = "https://www.stitcher.com/show/"
private val emailRegex = "^[\\w.-]+@([\\w-]+\\.)+[\\w-]{2,4}\$".toRegex()


/**
 * Renders the main application dashboard.
 */
@Composable
fun podcastsDashboard() {
    var searchText by remember { mutableStateOf("") }
    var searchResults by remember { mutableStateOf(emptyList<Show>()) }
    val coroutineScope = rememberCoroutineScope()
    var searchJob by mutableStateOf<Job?>(null)
    var searching by mutableStateOf(false)
    var showStats by remember { mutableStateOf<AuditShowResponse?>(null) }
    // paginated shows
    var showsResults by remember { mutableStateOf(emptyList<Show>()) }
    var currentShowsList by remember { mutableStateOf(emptyList<Show>()) }
    var showSpinner by mutableStateOf(false)
    var page by remember { mutableStateOf(0) }
    var showToken by remember { mutableStateOf<String?>(null) }
    var hasMorePages by remember { mutableStateOf(true) }
    // filter by authors
    var authorInput by mutableStateOf<String?>(null)
    var authorsList by remember { mutableStateOf(emptyList<String>()) }
    // filter by dirs
    val dirsList by remember { mutableStateOf(listOf("connected-catalog", "sxm")) }
    var dirInput by mutableStateOf<String?>(null)

    LaunchedEffect("status") {
        showStats = Podcasts.ingestionAuditAPI.auditShows("pmc")
    }

    LaunchedEffect(page) {
        if (!hasMorePages) return@LaunchedEffect // Stop if no more pages
        val available = showsResults.drop(page * SHOWS_FETCH_SIZE).take(SHOWS_FETCH_SIZE)
        if (available.isNotEmpty()) {
            currentShowsList = available
            return@LaunchedEffect
        }

        try {
            showSpinner = true
            val result = Podcasts.api.query {
                shows(ShowsRequest(limit = SHOWS_FETCH_SIZE, token = showToken)) {
                    shows {
                        showSummary()
                        configuration {
                            directories
                        }
                        authors {
                            email
                            name
                            uri
                        }
                    }
                    token
                }
            }.shows
            if (result.shows.isEmpty()) {
                hasMorePages = false
                return@LaunchedEffect
            }
            showsResults = (showsResults + result.shows)
            currentShowsList = result.shows
            showToken = result.token
            val currentAuthors = result.shows.mapNotNull { show -> show.authors?.mapNotNull { it.name } }.flatten()
            authorsList = currentAuthors.distinct()
        } catch (t: Throwable) {
            console.log("Error while calling shows...", t)
            showsResults = emptyList()
        } finally {
            showSpinner = false
        }
    }

    suspend fun search(text: String) {
        try {
            searching = true
            val showId = text.toLongOrNull()
            if (showId != null) {
                val show = Podcasts.api.query {
                    contentService {
                        show(showId.toString()) {
                            show {
                                showSummary()
                            }
                        }
                    }
                }.contentService.show.show
                searchResults = listOfNotNull(show)
            } else if (text.startsWith(stitcherShowUrlPrefix)) {
                val slug = text.removePrefix(stitcherShowUrlPrefix).substringBeforeLast("/")
                    .substringBeforeLast("?").trim()
                if (slug.isNotBlank()) {
                    Podcasts.api.query {
                        showBySlug(slug) {
                            showSummary()
                        }
                    }.showBySlug.also {
                        searchResults = listOf(it)
                    }
                }
            } else if (text.startsWith("http")) {
                val show = Podcasts.api.query {
                    feed(text) {
                        show {
                            showSummary()
                        }
                    }
                }.feed.show
                searchResults = listOfNotNull(show)
            } else if (text.startsWith("PC:")) {
                Podcasts.api.query {
                    catalogShow(text) {
                        showSummary()
                    }
                }.catalogShow.also {
                    searchResults = listOf(it)
                }
            } else if (emailRegex.matches(text)) {
                searchResults = Podcasts.api.query {
                    shows(ShowsRequest(owner = text)) {
                        shows {
                            showSummary()
                        }
                    }
                }.shows.shows
            } else {
                searchResults = Podcasts.api.query {
                    shows(ShowsRequest(query = text, includePrivate = true)) {
                        shows {
                            showSummary()
                        }
                    }
                }.shows.shows
            }
        } catch (e: CancellationException) {
            // ignored
        } catch (t: Throwable) {
            searchResults = emptyList()
        } finally {
            searching = false
        }
    }

    val hasNoShows = currentShowsList.isEmpty() && page == 0
    box({
        header({
            title = "Shows"
            if (!hasNoShows) {
                actionContent {
                    paginator {
                        pages = if (showsResults.size % SHOWS_FETCH_SIZE != 0) showsResults.size.div(SHOWS_FETCH_SIZE) + 1 else Int.MAX_VALUE
                        currentPage = page
                        onNavigate {
                            page = it
                        }
                    }
                }
            }
        }) {
            Div({
                style {
                    display(DisplayStyle.Flex)
                    gap(1.em)
                }
            }) {
                Div({
                    style {
                        flexGrow(1)
                    }
                }) {
                    Input(InputType.Text, attrs = {
                        defaultValue(searchText)
                        placeholder("Enter a show id, a show title, or a show feed URL")
                        onInput { event ->
                            searchText = event.value

                            searchJob?.cancel("Starting new search")
                            searchJob = null
                            if (event.value.length >= 4) {
                                searchJob = coroutineScope.launch {
                                    search(event.value)
                                }
                            }
                        }
                        style {
                            width(100.percent)
                        }
                    })
                }
            }
            paddedContent = showsResults.isEmpty()
        }
    }) {
        if (searching || showSpinner) {
            spinner()
        } else if (searchResults.isEmpty()) {
            if (searchText.length >= 4) {
                Text("No shows have been found")
            }
            if(currentShowsList.isNotEmpty()) {
                Div({
                    style {
                        gap(1.em)
                        marginTop(1.em)
                        marginBottom(1.em)
                        textAlign("right")
                    }
                }) {
                    Select({
                        style {
                            width(20.percent)
                            marginRight(1.em)
                        }
                        onChange {
                            dirInput = it.value?.takeIf { it != "any" }
                        }
                    }) {
                        Option("any", {
                            if (dirInput == null) selected()
                        }) {
                            Text("Filter By Dirs")
                        }
                        dirsList.forEach {
                            Option(it) {
                                Text(it)
                            }
                        }
                    }

                    Select({
                        style {
                            width(30.percent)
                            marginRight(1.em)
                        }
                        onChange {
                            authorInput = it.value?.takeIf { it != "any" }
                        }
                    }) {
                        Option("any", {
                            if (authorInput == null) selected()
                        }) {
                            Text("Filter By Author")
                        }
                        authorsList.forEach {
                            Option(it) {
                                Text(it)
                            }
                        }
                    }
                }
                table<Show> {
                    items(currentShowsList)
                    column {
                        content { show ->
                            if (
                                (authorInput == null || (authorInput!= null && authorInput == show.authors?.let { it[0].name })) &&
                                (dirInput == null || (dirInput!= null && show.configuration.directories?.contains(dirInput!!) == true))
                            ) {
                                Div({
                                    style {
                                        display(DisplayStyle.Flex)
                                    }
                                }) {
                                    Div({
                                        style {
                                            width(64.px)
                                            height(64.px)
                                            marginRight(5.px)
                                            overflow("hidden")
                                        }
                                    }) {
                                        show.images?.firstOrNull()?.sizes?.firstOrNull()?.let {
                                            Img(it) {
                                                style {
                                                    maxWidth(100.percent)
                                                }
                                            }
                                        }
                                    }
                                    Div {
                                        A(href = "/#podcasts/show/${show.id}", attrs = { attr("target", "_blank") }) {
                                            Text(show.titleOrUnknown())
                                        }
                                        Div {
                                            show.authors?.let { it[0].name?.let { it1 -> Text(it1) } }
                                        }
                                        Div({
                                            style {
                                                fontFamily("Helvetica Now", "Arial", "Helvetica", "sans-serif")
                                            }
                                        }) {
                                            show.configuration.directories?.forEach { it1 ->
                                                if(it1 == "connected-catalog") {
                                                    Text("Pandora ")
                                                }
                                                if(it1 == "sxm") {
                                                    Text("SXM ")
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } else {
            table<Show> {
                items(searchResults)

                column {
                    title = "Show Title"
                    content { show ->
                        Div({
                            style {
                                display(DisplayStyle.Flex)
                            }
                        }) {
                            Div({
                                style {
                                    width(64.px)
                                    height(64.px)
                                    marginRight(5.px)
                                    overflow("hidden")
                                }
                            }) {
                                show.images?.firstOrNull()?.sizes?.firstOrNull()?.let {
                                    Img(it) {
                                        style {
                                            maxWidth(100.percent)
                                        }
                                    }
                                }
                            }
                            Div {
                                A(href = "/#podcasts/show/${show.id}") {
                                    Text(show.titleOrUnknown())
                                }
                                Div {
                                    Text(show.showType.name)
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    box({
        header({
            title = "Stats"
        })
    }) {
        if(showStats == null) {
            spinner()
        } else {
            Span {
                Text("Total shows: ${showStats?.totalShows}")
            }
            Span({
                style {
                    marginLeft(1.em)
                }
            }) {
                Text("Stitcher Only shows:${showStats?.stitcherOnlyShows}")
            }
            Span({
                style {
                    marginLeft(1.em)
                }
            }) {
                Text("Active shows:${showStats?.activeShows}")
            }
            Span({
                style {
                    marginLeft(1.em)
                }
            }) {
                Text("Inactive shows:${showStats?.inactiveShows}")
            }
        }
    }
}
