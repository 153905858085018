package com.siriusxm.pia.views.podcasts

import kotlinx.coroutines.delay

/**
 * Wait for a show's source to update. As long as the feed remains in the
 * list of show sources as provided, this will loop (up to a max count).
 */
suspend fun waitForShowSourceUpdate(showId: String, url: String, adding: Boolean): Boolean {
    var count = 0
    while (true) {
        count++

        if (count > 30) {
            return false
        }

        try {
            val sources = Podcasts.api.query {
                show(showId) {
                    sources {
                        this.url
                    }
                }
            }.show.sources?.mapNotNull {
                it.url
            }.orEmpty()

            if (adding && sources.contains(url)) {
                return true
            } else if (!adding && !sources.contains(url)) {
                return true
            }
        } catch (t: Throwable) {
            // ignored for now, we'll continue to retry since the show might not exist yet.
        }
        delay(500)
    }
}