package com.siriusxm.pia.views.unifiedaggregator.backfill

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.BackfillStatus
import com.siriusxm.pia.rest.unifiedaggregator.TypeProgress
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import io.ktor.client.plugins.*
import kotlinx.coroutines.delay
import kotlinx.coroutines.isActive
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.seconds


fun Number.toLocaleString(): String {
    return this.asDynamic()?.toLocaleString()
}

fun BackfillStatus.isRunning(): Boolean {
    return status == "ACTIVE" || status == "CREATING"
}

/**
 * Render the backfill page.
 */
@Composable
fun backfill(aggregatorService: AggregatorService, id: String, backfillStatus: BackfillStatus? = null) {
    var status by remember { mutableStateOf(backfillStatus) }
    var error by remember { mutableStateOf<ClientRequestException?>(null) }
    var targetName by remember { mutableStateOf<String?>(null) }
    var jobId by remember { mutableStateOf<String?>(null) }

    val baseUrl = Application.configuration.datadogLookupByJobId

    LaunchedEffect(id) {
        if (status == null) {
            try {
                status = aggregatorService.api.backfill(id)
                targetName = status?.targetArn?.substringAfterLast("/")
                jobId = status?.jobId
            } catch (e: ClientRequestException) {
                error = e
            }
        }

        // Periodically fetch the status of the job
        while (isActive && status?.isRunning() == true) {
            status = aggregatorService.api.backfill(id)
            delay(1.seconds)
        }
    }

    suspend fun cancel() {
        try {
            aggregatorService.api.cancelBackfill(id)
        } catch (t: Throwable) {
            t.printStackTrace()
            Application.notifications.showError("Unable to cancel", t.message)
        }
    }

    serviceContentView({
        title = "Backfill"
        subTitle = targetName
        breadcrumbs {
            crumb("Backfills", "aggregator/backfill") {
                crumb(id, null)
            }
        }

        if (aggregatorService.isAdmin() && status != null) {
            if (status?.status == "ACTIVE") {
                action("Cancel") {
                    cancel()
                }
            } else {
                action("Run Again") {
                    val role = status?.roleArn?.let { encodeURIComponent(it) }
                    val target = status?.targetArn?.let { encodeURIComponent(it) }
                    val types = status?.typeProgress?.keys?.mapNotNull { type ->
                        aggregatorService.entityTypes.find { it.type == type }
                    }?.joinToString(",") { it.type }?.let { encodeURIComponent(it) }.orEmpty()
                    if (target != null && types.isNotBlank())
                        aggregatorService.navigate("backfill/new?target=$target${role?.let { "&role=$it" } ?: ""}&types=${types}")
                }
            }
        }
    }) {
        if (status == null) {
            spinner(size = Size.LARGE)
        } else {
            box {
                var resolvedStatus = status?.status ?: "STARTING"
                if (status?.status == "ACTIVE") {
                    val allComplete = status?.typeProgress?.values.orEmpty().find { !it.isComplete } == null
                    if (allComplete) {
                        resolvedStatus = "FINISHING"
                    }
                }

                detailGrid {
                    detail("Status", resolvedStatus)
                    detail("Entity Count", status?.count?.toLocaleString())
                    detail("Job ID", status?.jobId)
                }
                detailGrid {
                    detail("Started", status?.startTime?.toLocalDateTimeString())
                    status?.completeTime?.let {
                        detail("Completed", it.toLocalDateTimeString())
                    }
                    detail("Data Timestamp", status?.timestamp?.let {
                        Instant.parse(it)
                    }?.toLocalDateTimeString())
                }
                detailGrid {
                    detail("Target") {
                        Div {
                            Text(status?.targetArn.orEmpty())
                        }
                    }
                    detail("Role") {
                        A(href = "https://us-east-1.console.aws.amazon.com/iam/home?region=us-east-2#/roles/details/${
                            status?.roleArn?.substringAfterLast(
                                "role/"
                            )
                        }",
                            {
                                target(ATarget.Blank)
                            }) {
                            Text(status?.roleArn.orEmpty())
                        }
                    }
                    detail("Data Dog") {
                        if (jobId != null) {
                            val url = baseUrl.replace("{{JOB_ID}}", jobId!!)
                            A(href = url, {
                                target(ATarget.Blank)
                            }) {
                                Img(src= "images/dd-logo-rgb.png", "DataDog") {
                                    style {
                                        width(20.px)
                                        property("vertical-align", "middle")
                                        marginRight(3.px)
                                    }
                                }
                                Text("DataDog Link for backfill job")
                            }
                        } else {
                            Text("Job ID is unavailable")
                        }

                    }
                }
            }

            boxTable<Pair<String, TypeProgress>>("Types") {
                items(status?.typeProgress?.map {
                    it.key to it.value
                })

                column {
                    width = 15.em
                    content {
                        Text(it.first)
                    }
                }
                column {
                    width = 15.em
                    content {
                        Text(it.second.count.toLocaleString())
                    }
                }
                column {
                    content { typeStatus ->
                        if (status?.status == "ACTIVE") {
                            Div({
                                style {
                                    width(200.px)
                                    height(20.px)
                                    backgroundColor(SXMUI.borderDividerDefault.value())
                                    borderRadius(5.px)
                                    overflow("hidden")
                                }
                            }) {
                                val index = typeStatus.second.currentSegment
                                val total = typeStatus.second.totalSegments
                                val progress = if (total <= 0) 0 else {
                                    ((index.toDouble() / total.toDouble()) * 100).toInt()
                                }
                                Div({
                                    style {
                                        display(DisplayStyle.InlineBlock)
                                        height(100.percent)
                                        width(progress.percent)
                                        backgroundColor(Color.green)
                                    }
                                }) {

                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
