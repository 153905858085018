package com.siriusxm.pia.views.unifiedaggregator.management

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.getConsumer
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.aggregator.ConsumerDetails
import contentingestion.aggregator.Contact
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * The view for browsing and editing consumers.
 */
@Composable
fun consumers(aggregator: AggregatorService, selectedId: String? = null) {
    val consumers = aggregator.consumers
    val selected = consumers.find { it.id == selectedId }
    serviceContentView {
        breadcrumbs {
            crumb("Aggregator", "aggregator") {
                crumb("Consumers", "consumers") {
                    if (selected != null) {
                        crumb(selected.name ?: selected.id, null)
                    }
                }
            }
        }

        splitSelectionView<ConsumerDetails> {
            title = "Consumers"
            items = consumers
            selection = consumers.find { it.id == selectedId }
            onSelect {
                aggregator.navigate("consumers/${encodeURIComponent(it.id)}")
            }

            render { consumer ->
                consumer(aggregator, consumer)
            }

            renderListItem {
                Text(it.name ?: it.id)
            }

            if (aggregator.isAdmin() && selectedId == null) {
                this.action("New Consumer") {
                    primary = true
                    action {
                        aggregator.navigate("consumers/edit")
                    }
                }
            }
        }
    }
}

@Composable
fun consumer(aggregator: AggregatorService, consumer: ConsumerDetails) {
    var loadedConsumer by remember { mutableStateOf<ConsumerDetails?>(null) }

    suspend fun loadConsumer() {
        loadedConsumer = aggregator.api.getConsumer(consumer.id)
    }

    LaunchedEffect(consumer.id) {
        loadedConsumer = null
        loadConsumer()
    }

    entityView({
        title = consumer.name

        if (aggregator.isAdmin()) {
            action("Edit", true) {
                aggregator.navigate("consumers/${encodeURIComponent(consumer.id)}/edit")
            }
        }
    }) {
        box({
            title = "Consumer Details"
        }) {
            detailGrid {
                detail("ID", consumer.id)

                if (consumer.accounts?.isNotEmpty() == true) {
                    detail("Accounts") {
                        consumer.accounts?.forEach {
                            Div {
                                Text(it)
                            }
                        }
                    }
                }
            }
        }

        if (!consumer.types.isNullOrEmpty()) {
            box({
                title = "Consumed Types"
                paddedContent = false
            }) {

                table<String> {
                    items(consumer.types?.toList())

                    column {
                        content { type ->
                            Div {
                                Text(type)
                            }
                        }
                    }
                }
            }
        }

        if (!consumer.targets.isNullOrEmpty()) {
            box({
                title = "Targets"
                paddedContent = false
            }) {
                table<String> {
                    items(consumer.targets?.map { it.arn }?.toList())
                    column {
                        content {
                            Text(it)
                        }
                    }
                }
            }
        }

        if (!consumer.contact.isNullOrEmpty()) {
            box({
                title = "Contacts"
                paddedContent = false
            }) {
                table<Contact> {
                    items(consumer.contact?.toList())
                    column {
                        content {
                            Div {
                                A(href = "mailto:${it.email}") {
                                    Text(it.email)
                                }
                            }
                        }
                    }
                }
            }
        }

        ServiceUsersBox(aggregator, consumer.id, ServiceUsersType.CONSUMER)

        loadedConsumer?.let {
            if (aggregator.isAdmin() || !loadedConsumer?.targets.isNullOrEmpty()) {
                consumerTargetsView(aggregator, it, aggregator.isAdmin()) {
                    loadedConsumer = it
                }
            }
        }
    }
}
