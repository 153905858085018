package com.siriusxm.pia.views.epg

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.rest.unifiedaggregator.asEntities
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.iconForBoolean
import com.siriusxm.pia.views.sports.*
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.unifiedmodel.*
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * @param lineupIds if the lineup ids list is null we are still loading lineups, if it is empty the channel is not in any lineups
 */
@Composable
fun channelItem(channel: Channel, includeLink: Boolean, lineupIds: List<Int>?, onClick: (() -> Unit)? = null) {
    @Composable
    fun channelContent() {
        val notInAChannelLineup = lineupIds?.isEmpty() == true
        val listItemClass = if (notInAChannelLineup) {
            SportsStyles.listItemError
        } else {
            SportsStyles.listItem
        }
        Div({ classes(listItemClass) }) {
            Div {
                val preferredImage = channel.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
                if (preferredImage != null) {
                    Img(src = getAbsoluteUrl(preferredImage)) {
                        style {
                            width(100.px)
                            height(100.px)
                        }
                    }
                }
            }

            Div({ classes(SportsStyles.metadata) }) {
                detailGrid {
                    detail(channel.type.name) {
                        Div({ classes(SportsStyles.entityInfo) }) { Text(channel.name) }
                    }
                    detail("Id") {
                        Div({ classes(SportsStyles.entitySubInfo) }) {
                            Text(channel.id)
                        }
                    }
                    detail("Channel Number") {
                        Div({ classes(SportsStyles.entitySubInfo) }) {
                            Text(channel.channelNumber.toString())
                        }
                    }
                    val streamingId = channel.streamingId
                    if (streamingId != null) {
                        detail("Streaming Id") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(streamingId)
                            }
                        }
                    }
                    val statusStr = getStatusStr(channel)
                    if (statusStr != null) {
                        detail("Status") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                Text(statusStr)
                            }
                        }
                    }
                    val businessOnly = channel.businessOnly
                    if (businessOnly == true) {
                        detail("Business Only") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                iconForBoolean(businessOnly == true)
                            }
                        }
                    }


                    if (lineupIds?.isNotEmpty() == true) {
                        detail("Lineups") {
                            Div({ classes(SportsStyles.entitySubInfo) }) {
                                if (lineupIds.size < 10) {
                                    Text(lineupIds.joinToString(", "))
                                } else {
                                    Text("In ${lineupIds.size} lineups")
                                }
                            }
                        }
                    } else {
                        if (lineupIds != null) {
                            detail("Lineups") {
                                Div({ classes(SportsStyles.entitySubInfo) }) {
                                    Text("NOT IN ANY LINEUP")
                                }
                            }
                        } else {
                            detail("Lineups") {
                                Div({ classes(SportsStyles.entitySubInfo) }) {
                                    Text("Loading...")
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    if (includeLink) {
//        val url = "#epg/shows-by-channel/${channel.id}"
        val url = "#aggregator/entity/${channel.id}"
        A(url) {
            channelContent()
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            channelContent()
        }
    }
}

private fun getStatusStr(channel: Channel): String? {
    val accessControls = channel.accessControls?.default
    val statuses = mutableStateListOf<String>()
    if (accessControls?.visible != false) {
        statuses += "Visible"
    }
    if (accessControls?.discoverable != false) {
        statuses += "Discoverable"
    }
    if (accessControls?.recommendable != false) {
        statuses += "Recommendable"
    }
    if (statuses.isEmpty()) {
        statuses += "Active"
    }

    return statuses.joinToString(", ").ifEmpty { null }
}


@Composable
fun channelsGrid(
    entities: List<Channel>,
    includeLinks: Boolean = true,
    typeFilter: String?,
    businessOnlyFilter: Boolean?,
    hasImageFilter: Boolean?,
    categoryFilter: String?,
    channelToLineupMap: Map<String, List<Int>>?,
    lineupIdFilter: Int?,
    onClick: ((Channel) -> Unit)? = null
) {
    val channels = entities.sortedBy { it.channelNumber }

    val totalCount = channels.size

    val filteredChannels = channels.mapNotNull {
        if ((typeFilter == null || typeFilter == it.type.name)
            && (businessOnlyFilter == null || businessOnlyFilter == (it.businessOnly == true))
            && (hasImageFilter == null || hasImageFilter == (it.hasPreferredImage()))
            && (categoryFilter == null || it.categories?.contains(categoryFilter) == true)
            && (lineupIdFilter == null || channelToLineupMap?.get(it.id)?.contains(lineupIdFilter) == true)
        ) {
            it
        } else {
            null
        }
    }
    val filteredCount = filteredChannels.size

    if (filteredCount == totalCount) {
        Div {
            Text("Showing $totalCount channels")
        }

    } else {
        Div {
            Text("Showing $filteredCount of $totalCount total channels")
        }
    }

    Div {
        if (typeFilter != null) {
            Text("Type: $typeFilter")
        }
        if (businessOnlyFilter != null) {
            Text("Business: $businessOnlyFilter")
        }
        if (hasImageFilter != null) {
            Text("Has Image: $hasImageFilter")
        }
        if (lineupIdFilter != null) {
            Text("Lineup: $lineupIdFilter")
        }
    }

    Div({
        classes(SportsStyles.gridList)
    }) {
        filteredChannels.forEach { entity ->
            val lineups = if (channelToLineupMap == null) {
                null
            } else {
                channelToLineupMap[entity.id] ?: listOf()
            }
            channelItem(entity, includeLinks && onClick == null, lineups) {
                onClick?.invoke(entity)
            }
        }
    }
}

@Composable
fun allChannelsPage(epgContext: EpgService, aggregatorService: AggregatorService) {

    var channels by remember { mutableStateOf(emptyList<Channel>()) }

    var typeFilter by remember { mutableStateOf<String?>(null) }
    var businessOnlyFilter by remember { mutableStateOf<Boolean?>(null) }
    var imageFilter by remember { mutableStateOf<Boolean?>(null) }
    var categories by remember { mutableStateOf<List<Entity>?>(null) }
    var categoryFilter by remember { mutableStateOf<String?>(null) }
    var lineupFilter by remember { mutableStateOf<Int?>(null) }

    var channelToLineupMap by remember { mutableStateOf<Map<String, List<Int>>?>(null) }
    var lineupIds by remember { mutableStateOf<List<Int>?>(null) }

    LaunchedEffect("channels") {
        val linearChannels = epgContext.api.fetchAllLinearChannels().filterNot { it.id == "LC:" }.sortedBy { it.id }
        val xtraChannels = epgContext.api.fetchAllXtraChannels().filterNot { it.id == "LC:" }.sortedBy { it.id }
        channels = linearChannels + xtraChannels
        console.log("channels", channels)

        val categoriesIdSet = channels.mapNotNull {
            it.categories?.map { cat -> cat }
        }.flatten().toSet()

        categories = coroutineScope {
            categoriesIdSet.map {
                async {
                    aggregatorService.api.fetchEntityById(it).asEntities()
                }
            }.awaitAll()
        }.flatten().sortedBy { it.name }
        console.log(categories)

        val lineups = mutableListOf<Int>()
        val map = mutableMapOf<String, MutableList<Int>>()
        epgContext.api.fetchAllLineups().forEach { lineup ->
            lineup.lineupId?.let {lineupId ->
                lineups.add(lineupId)
                lineup.channels.forEach {
                    var list = map[it]
                    if (list == null) {
                        list = mutableListOf()
                        map[it] = list
                    }
                    list.add(lineupId)
                }
            }
        }
        channelToLineupMap = map
        lineupIds = lineups
    }

    serviceContentView({
        title = "Channels"
    }) {
        if (channels.isEmpty()) {
            spinner(size = Size.LARGE)
        } else {
            detailGrid {
                detail("Types") {
                    buildSelect(typeFilter, setOf(EntityType.CHANNEL_LINEAR.name, EntityType.CHANNEL_XTRA.name)) {
                        typeFilter = it
                    }
                }
                detail("Business Only") {
                    buildSelect(typeFilter, setOf("true", "false")) {
                        businessOnlyFilter = it?.toBooleanStrictOrNull()
                    }
                }
                detail("Has Image") {
                    buildSelect(typeFilter, setOf("true", "false")) {
                        imageFilter = it?.toBooleanStrictOrNull()
                    }
                }
                if (categories != null) {
                    detail("Category") {
                        val idToNameMap = mutableMapOf<String, String>()
                        val idList = mutableListOf<String>()
                        categories?.forEach {
                            val id = it.id
                            idList.add(id)
                            val name = it.name
                            if (name != null) {
                                idToNameMap[it.id] = name
                            }
                        }
                        val linkedHashSet = linkedStringSetOf()
                        linkedHashSet.addAll(idList)
                        buildSelect(categoryFilter, linkedHashSet, idToNameMap, sortSelectOptions = false) {
                            categoryFilter = it
                        }
                    }
                }

                if (lineupIds != null) {
                    detail("Lineup") {
                        val sortedLineupIds = lineupIds?.sorted()?.map { it.toString() }?.toSet()
                        buildSelect(categoryFilter, sortedLineupIds, sortSelectOptions = false) {
                            lineupFilter = it?.toIntOrNull()
                        }
                    }
                }
            }
            channelsGrid(
                channels, typeFilter = typeFilter, businessOnlyFilter = businessOnlyFilter,
                hasImageFilter = imageFilter, categoryFilter = categoryFilter, channelToLineupMap = channelToLineupMap,
                lineupIdFilter = lineupFilter
            )
        }
    }
}

/**
 * EPG Routing
 */
@Composable
fun Route.epgDashboard(epgAPI: EpgAPI, aggregatorClient: UnifiedAggregatorClient) {
    var index by remember { mutableStateOf(1) }
    val epgContext by remember {
        mutableStateOf(
            EpgService(
                api = epgAPI,
                reloader = {
                    index++
                }
            )
        )
    }
    val aggregatorContext by remember {
        mutableStateOf(
            AggregatorService(
                api = aggregatorClient,
                reloader =  {
                    index++
                }
            )
        )
    }

    switch {
        select("shows-by-channel") {
            switch {
                select(Regex(".+")) {
                    val channelId = this.match
                    showsForLinearChannel(epgContext, channelId)
                }
            }
        }

        default {
            primaryContentArea {
                allChannelsPage(epgContext, aggregatorContext)
            }
        }
    }
}

fun Channel.hasPreferredImage(): Boolean {
    return this.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default != null
}