package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.button
import com.siriusxm.pia.components.simpleCheckBox
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import contentingestion.aggregator.*
import contentingestion.unifiedmodel.EntityAccessControl
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div

private val json = Json {
    ignoreUnknownKeys = true
}

@Composable
fun entityAccessControls(aggregator: AggregatorService, entity: Entity) {
    val coroutineScope = rememberCoroutineScope()

    var loading by mutableStateOf(false)

    var savedAccessControls by remember { mutableStateOf<EntityAccessControl?>(null) }
    var updatedAccessControls by remember { mutableStateOf<EntityAccessControl?>(null) }
    var override by remember { mutableStateOf(false) }

    var saving by mutableStateOf(false)

    fun getDefaultAccessControl(): EntityAccessControl {
        val entityAccessControls: EntityAccessControl? = entity["accessControl"]?.let {
            json.decodeFromJsonElement(it)
        }

        return EntityAccessControl(
            visible = entityAccessControls?.visible ?: true,
            discoverable = entityAccessControls?.discoverable ?: true,
            recommendable = entityAccessControls?.recommendable ?: true
        )
    }

    LaunchedEffect(entity.id, entity.type, entity.version) {
        loading = true


        val accessControllableEntity =
            aggregator.api.incomingEntity(
                entity.id, entity.type,
                entity.version
            )?.partials?.find {
                it.partialId == "access-control"
            }

        savedAccessControls = accessControllableEntity?.data?.jsonObject?.get("accessControl")?.let {
            json.decodeFromJsonElement<EntityAccessControl>(it)
        }
        updatedAccessControls = savedAccessControls ?: getDefaultAccessControl()
        if (savedAccessControls != null) {
            override = true
        }
        loading = false
    }

    suspend fun saveAccessControls(accessControl: EntityAccessControl): Boolean {
        try {
            saving = true

            val viewerEmail = Application.viewer.email
            val partialUpdate = PartialUpdate(
                type = ContentUpdateType.UPDATE,
                timestamp = Clock.System.now(),
                modification = buildJsonObject {
                    put("id", entity.id)
                    put("type", entity.type)
                    put("version", entity.version)
                    put("accessControl", json.encodeToJsonElement(accessControl))
                },
                partialId = "accessControl",
                operations = listOf(
                    PartialUpdateOperation(
                        type = PartialUpdateOperationType.REPLACE,
                        sourcePath = "$.accessControl",
                        targetPath = "$.accessControl"
                    )
                ),
                auditContext = viewerEmail?.let {
                    AuditContext(viewerEmail)
                }
            )
            aggregator.api.partialUpdate(
                partialUpdate
            )
            savedAccessControls = accessControl
            updatedAccessControls = accessControl
            override = true
            Application.notifications.info("Access controls saved")
            return true
        } catch (t: Throwable) {
            Application.notifications.showError(
                "When save was attempted, an error was returned.",
                t.message
            )
        } finally {
            saving = false
        }
        return false
    }

    Div({
        style {
            backgroundColor(SXMUI.containerContentBackgroundColor.value())
            padding(1.em)
        }
    }) {
        if (loading) {
            spinner()
        } else {
            simpleCheckBox(
                "Override access controls?", override
            ) { selected ->
                override = selected
            }

            Div({
                style {
                    marginLeft(2.em)
                    marginTop(5.px)
                }
            }) {
                simpleCheckBox(
                    "Visible", updatedAccessControls?.visible ?: false,
                    enabled = override
                ) { selected ->
                    updatedAccessControls = updatedAccessControls!!.copy(visible = selected)
                }

                simpleCheckBox(
                    "Discoverable", updatedAccessControls?.discoverable ?: false,
                    enabled = override
                ) { selected ->
                    updatedAccessControls = updatedAccessControls!!.copy(discoverable = selected)
                }

                simpleCheckBox(
                    "Recommendable", updatedAccessControls?.recommendable ?: false,
                    enabled = override
                ) { selected ->
                    updatedAccessControls = updatedAccessControls!!.copy(recommendable = selected)
                }
            }

            if (override && updatedAccessControls != null) {
                Div({
                    style {
                        margin(1.em, 0.px)
                    }
                }) {
                    button {
                        primary = true
                        title = "Save"
                        showProgressOnAction = true
                        enabled = !saving
                        action {
                            updatedAccessControls?.let { controls ->
                                coroutineScope.launch {
                                    saveAccessControls(controls)
                                }
                            }
                        }
                    }
                    button("Save") {
                        if (!saving) {
                            updatedAccessControls?.let { controls ->
                                coroutineScope.launch {
                                    saveAccessControls(controls)
                                }
                            }
                        }
                    }
                    button("Reset") {
                        if (!saving) {
                            updatedAccessControls = savedAccessControls ?: getDefaultAccessControl()
                            override = savedAccessControls != null
                        }
                    }
                }
            }
        }
    }
}


