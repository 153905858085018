package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.utils.toLocalDayVerbose
import com.siriusxm.pia.views.channelguide.aggregatorIconLink
import com.siriusxm.unifiedcontent.sports.CompetitionParticipant
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.aggregator.*
import contentingestion.unifiedmodel.League
import contentingestion.unifiedmodel.Team
import contentingestion.unifiedmodel.TeamColors
import kotlinx.datetime.Clock
import kotlinx.serialization.json.Json
import kotlinx.serialization.json.buildJsonObject
import kotlinx.serialization.json.encodeToJsonElement
import kotlinx.serialization.json.put
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days

@Composable
fun teamById(app: SportsApplication, participantId: String) {
    var team by remember { mutableStateOf<CompetitionParticipant?>(null) }

//    var saving by remember { mutableStateOf(false) }
//    var fetchedColorList by remember { mutableStateOf<List<ColorizableField>?>(null) }
    val coroutineScope = rememberCoroutineScope()

    var events by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }
    var selectedDay by remember { mutableStateOf<String?>(null) }

    var previousEvents by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }
    var previousSelectedDay by remember { mutableStateOf<String?>(null) }

    var loading by remember { mutableStateOf(false) }

//    suspend fun saveColors(colors: List<ColorizableField>): Boolean {
//        try {
//            saving = true
//
//           val partialUpdate = team?.getColorPartialUpdate(colors)
//            if (partialUpdate != null) {
//                aggregatorContext.api.partialUpdate(
//                    partialUpdate
//                )
//
//                team = team?.copy(
//                    colors = toTeamColors(colors)
//                )
//                fetchedColorList = colors
//
//                Application.notifications.info("Colors saved")
//            }
//            return true
//        } catch (t: Throwable) {
//            Application.notifications.showError(
//                "When save was attempted, an error was returned.",
//                t.message
//            )
//        } finally {
//            saving = false
//        }
//        return false
//    }


    LaunchedEffect(participantId) {
        loading = true
        try {
            team = app.client.participant(participantId)
            events = app.client.participantCompetitions(
                participantId,
                Clock.System.now().minus(1.days),
                Clock.System.now().plus(14.days)
                ).entities.filterNot { it.completed() }

            previousEvents = app.client.participantCompetitions(
                participantId,
                Clock.System.now().minus(30.days),
                Clock.System.now()
            ).entities.filter {  it.completed()  }
        } finally {
            loading = false
        }
    }

    val t = team

    if (t == null || loading) {
        spinner(size = Size.LARGE)
    } else {
        serviceContentView({
            title = team?.let { getTeamName(it) }

            val league = app.leagues.value?.find { it.id == team?.league }
            breadcrumbs {
                crumb("Sports", "sports")
                if (league != null) {
                    crumb(league.name, "sports/league/${league.id}")
                }
                crumb(team?.name ?: participantId, null)
            }

            actionArea {
                aggregatorIconLink(t.id, IconSize.NORMAL, false)
            }
        }) {
            tabView {
                if (!events.isNullOrEmpty()) {
                    tab("Upcoming Events ${events?.size?.let { "($it)"} ?: "" }") {
                        val upcomingEventsByDate = events!!.filter { it.start != null }.groupBy { it.start!!.toLocalDateTime().date }
                        val optionTextMap = upcomingEventsByDate.map { it.key.toString() to "${it.key.toLocalDayVerbose()} (${it.value.size})" }.toMap()

                        val upcomingEventsByDay = upcomingEventsByDate.map { it.key.toString() to it.value }.toMap()
                        eventsGridAdvanced(app, events!!, upcomingEventsByDay, selectedDay, optionTextMap,
                            sortFn = { events -> events.sortedBy { it.start } },
                            keySortFn = {keys -> keys.sorted().toSet() }) {
                            selectedDay = it
                        }
                    }
                }
                if (!previousEvents.isNullOrEmpty()) {
                    tab("Previous Events ${previousEvents?.size?.let { "($it)"} ?: "" }") {
                        val previousEventsByDate = previousEvents!!.filter { it.start != null }.groupBy { it.start!!.toLocalDateTime().date }
                        val optionTextMap = previousEventsByDate.map { it.key.toString() to "${it.key.toLocalDayVerbose()} (${it.value.size})" }.toMap()
                        val previousEventsByDay = previousEventsByDate.map { it.key.toString() to it.value }.toMap()

                        eventsGridAdvanced(
                            app, previousEvents!!, previousEventsByDay, previousSelectedDay, optionTextMap,
                            sortFn = { events -> events.sortedByDescending { it.start } },
                            keySortFn = {keys -> keys.sortedDescending().toSet() }) {
                            previousSelectedDay = it
                        }
                    }
                }
                tab("Images") {
                    imagesForParticipant(app, t)
                }
            }

//            // show the colors for the team
//            val colorList = t.getColorList()
//            if (colorList != null) {
//                colorListViewer(colorList, Application.viewer.contentEditor) {
//                    coroutineScope.launch {
//                        saveColors(it)
//                    }
//                }
//            }
        }
    }
}

@Composable
private fun imagesForParticipant(app: SportsApplication, participant: CompetitionParticipant) {
    val images = getDefaultTeamImages(participant)

    images.forEach {
        Div({ classes(SportsStyles.imageBox) }) {
            Div({ classes(SportsStyles.imageContainer) }) {
                Img(getAbsoluteUrl(app.context, it.image))
            }
            Div({ classes(SportsStyles.imageLabel) }) {
                Text("${it.purpose} ${it.aspectRatio.name.removePrefix("aspect_")}")
            }
        }
    }
}

//private fun Team?.getAbbreviation(): String {
//    return this?.additionalNames?.get("abbrevation")?.name ?: ""
//}
//
//private fun Team?.shortName(): String? {
//    return this?.additionalNames?.get(NameType.SHORT.name)?.name
//}
//
//private fun Team?.longName(): String? {
//    return this?.additionalNames?.get(NameType.LONG.name)?.name
//}

fun Team.getColorList(): List<ColorizableField>? {
    val teamColors = this.colors
    val fields = mutableListOf<ColorizableField>()
    fields.addAll(
        listOf(
            ColorizableField("primary", teamColors?.primary?.hex, teamColors?.primary?.opacity),
            ColorizableField("secondary", teamColors?.secondary?.hex, teamColors?.secondary?.opacity),
            ColorizableField("background", teamColors?.background?.hex, teamColors?.background?.opacity),
        )
    )

    // this will never be null as we have defined it, but the logic is here so that if something changes, we
    // produce `an empty list as `null`
    return if (fields.isEmpty()) {
        null
    } else {
        fields
    }
}

/**
 * This should return null if no partial update is required (ie the data is unchanged).
 *
 * If the partial is to be removed, that would be its own partial update flavor
 */
fun Team.getColorPartialUpdate(app: SportsApplication, newColors: List<ColorizableField>?): PartialUpdate? {
    val team = this

    val existingColors = team.getColorList() ?: listOf()

    val existingColorsKeyed = existingColors.associateBy { it.field }
    val newColorsKeyed = newColors?.associateBy { it.field } ?: mapOf()

    val resultingMap = existingColorsKeyed.toMutableMap()
    newColorsKeyed.forEach { (k, v) ->
        resultingMap[k] = v
    }

    var hasChanges = false
    resultingMap.forEach {
        val prevValue = existingColorsKeyed[it.key]
        val currValue = it.value

        if (currValue != prevValue) {
            hasChanges = true
        }
    }

    if (!hasChanges) {
        return null
    }

    val colors = TeamColors(
        primary = resultingMap["primary"]?.toColor(),
        secondary = resultingMap["secondary"]?.toColor(),
        background = resultingMap["background"]?.toColor(),
        // TODO: implement accent colors
    )

    val viewerEmail = app.context.viewer.email
    val partialUpdate = PartialUpdate(
        type = ContentUpdateType.UPDATE,
        timestamp = Clock.System.now(),
        modification = buildJsonObject {
            put("id", team.id)
            put("type", team.type.name)
            put("version", team.version)
            put("colors", Json.encodeToJsonElement(colors))
        },
        partialId = "cmc-team-colors",
        operations = listOf(
            PartialUpdateOperation(
                type = PartialUpdateOperationType.REPLACE,
                sourcePath = "$.colors",
                targetPath = "$.colors"
            )
        ),
        auditContext = viewerEmail?.let {
            AuditContext(viewerEmail)
        }
    )
    return partialUpdate
}

fun toTeamColors(colors: List<ColorizableField>?) : TeamColors? {
    val resultingMap = colors?.associateBy { it.field } ?: mapOf()

    if (resultingMap.isEmpty()) return null

    return TeamColors(
        primary = resultingMap["primary"]?.toColor(),
        secondary = resultingMap["secondary"]?.toColor(),
        background = resultingMap["background"]?.toColor(),
    )
}
