package com.siriusxm.pia.components

import androidx.compose.runtime.*
import kotlinx.browser.document
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Canvas
import org.w3c.dom.CanvasRenderingContext2D
import org.w3c.dom.HTMLCanvasElement
import kotlin.random.Random

@Composable
fun Chart(datasets: List<Dataset>, height: CSSSizeValue<*> = 300.px) {
    val chartUuid: Int by remember { mutableStateOf(Random.nextInt()) }

    DisposableEffect(datasets) {
        Chart.register(
            ChartComponents.LineController,
            ChartComponents.LineElement,
            ChartComponents.PointElement,
            ChartComponents.TimeScale,
            ChartComponents.LinearScale,
            ChartComponents.Legend,
            ChartComponents.Tooltip,
        )

        val canvas = document.getElementById("chart-${chartUuid}") as? HTMLCanvasElement
        val ctx = canvas?.getContext("2d") as? CanvasRenderingContext2D

        val config = new<ChartConfiguration> {
            type = "bar"
            data = new {
                this.datasets = datasets.toTypedArray()
            }
            options = new {
                responsive = true
                maintainAspectRatio = false
                devicePixelRatio = 4
                plugins = new {
                    legend = new {
                        display = true
                    }
                }
                scales = new {
                    x = new {
                        stacked = true
                    }
                    y = new {
                        stacked = true
                    }
                }
            }
        }

        val chart = Chart.Chart(ctx, config)
        onDispose {
            chart.destroy()
        }
    }

    Canvas(attrs = {
        id("chart-${chartUuid}")
        style {
            width(100.percent)
            maxWidth(100.percent)
            height(height)
        }
    })
}