// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.mddb

import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress

@OptIn(InternalKtorfitApi::class)
public class _MddbClientImpl : MddbClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun status(): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "status")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun statusAuthenticated(): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "status_authenticated")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun triggerPolling(entityType: String): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "polling/${"$entityType".encodeURLPath()}/trigger")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun fetchEntityById(typedEntityId: String): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entity/${"$typedEntityId".encodeURLPath()}")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun fetchRawEntityById(typedEntityId: String): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "entity/${"$typedEntityId".encodeURLPath()}/raw")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun refreshEntityById(typedEntityId: String) {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "entity/${"$typedEntityId".encodeURLPath()}/refresh")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Unit",
    typeInfo = typeInfo<kotlin.Unit>())

    return _converter.suspendRequest<kotlin.Unit, kotlin.Unit>(_typeData,_ext)!!
  }

  override suspend fun pollingJobStatus(): JobStatusResponse? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "polling/status")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.mddb.JobStatusResponse?",
    typeInfo = typeInfo<com.siriusxm.pia.rest.mddb.JobStatusResponse?>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.mddb.JobStatusResponse?,
        com.siriusxm.pia.rest.mddb.JobStatusResponse?>(_typeData,_ext)
  }

  override suspend fun enablePolling(entityType: String): JobInfo? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "polling/${"$entityType".encodeURLPath()}/enable")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.mddb.JobInfo?",
    typeInfo = typeInfo<com.siriusxm.pia.rest.mddb.JobInfo?>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.mddb.JobInfo?,
        com.siriusxm.pia.rest.mddb.JobInfo?>(_typeData,_ext)
  }

  override suspend fun disablePolling(entityType: String): JobInfo? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "polling/${"$entityType".encodeURLPath()}/disable")
        }
        headers{
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.mddb.JobInfo?",
    typeInfo = typeInfo<com.siriusxm.pia.rest.mddb.JobInfo?>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.mddb.JobInfo?,
        com.siriusxm.pia.rest.mddb.JobInfo?>(_typeData,_ext)
  }
}

public fun Ktorfit.createMddbClient(): MddbClient = this.create(_MddbClientImpl().apply {
    _converter= KtorfitConverterHelper(this@createMddbClient) })
