package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.FormatParams
import com.siriusxm.pia.rest.unifiedaggregator.ImageRenderingHelper
import com.siriusxm.pia.rest.unifiedaggregator.ResizeParams
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.utils.toLocalDayString
import com.siriusxm.unifiedcontent.sports.CompetitionLeague
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.datetime.*
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours

/**
 * The main sports dashboard page.
 */

@Composable
fun sportsDashboardPage(app: SportsApplication) {
    val leagues by app.leagues.collectAsState()

    var eventsByDayByLeague by remember { mutableStateOf<Map<LocalDate, Map<String, List<CompetitiveEvent>>>?>(null) }
    var eventsByDay by remember { mutableStateOf<Map<LocalDate, List<CompetitiveEvent>>?>(null) }

    LaunchedEffect("leagues") {
        val byDayByLeague = mutableMapOf<LocalDate, MutableMap<String, List<CompetitiveEvent>>>()
        val byDayAllEvents = mutableMapOf<LocalDate, MutableList<CompetitiveEvent>>()

        //TODO: once we have a way to fetch events across leagues on the server, we can remove this
        leagues?.map {
            async {
                val eventsByDate = app.client.getLeagueCompetitions(
                    it.id,
                    Clock.System.now().minus(1.days), Clock.System.now().plus(7.days)
                ).entities.groupBy {
                    it.start?.toLocalDateTime()?.date
                }
                if (eventsByDate.isNotEmpty()) {
                    eventsByDate.entries.forEach { (date, events) ->
                        var leagueEvents = byDayByLeague[date]
                        if (leagueEvents == null) {
                            leagueEvents = mutableMapOf()
                            byDayByLeague[date!!] = leagueEvents
                        }
                        leagueEvents[it.name] = events

                        var dayEvents = byDayAllEvents[date]
                        if (dayEvents == null) {
                            dayEvents = mutableListOf()
                            byDayAllEvents[date!!] = dayEvents
                        }
                        dayEvents.addAll(events)
                    }
                }
            }
        }?.awaitAll()
        eventsByDayByLeague = byDayByLeague
        eventsByDay = byDayAllEvents
    }

    serviceContentView({
        title = "Sports"
    }) {
        if (leagues == null) {
            spinner(size = Size.LARGE)
        } else {
            tabView {
                tab("Leagues") {
                    leaguesGrid(app, leagues!!)
                }

                val today = Clock.System.todayIn(TimeZone.currentSystemDefault())
                repeat(7) {
                    val date = today.plus(DatePeriod(days = it))
                    val tabName = if (date != today) {
                        date.toLocalDayString()
                    } else {
                        "Today"
                    }
                    tab(tabName) {
                        allSportsDayView(app, date)
                    }
                }

//                eventsByDayByLeague?.entries?.sortedBy { it.key }?.forEach { (date, eventsByLeague) ->
//                    val allDaysEvents = eventsByDay?.let { it[date] }
//                    val tabName = if (date != today) {
//                        date.toLocalDayString()
//                    } else {
//                        "Today"
//                    }
//                    if (date >= today) {
//                        tab(tabName) {
//                            val optionTextMap = eventsByLeague.map { it.key to "${it.key} (${it.value.size})" }.toMap()
//                            eventsGridAdvanced(
//                                app, allDaysEvents!!, eventsByLeague, selectedLeague,
//                                optionTextMap,  keySortFn = null,
//                                sortFn =
//                                { events: List<CompetitiveEvent> ->
//                                    val sorted = mutableListOf<CompetitiveEvent>()
//                                    sorted.addAll(events.filter { it.liveAndUpcoming() })
//                                    sorted.addAll(events.filter { it.completed() })
//                                    sorted.addAll(events.filter { it.future() })
//                                    sorted
//                                }) {
//                                selectedLeague = it
//                            }
//                        }
//                    }
//                }
            }
        }
    }
}

fun <K, V> Map<K?, V>.filterNullKeys(): Map<K, V> {
    return filterKeys { it != null }  // keep only entries with non-null keys
        .mapKeys { it.key!! }       // safely cast the key to non-null
}

@Composable
fun allSportsDayView(app: SportsApplication, date: LocalDate) {
    var eventsByLeague by remember { mutableStateOf<Map<String, List<CompetitiveEvent>>?>(null) }
    var selectedLeague by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(date) {
        eventsByLeague = null
        val startOfDay = date.atStartOfDayIn(TimeZone.currentSystemDefault())
        val endOfDay = startOfDay.plus(24.hours)

        eventsByLeague = app.client.getCompetitions(startOfDay, endOfDay).entities.groupBy {
            it.league?.name
        }.filterNullKeys()
    }

    waitUntilNonNull(eventsByLeague) { eventsMap ->
        val optionTextMap = eventsMap.map { it.key to it.key }.toMap()
        eventsGridAdvanced(
            app, eventsMap.values.flatten(), eventsMap, selectedLeague,
            optionTextMap, keySortFn = null,
            sortFn =
                { events: List<CompetitiveEvent> ->
                    val sorted = mutableListOf<CompetitiveEvent>()
                    sorted.addAll(events.filter { it.liveAndUpcoming() })
                    sorted.addAll(events.filter { it.completed() })
                    sorted.addAll(events.filter { it.future() })
                    sorted
                }) {
            selectedLeague = it
        }
    }
}

@Composable
fun leagueItem(app: SportsApplication, league: CompetitionLeague, includeLink: Boolean, onClick: (() -> Unit)? = null) {
    @Composable
    fun leagueContent() {
        Div({
            classes(SportsStyles.teamCard)
            onClick {
                onClick?.invoke()
            }
        }) {
            Div {
                Div({
                    classes(SportsStyles.entityImage)
                }) {
                    val preferredImage =
                        league.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
                    if (preferredImage != null) {
                        Img(
                            src = getAbsoluteUrl(
                                app.context, preferredImage,
                                FormatParams("png"), ResizeParams(100, 100)
                            )
                        )
                    }
                }
            }

            tooltip({
                Text("League ID: ${league.id}")
            }) {
                H3 {
                    Text(league.name)
                }
            }
        }
    }

    if (includeLink) {
        A("#sports/league/${league.id}") {
            leagueContent()
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            leagueContent()
        }
    }
}

@Composable
fun leaguesGrid(
    app: SportsApplication,
    entities: List<CompetitionLeague>,
    includeLinks: Boolean = true,
    onClick: ((CompetitionLeague) -> Unit)? = null
) {
    Div({
        classes(SportsStyles.gridList)
    }) {
        entities.forEach { entity ->
            leagueItem(app, entity, includeLinks && onClick == null) {
                onClick?.invoke(entity)
            }
        }
    }
}

// TODO: make this not a duplicate code
fun getAbsoluteUrl(
    context: ApplicationContext, preferredImage: Image,
    formatParams: FormatParams = FormatParams("png"),
    resizeParams: ResizeParams = ResizeParams(600, 600)
): String {
    return ImageRenderingHelper.getImageUrl(
        context.configuration.atlasImageServerBaseUrl,
        preferredImage.url,
        resizeParams,
        formatParams
    )
}

/**
 * Sports Routing
 */
@Composable
fun Route.sportsDashboard(app: SportsApplication) {
    switch {
        select("league") {
            switch {
                select(Regex(".+")) {
                    val leagueId = this.match
                    leagueView(app, leagueId)
                }
            }
        }

        select("team") {
            switch {
                select(Regex(".+")) {
                    val teamId = this.match
                    teamById(app, teamId)
                }
            }
        }

        select("event") {
            switch {
                select(Regex(".+")) {
                    val eventId = this.match
                    val autoPolling = parameter("autoPolling")
                    val isAutoPolling = "true" == autoPolling
                    scoresForEvent(app, eventId, isAutoPolling)
                }
            }
        }

        default {
            sportsDashboardPage(app)
        }
    }
}