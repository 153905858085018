package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.asEntities
import com.siriusxm.pia.utils.*
import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*

object AggregatorStyles : StyleSheet() {
    val entityList by style {
        padding(0.px)
        margin(0.px)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
    }

    val entityListItem by style {
        display(DisplayStyle.Flex)
        gap(1.em)
        padding(1.em)
        border {
            color = SXMUI.borderDividerDefault.value()
            style(LineStyle.Solid)
        }
        borderWidth(0.px, 0.px, 1.px, 0.px)

        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
            textDecorationStyle("none")
        }
    }

    val entitySubInfo by style {
        fontSize((0.8.cssRem))
        color(SXMUI.subtleTextColor.value())
        marginBottom(3.px)
    }

    val imageBox by style {
        display(DisplayStyle.InlineBlock)
        padding(10.px)
    }

    val imageContainer by style {
        width(200.px)
        height(200.px)
        lineHeight(200.px)

        type("img") style {
            maxWidth(200.px)
            maxHeight(200.px)
            property("vertical-align", "middle")
        }
    }

    val imageLabel by style {
        padding(10.px)
        textAlign("center")
    }

    val paginationContainer by style {
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        margin(1.em, 0.px)
    }
    val republishAllContainer by style {
        display(DisplayStyle.Flex)
        justifyContent(JustifyContent.Center)
        alignItems(AlignItems.Center)
        margin(1.em, 0.px)
    }

    val additionalNames by style {
        margin(3.px, 0.px)
        lastChild {
            marginBottom(0.em)
        }
    }

    val additionalName by style {
        marginBottom(.5.em)
    }
}

@Composable
fun entities(context: AggregatorService, initSearch: String? = null, initType: String? = null, initVisible: Boolean? = null) {
    var entityInput by mutableStateOf(initSearch)
    var typeInput by mutableStateOf(initType)
    var visibleInput by remember { mutableStateOf(initVisible) }
    var submittedInput by mutableStateOf<String?>(null)
    var loadingEntities by remember { mutableStateOf(false) }
    var results by remember { mutableStateOf<List<Entity>>(emptyList()) }
    var currentOffset by remember { mutableStateOf(0) }
    val hasPreviousResults by remember { mutableStateOf(false) }
    var hasMoreResults by remember { mutableStateOf(false) }
    var totalResults by remember { mutableStateOf(0) }
    val pageSize = 10
    val coroutineScope = rememberCoroutineScope()
    var allowRepublishAll by remember { mutableStateOf(false) }

    suspend fun runSearch(offset: Int = 0) {
        submittedInput = entityInput
        loadingEntities = true
        allowRepublishAll = false
        currentOffset = offset

        try {
            if (isValidGuid(entityInput.orEmpty())) {
                val sourceId = context.api.lookupSourceId(entityInput!!).sourceId
                results = context.api.fetchEntityById(sourceId, "1").asEntities()
                totalResults = results.size
                hasMoreResults = false
            } else if (entityInput.isTypedEntityList() || entityInput.isGuidList()) {
                val idList = if (entityInput.isTypedEntityList()) {
                    entityInput.toTypedEntityList()
                } else {
                    entityInput.toGuidList()?.map {
                        context.api.lookupSourceId(it).sourceId
                    }
                }
                results = coroutineScope {
                    idList?.map {
                        async {
                            context.api.fetchEntityById(it).asEntities()
                        }
                    }?.awaitAll().orEmpty()
                }.flatten()
                totalResults = results.size
                hasMoreResults = false
                if (Application.viewer.operationsUser) {
                    allowRepublishAll = true
                }
            } else {
                val prefix = entityInput?.substringBefore(":", "").orEmpty()
                if (prefix.length in 2..4 && prefix.uppercase() == prefix) {
                    results = context.api.fetchEntityById(entityInput!!, "1").asEntities()
                    totalResults = results.size
                    hasMoreResults = false
                } else {
                    val query = entityInput?.takeIf { it.isNotBlank() }
                    val types = typeInput?.let { listOf(it) }
                    if (query != null || !types.isNullOrEmpty()) {
                        val response = context.api.searchEntities(
                            queryTerm = query,
                            types = types?.joinToString(","),
                            from = offset,
                            size = pageSize,
                            visible = visibleInput
                        )
                        results = response.entities.asEntities()
                        hasMoreResults = response.paginationToken != null
                        if (offset == 0) {
                            totalResults = if (hasMoreResults) pageSize + 1 else results.size
                        } else {
                            totalResults = maxOf(totalResults, offset + results.size)
                        }
                    }
                }
            }
        } catch (e: Exception) {
            e.printStackTrace()
        } finally {
            loadingEntities = false
        }
    }

    LaunchedEffect(initSearch, initType, initVisible) {
        runSearch()
    }

    fun submitSearch() {
        val query = entityInput?.takeIf { it.isNotBlank() }?.let { "query=$it" }
        val type = typeInput?.let { "type=$it" }
        val visible = visibleInput?.let { "visible=$it" }

        val url = "search?${listOfNotNull(query, type, visible).joinToString("&")}"
        context.navigate(url)
    }

    serviceContentView({}) {
        box({
            header({
                title = "Entities"
            }) {
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        gap(1.em)
                        marginTop(1.em)
                    }
                }) {
                    Div({
                        style {
                            flexGrow(1)
                        }
                    }) {
                        Input(InputType.Text) {
                            style {
                                width(100.percent)
                            }
                            placeholder("Search for entities by id or text")
                            value(entityInput ?: "")
                            onInput {
                                entityInput = it.value.ifBlank { null }
                                submittedInput = null
                            }
                            onKeyDown { event ->
                                if (event.key == "Enter") {
                                    submitSearch()
                                }
                            }
                        }
                    }

                    Div({
                        style {
                            flex(0, 0, 250.px)
                        }
                    }) {
                        Select({
                            style { width(100.percent) }
                            onChange {
                                typeInput = it.value?.takeIf { it != "any" }
                            }
                        }) {
                            Option("any", {
                                if (typeInput == null) selected()
                            }) {
                                Text("All Types")
                            }
                            context.entityTypes.filter { it.searchable }.sortedBy { it.name }.forEach {
                                Option(it.type, {
                                    if (it.type == typeInput) selected()
                                }) {
                                    Text(it.name)
                                }
                            }
                        }
                    }

                    Div({
                        style {
                            flex(0, 0, 150.px)
                        }
                    }) {
                        Select({
                            style { width(100.percent) }
                            onChange {
                                val newVisibility = when (it.value) {
                                    "visible" -> true
                                    "hidden" -> false
                                    else -> null
                                }
                                visibleInput = newVisibility
                            }
                        }) {
                            Option("any", {
                                if (visibleInput == null) selected()
                            }) {
                                Text("All Visibility")
                            }
                            Option("visible", {
                                if (visibleInput == true) selected()
                            }) {
                                Text("Visible")
                            }
                            Option("hidden", {
                                if (visibleInput == false) selected()
                            }) {
                                Text("Hidden")
                            }
                        }
                    }

                    Div({

                    }) {
                        Button({
                            classes(ButtonStyles.button)
                            classes(ButtonStyles.primaryButton)
                            style {
                                height(2.7.em)
                                lineHeight(1.7.em)
                            }
                            onClick {
                                submitSearch()
                                it.preventDefault()
                            }
                        }) {
                            Text("Search")
                        }
                    }
                }
            }
            paddedContent = results.isEmpty() || loadingEntities
        }) {
            if (loadingEntities) {
                spinner()
            } else if (results.isEmpty()) {
                if (entityInput.isNullOrBlank() && submittedInput != null) {
                    boxMessage("Enter an entity id or search text above")
                } else {
                    boxMessage("No results found")
                }
            } else {
                val isIdList = submittedInput?.isTypedEntityList() == true || submittedInput?.isGuidList() == true
                entityList(results, includeIds = isIdList)
                if (isIdList) {
                    if (allowRepublishAll) {
                        Div({ classes(AggregatorStyles.republishAllContainer) }) {
                            Button({
                                classes(ButtonStyles.button)
                                onClick {
                                    coroutineScope.launch {
                                        val published = results.map {
                                            async {
                                                val idMapping = context.api.lookupSourceId(it.id)
                                                try {
                                                    context.api.republish(
                                                        idMapping.atlasId,
                                                        it.type,
                                                        it.version,
                                                        updateTimestamp = true
                                                    )
                                                    it.id
                                                } catch (t: Throwable) {
                                                    Application.notifications.showError("Republish failed id: ${it.id} message: ${t.message}")
                                                    null
                                                }
                                            }
                                        }.awaitAll().filterNotNull()

                                        if (published.isNotEmpty()) {
                                            Application.notifications.info("Republished ${published.size} of ${results.size} ids: ${ published.joinToString(", ") }")
                                        }
                                    }
                                }
                            }) {
                                Text("Republish All")
                            }
                        }
                    }
                } else {
                    Div({ classes(AggregatorStyles.paginationContainer) }) {
                        if (hasPreviousResults) {
                            Button({
                                classes(ButtonStyles.button)
                                onClick {
                                    println("Debug: Previous button clicked. Current offset: $currentOffset")
                                    coroutineScope.launch {
                                        runSearch(maxOf(0, currentOffset - pageSize))
                                    }
                                }
                            }) {
                                Text("Previous")
                            }
                        }
                        if (hasMoreResults) {
                            Button({
                                classes(ButtonStyles.button)
                                onClick {
                                    println("Debug: Next button clicked. Current offset: $currentOffset")
                                    coroutineScope.launch {
                                        runSearch(currentOffset + pageSize)
                                    }
                                }
                            }) {
                                Text("Next")
                            }
                        }
                    }
                }
            }
        }
    }
}