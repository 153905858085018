package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.Composable
import androidx.compose.runtime.State
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.ConsoleApplication
import com.siriusxm.pia.EntityAugmenter
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.rest.unifiedaggregator.unifiedAggregatorClient
import com.siriusxm.pia.utils.LazyLoadedState
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.mddb.MddbAPI
import com.siriusxm.pia.views.mddb.MddbAugmenter
import com.siriusxm.smithy4kt.SmithyModel
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.coroutines.launch


/**
 * The aggregator application
 */
class AggregatorApplication : ConsoleApplication {
    lateinit var aggregatorClient: UnifiedAggregatorClient
    lateinit var context: ApplicationContext
    lateinit var augmenters: List<EntityAugmenter>

    private val _aggregatorService = LazyLoadedState {
        coroutineScope {
            val types = async {
                aggregatorClient.entityTypes().sortedBy { it.type }
            }
            val relationships = async {
                aggregatorClient.relationshipSchemas()
            }

            val producers = async {
                aggregatorClient.producers()
            }

            val consumers = async {
                aggregatorClient.consumers()
            }

            val partials = async {
                try {
                    aggregatorClient.partialSchemas()
                } catch (e: Throwable) {
                    emptyList()
                }
            }

            // perform a mock search to 'wake up' the search API.
            val searchInit = launch {
                aggregatorClient.searchEntities("love")
            }

            val incomingInit = launch {
                aggregatorClient.incomingEntity("TST:2", "test-entity", "1.0.0")
            }

            val outgoingInit = launch {
                aggregatorClient.outgoing("TST:2", "test-entity", "1.0.0")
            }

            val modelJob = async {
                aggregatorClient.entityModel()
            }

            searchInit.join()
            incomingInit.join()
            outgoingInit.join()

            AggregatorService(
                app = this@AggregatorApplication,
                api = aggregatorClient,
                entityAugmenters = augmenters,
                reloader = {
                    reload()
                },
                context = context,
                entityTypes = types.await(),
                relationships = relationships.await(),
                producers = producers.await(),
                consumers = consumers.await(),
                partials = partials.await(),
                entityModel = try {
                    SmithyModel(modelJob.await())
                } catch (t: Throwable) {
                    null
                }
            )
        }
    }

    private val userRolesState = LazyLoadedState {
        UserRoles(this).apply {
            load()
        }
    }

    /**
     * Reload producers into the application context.
     */
    suspend fun reloadProducers() {
        _aggregatorService.value()?.let {
            _aggregatorService.update(
                it.copy(
                    producers = aggregatorClient.producers()
                )
            )
        }
    }

    /**
     * Reload consumers into the application context.
     */
    suspend fun reloadConsumers() {
        _aggregatorService.value()?.let {
            _aggregatorService.update(
                it.copy(
                    consumers = aggregatorClient.consumers()
                )
            )
        }
    }

    private suspend fun reload() {
        _aggregatorService.reload()
    }

    override fun initialize(context: ApplicationContext) {
        aggregatorClient = unifiedAggregatorClient(context)
        this.context = context
        val mddbAPI = MddbAPI(context.configuration.mddbApiUrl, context)
        augmenters = listOf(
            MddbAugmenter(mddbAPI)
        )
    }

    @Composable
    fun userRoles(): State<UserRoles?> {
        return userRolesState.collect()
    }

    @Composable
    fun aggregatorContext(): State<AggregatorService?> {
        return _aggregatorService.collect()
    }

    @Composable
    override fun route(route: Route) {
        route.contentAggregator(this)
    }
}