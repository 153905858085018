package com.siriusxm.pia.views.podcasts

import com.siriusxm.pia.client.api.ImageSpecInput
import com.siriusxm.pia.client.api.client._ShowQuery

fun _ShowQuery.showSummary(imageSize: ImageSpecInput? = ImageSpecInput(64, 64, 100)) {
    id
    title
    added
    configuration {
        directories
    }
    csShow {
        csId
    }
    if (imageSize != null) {
        images {
            sizes(listOf(imageSize))
        }
    }
    sources {
        url
        bundles {
            this.id
        }
    }
}

