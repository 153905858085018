package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.detail
import com.siriusxm.pia.components.detailGrid
import com.siriusxm.pia.components.spinner
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun showConfiguration(showId: String) {
    var show by remember { mutableStateOf<Show?>(null) }
    LaunchedEffect(showId) {
        Podcasts.api.query {
            show(showId) {
                id
                podswag {
                    id
                    url
                }
                configuration {
                    active
                    directoryDetails {
                        this.showId
                        this.showUrl
                        directory {
                            directoryId
                            directoryName
                        }
                    }
                    streamOnly
                    audioBook
                }
                slugs
            }
        }.show.let {
            show = it
        }
    }

    if (show == null) {
        spinner()
        return
    }

    if (!show?.slugs.isNullOrEmpty()) {
        box("SEO") {
            detailGrid {
                detail("Primary SEO Slug", show?.slugs?.firstOrNull() ?: "-")
            }
        }
    }

    if (show?.podswag?.id != null) {
        box("Podswag") {
            detailGrid {
                show?.podswag?.let { podswag ->
                    detail("Collection Id") {
                        Div {
                            Text(podswag.id)
                        }
                    }
                    detail("URL") {
                        Div {
                            A(href = podswag.url, attrs = {
                                target(ATarget.Blank)
                            }) {
                                Text(podswag.url)
                            }
                        }
                    }
                }
            }
        }
    }

    box("Directories") {
        if (show?.configuration?.active == false) {
            Div {
                Text("NONE. This show is inactive.")
            }
        } else {
            show?.configuration?.directoryDetails?.forEach {
                Div {
                    A(href = it.showUrl, attrs = {
                        target(ATarget.Blank)
                    }) {
                        Text(it.directory.directoryName)
                    }
                }
            }
        }
    }
    box("Advanced") {
        detailGrid {
            show?.configuration?.let { showConfig ->
                detail("Stream Only") {
                    Div {
                        Text("${showConfig.streamOnly}")
                    }
                }
                detail("Audio Book") {
                    Div {
                        Text("${showConfig.audioBook}")
                    }
                }
            }
        }
    }
}