package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.Composable
import com.siriusxm.pia.Application
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.EntityAugmenter
import com.siriusxm.pia.components.multiSelect
import com.siriusxm.pia.rest.unifiedaggregator.RelationshipSchema
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.utils.Navigator
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.smithy4kt.SmithyModel
import contentingestion.aggregator.ConsumerDetails
import contentingestion.aggregator.EntityTypeConfiguration
import contentingestion.aggregator.PartialSchema
import contentingestion.aggregator.ProducerDetails
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

/**
 * Encapsulates access to the aggregator service
 */
data class AggregatorService(
    val api: UnifiedAggregatorClient,
    val entityTypes: List<EntityTypeConfiguration> = emptyList(),
    val relationships: List<RelationshipSchema> = emptyList(),
    val producers: List<ProducerDetails> = emptyList(),
    val consumers: List<ConsumerDetails> = emptyList(),
    val partials: List<PartialSchema> = emptyList(),
    val entityAugmenters: List<EntityAugmenter> = emptyList(),
    val reloader: () -> Unit,
    val context: ApplicationContext,
    val entityModel: SmithyModel? = null
) : Navigator {
    fun producerById(id: String): ProducerDetails? = producers.firstOrNull { it.id == id }
    fun consumerById(id: String): ConsumerDetails? = consumers.firstOrNull { it.id == id }
    fun entityType(type: String): EntityTypeConfiguration? = entityTypes.find { it.type == type }
    fun partial(id: String): PartialSchema? = partials.find { it.id == id }

    val globalNavigator: Navigator get() = context

    /**
     * Navigate to the path (relative to the aggregator path, so don't start with "aggregator")
     */
    override fun navigate(path: String) {
        globalNavigator.navigate("aggregator/${path.removePrefix("aggregator/")}")
    }

    override fun push(newPath: String, title: String?, render: Boolean) {
        globalNavigator.push(
            "aggregator/${newPath.removePrefix("aggregator/")}",
            title, render
        )
    }

    fun isAdmin(): Boolean {
        return Application.viewer.aggregatorAdmin
    }

    suspend fun updateProducer(details: ProducerDetails) {
        api.updateProducer(encodeURIComponent(details.id), details)
        refresh()
    }

    suspend fun newProducer(details: ProducerDetails) {
        api.newProducer(details)
        refresh()
    }

    suspend fun updateConsumer(details: ConsumerDetails) {
        api.updateConsumer(encodeURIComponent(details.id), details)
        refresh()
    }

    suspend fun newConsumer(details: ConsumerDetails) {
        api.newConsumer(details)
        refresh()
    }

    fun refresh() {
        reloader()
    }
}

/**
 * Render a multi-select for types.
 */
@Composable
fun AggregatorService.typeSelect(
    selectedTypes: Collection<EntityTypeConfiguration>,
    maxSelected: Int = Int.MAX_VALUE,
    onChange: (Collection<EntityTypeConfiguration>) -> Unit
) {
    if (maxSelected == 1) {
        Select({
            onChange { update ->
                val entityType = entityTypes.find { it.type == update.value }
                if (entityType != null) {
                    onChange(listOf(entityType))
                } else {
                    onChange(emptyList())
                }
            }
        }) {
            if (selectedTypes.isEmpty()) {
                Option("NONE", {
                    selected()
                }) {
                    Text(" ")
                }
            }
            entityTypes.forEach { type ->
                Option(type.type, {
                    if (selectedTypes.any { it.type == type.type }) {
                        selected()
                    }
                }) {
                    Text(type.type)
                }
            }
        }
    } else {
        multiSelect(entityTypes, selectedTypes, onChange) {
            Text(it.type)
        }
    }
}

@Composable
fun AggregatorService.typeSelect(
    selectedTypes: Collection<String>, maxSelected: Int = Int.MAX_VALUE,
    onChange: (Collection<EntityTypeConfiguration>) -> Unit
) {
    typeSelect(entityTypes.filter { selectedTypes.contains(it.type) }, maxSelected, onChange)
}
