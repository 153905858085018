package com.siriusxm.pia.views.unifiedaggregator.relationships

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.dialogField
import com.siriusxm.pia.components.dialogView
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.rest.unifiedaggregator.*
import com.siriusxm.pia.views.unifiedaggregator.*
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text

/**
 * A view for creating or editing relationships
 * @param schema the schema to use. If not provided, all schemas will be available
 * @param entity one side of the relationship. If not provided, both entities will be provided. A provided
 * entity will also limit the relationship schemas that are made available.
 * @param relationship the existing relationship to edit. When saved, this relationship will be removed
 * and a new one added (you can't edit a relationship).
 */
@Composable
fun relationshipEditor(
    context: AggregatorService, schema: RelationshipSchema? = null,
    entity: Entity? = null, relationship: EntityRelationship? = null,
    returnPath: String? = null
) {
    val availableSchemas: List<RelationshipSchema> = if (relationship != null) {
        context.relationships.filter { it.id == relationship.schemaId }
    } else if (schema != null) {
        listOf(schema)
    } else if (entity != null) {
        context.relationships.filter {
            it.objectType.name == entity.type || it.subjectType.name == entity.type
        }
    } else {
        context.relationships
    }.filter { it.editable }.sortedBy { it.name.lowercase() }

    var selectedSchema by remember { mutableStateOf(availableSchemas.firstOrNull()) }
    var subject by remember { mutableStateOf(relationship?.subjectEntity) }
    var `object` by remember { mutableStateOf(relationship?.objectEntity) }

    LaunchedEffect(selectedSchema) {
        if (entity != null) {
            if (entity.type == selectedSchema?.subjectType?.name) {
                subject = entity
                `object` = null
            } else if (entity.type == selectedSchema?.objectType?.name) {
                `object` = entity
                subject = null
            }
        }
    }

    dialogView(if (relationship != null) "Edit Relationship" else "Create Relationship") {
        content {
            box {
                dialogField("Relationship") {
                    if (availableSchemas.size == 1) {
                        Text(selectedSchema?.name.orEmpty())
                    } else {
                        Select({
                            onChange { update ->
                                selectedSchema = availableSchemas.find { it.id == update.value }
                            }
                        }) {
                            availableSchemas.sortedBy { it.name.lowercase() }.forEach {
                                Option(it.id, {
                                    if (it == selectedSchema) selected()
                                }) {
                                    Text(it.name)
                                }
                            }
                        }
                    }
                    selectedSchema?.description?.let {
                        Div({
                            style {
                                marginTop(1.em)
                                fontSize(0.9.em)
                            }
                        }) {
                            Text(it)
                        }
                    }
                }

                @Composable
                fun searchResults(searchStatus: SearchStatus, onSelect: (Entity?) -> Unit) {
                    Div {
                        if (searchStatus.loading) {
                            spinner()
                        } else if (searchStatus.results.isNotEmpty()) {
                            entityList(searchStatus.results) {
                                onSelect(it)
                            }
                        }
                    }
                }

                @Composable
                fun relationshipEntitySelector(type: EntityType, selection: Entity? = null, cb: (Entity?) -> Unit) {
                    var searchResults by remember { mutableStateOf<SearchStatus?>(null) }
                    if (entity != null && entity.type == type) {
                        entityListItem(entity, false)
                    } else {
                        if (selection != null) {
                            entityListItem(selection, false) {
                                cb(null)
                            }
                        } else {
                            entitySearch(context, initType = type, showTypes = false) {
                                searchResults = it
                            }

                            searchResults?.let {
                                searchResults(it) {
                                    cb(it)
                                }
                            }
                        }
                    }
                }

                if (selectedSchema != null) {
                    dialogField(selectedSchema?.objectType?.name.orEmpty()) {
                        relationshipEntitySelector(selectedSchema!!.objectType.name, `object`) {
                            `object` = it
                        }

                    }

                    dialogField(selectedSchema?.subjectType?.name.orEmpty()) {
                        relationshipEntitySelector(selectedSchema!!.subjectType.name, subject) {
                            subject = it
                        }
                    }
                }
            }
        }

        fun navReturn() {
            if (returnPath != null) {
                context.navigate(returnPath)
            } else {
                context.navigate("relationships")
            }
        }

        if (`object` != null && subject != null && schema != null) {
            action {
                title = "Submit"
                primary = true
                showProgressOnAction = true
                this.action {
                    try {
                        context.api.submitRelationship(
                            schema.id, IncomingRelationshipUpdate(
                                subject?.id!!,
                                `object`?.id!!,
                                null
                            )
                        )
                        navReturn()
                    } catch (e: Throwable) {
                        Application.notifications.showError("The relationship wasn't created: ${e.message}")
                    }
                }
            }
        }
        action("Cancel") {
            navReturn()
        }
    }
}