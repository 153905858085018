package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.rememberCoroutineScope
import androidx.compose.runtime.setValue
import com.siriusxm.pia.client.api.ShowsRequest
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.ButtonStyles
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.spinner
import com.siriusxm.pia.components.table
import com.siriusxm.pia.views.HomeStyles
import com.siriusxm.pia.views.podcasts.utils.ShowType
import com.siriusxm.pia.views.podcasts.utils.emailRegex
import com.siriusxm.pia.views.podcasts.utils.formatUtcDate
import com.siriusxm.pia.views.podcasts.utils.stitcherShowUrlPrefix
import kotlinx.coroutines.CancellationException
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.AlignItems
import org.jetbrains.compose.web.css.DisplayStyle
import org.jetbrains.compose.web.css.JustifyContent
import org.jetbrains.compose.web.css.alignItems
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.display
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.gap
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.justifyContent
import org.jetbrains.compose.web.css.lineHeight
import org.jetbrains.compose.web.css.marginLeft
import org.jetbrains.compose.web.css.marginRight
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.maxWidth
import org.jetbrains.compose.web.css.overflow
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

const val SHOWS_FETCH_SIZE = 100

val Show.showType: ShowType
    get() {
        return sources.orEmpty().let {
            if (it.find { it.bundles.isNullOrEmpty() } != null) {
                if (it.find { it.bundles?.isNotEmpty() == true } != null) {
                    ShowType.Freemium
                } else {
                    ShowType.Free
                }
            } else {
                ShowType.Premium
            }
        }
    }

/**
 * Renders the main application dashboard.
 */
@Composable
fun podcastsDashboard() {
    var searchText by remember { mutableStateOf("") }
    var searchResults by remember { mutableStateOf(emptyList<Show>()) }
    val coroutineScope = rememberCoroutineScope()
    var searching by mutableStateOf(false)
    var showStats by remember { mutableStateOf<AuditShowResponse?>(null) }
    // paginated shows
    var showsResults by remember { mutableStateOf(emptyList<Show>()) }
    var showsPages by remember { mutableStateOf(emptyList<List<Show>>()) }
    var currentShowsList by remember { mutableStateOf(emptyList<Show>()) }
    var showSpinner by mutableStateOf(false)
    var page by remember { mutableStateOf(0) }
    var currentShowsPageIndex by remember { mutableStateOf(0) }
    var showToken by remember { mutableStateOf<String?>(null) }
    var hasMorePages by remember { mutableStateOf(true) }
    // filter by authors
    var authorInput by mutableStateOf<String?>(null)
    // filter by dirs
    val dirsList = listOf(
        "pandoraonly" to "Pandora Only",
        "sxmonly" to "SXM Only",
        "pandora" to "Pandora",
        "sxm" to "SXM",
        "both" to "Both Pandora & SXM"
    )
    var platformInput by remember {mutableStateOf<String?>(null) }
    var showsByDirToken by remember { mutableStateOf<String?>(null) }
    var showsByDirsPages by remember { mutableStateOf(emptyList<List<Show>>()) }
    var hasMorePagesOfShowsByDirs by remember { mutableStateOf(false) }
    var currentDirShowsPageIndex by remember { mutableStateOf(0) }

    LaunchedEffect("status") {
        showStats = Podcasts.ingestionAuditAPI.auditShows("pmc")
    }

    suspend fun fetchShows(shouldForwardIndex: Boolean = false) {
        try {
            showSpinner = true
            val result = Podcasts.api.query {
                shows(ShowsRequest(limit = SHOWS_FETCH_SIZE, token = showToken)) {
                    shows {
                        showSummary()
                        authors {
                            email
                            name
                            uri
                        }
                    }
                    token
                }
            }.shows
            if(result.shows.isNotEmpty()) {
                showsPages = showsPages + listOf(result.shows)
                if(shouldForwardIndex == true) {
                    currentShowsPageIndex += 1
                }
                currentShowsList = if (currentShowsPageIndex in showsPages.indices) showsPages[currentShowsPageIndex] else emptyList()
            }
            showToken = result.token
            hasMorePages = showToken!= null
        } catch (t: Throwable) {
            currentShowsList = emptyList()
            hasMorePages = false
            showToken = null
        } finally {
            showSpinner = false
        }
    }

    suspend fun fetchDefaultShowsList(resetPagination: Boolean = false) {
        if (resetPagination) {
            showsResults = emptyList()
            showToken = null
            hasMorePages = false
        }
        fetchShows(false)
    }

    suspend fun showsNext() {
        if (currentShowsPageIndex+1 in showsPages.indices) {
            currentShowsList = showsPages[currentShowsPageIndex+1]
            currentShowsPageIndex += 1
            return
        }
        if(showToken == null) {
            return
        }
        fetchShows(true)
    }

    fun showsPrevious() {
        if(currentShowsPageIndex == 0) {
            return
        }
        if (currentShowsPageIndex-1 in showsPages.indices) {
            currentShowsList = showsPages[currentShowsPageIndex-1]
            currentShowsPageIndex -= 1
        }
    }

    LaunchedEffect(page) {
        if (!hasMorePages) return@LaunchedEffect // Stop if no more pages
        fetchDefaultShowsList(true)
    }

    suspend fun fetchShowsByDirectory(text: String, shouldForwardIndex: Boolean? = false) {
        try {
            searching = true
            val showsByDirectory = Podcasts.api.query {
                showsByDirectory(text, showsByDirToken, SHOWS_FETCH_SIZE) {
                    token
                    shows {
                        showSummary()
                    }
                }
            }.showsByDirectory
            if(showsByDirectory.shows.isNotEmpty()) {
                showsByDirsPages = showsByDirsPages + listOf(showsByDirectory.shows)
                if(shouldForwardIndex == true) {
                    currentDirShowsPageIndex += 1
                }
                searchResults = if (currentDirShowsPageIndex in showsByDirsPages.indices) showsByDirsPages[currentDirShowsPageIndex] else emptyList()
            }
            showsByDirToken = showsByDirectory.token
            hasMorePagesOfShowsByDirs = showsByDirToken!= null
        } catch (ignored: CancellationException) {
        } catch (t: Throwable) {
            searchResults = emptyList()
            showsByDirToken = null
            hasMorePagesOfShowsByDirs = false
        } finally {
            searching = false
        }
    }

    suspend fun searchByDirNext(text: String?) {
        if(text == null) {
            return
        }
        if (currentDirShowsPageIndex+1 in showsByDirsPages.indices) {
            searchResults = showsByDirsPages[currentDirShowsPageIndex+1]
            currentDirShowsPageIndex += 1
            return
        }
        if(showsByDirToken == null) {
            return
        }
        fetchShowsByDirectory(text, true)
    }

    fun searchByDirPrevious() {
        if(currentDirShowsPageIndex == 0) {
            return
        }
        if (currentDirShowsPageIndex-1 in showsByDirsPages.indices) {
            searchResults = showsByDirsPages[currentDirShowsPageIndex-1]
            currentDirShowsPageIndex -= 1
        }
    }

    suspend fun searchByDir(text: String?, resetPagination: Boolean = false) {
        if (text != "any" && text != null) {
            if (resetPagination) {
                searchResults = emptyList()
                showsByDirsPages = emptyList()
                showsByDirToken = null
                hasMorePagesOfShowsByDirs = false
                currentDirShowsPageIndex = 0
            }
            fetchShowsByDirectory(text, false)
        } else {
            searchResults = emptyList()
            showsByDirToken = null
            hasMorePagesOfShowsByDirs = false
        }
    }

    suspend fun search(text: String) {
        try {
            searching = true
            val showId = text.toLongOrNull()
            hasMorePagesOfShowsByDirs = false
            if (showId != null) {
                val show = Podcasts.api.query {
                    contentService {
                        show(showId.toString()) {
                            show {
                                showSummary()
                            }
                        }
                    }
                }.contentService.show.show
                searchResults = listOfNotNull(show)
            } else if (text.startsWith(stitcherShowUrlPrefix)) {
                val slug = text.removePrefix(stitcherShowUrlPrefix).substringBeforeLast("/")
                    .substringBeforeLast("?").trim()
                if (slug.isNotBlank()) {
                    Podcasts.api.query {
                        showBySlug(slug) {
                            showSummary()
                        }
                    }.showBySlug.also {
                        searchResults = listOf(it)
                    }
                }
            } else if (text.startsWith("http")) {
                val show = Podcasts.api.query {
                    feed(text) {
                        show {
                            showSummary()
                        }
                    }
                }.feed.show
                searchResults = listOfNotNull(show)
            } else if (text.startsWith("PC:")) {
                Podcasts.api.query {
                    catalogShow(text) {
                        showSummary()
                    }
                }.catalogShow.also {
                    searchResults = listOf(it)
                }
            } else if (emailRegex.matches(text)) {
                searchResults = Podcasts.api.query {
                    shows(ShowsRequest(owner = text)) {
                        shows {
                            showSummary()
                        }
                    }
                }.shows.shows
            } else {
                searchResults = Podcasts.api.query {
                    shows(ShowsRequest(query = text, includePrivate = true)) {
                        shows {
                            showSummary()
                        }
                    }
                }.shows.shows
            }
        } catch (ignored: CancellationException) {
        } catch (t: Throwable) {
            searchResults = emptyList()
        } finally {
            searching = false
        }
    }

    suspend fun submitSearch() {
        if(platformInput!= null) {
            searchByDir(platformInput, resetPagination = true)
        }else if (searchText.isNotEmpty()) {
            search(searchText)
        } else {
            fetchDefaultShowsList(true)
        }
    }

    // box for input, filter options
    box({
        header{
            Div({
                style {
                    display(DisplayStyle.Flex)
                    gap(1.em)
                    alignItems(AlignItems.Center)
                }
            }) {
                Select({
                    style {
                        width(11.percent)
                        marginRight(1.em)
                    }
                    onChange {
                        platformInput = it.value?.takeIf { it != "any" }
                        if(platformInput!= null) {
                            searchText = ""
                        }
                        coroutineScope.launch {submitSearch()}
                    }
                }) {
                    Option("any", {
                        if (platformInput == null) selected()
                    }) {
                        Text("Filter By Dirs")
                    }
                    dirsList.forEach { (key, label) ->
                        Option(key) {
                            Text(label)
                        }
                    }
                }
                Input(InputType.Text, attrs = {
                    value(searchText)
                    placeholder("Enter a show id, a show title, or a show feed URL")
                    onInput { event ->
                        searchText = event.value.ifBlank { "" }
                    }
                    onKeyDown { event ->
                        if (event.key == "Enter") {
                            platformInput = null // Reset "Filter by Dirs"
                            coroutineScope.launch {submitSearch()}
                        }
                    }
                    style {
                        width(100.percent)
                        marginRight(1.em)
                    }
                })
                Button({
                    classes(ButtonStyles.button)
                    classes(ButtonStyles.primaryButton)
                    style {
                        height(2.7.em)
                        lineHeight(1.7.em)
                    }
                    onClick {
                        coroutineScope.launch {submitSearch()}
                        it.preventDefault()
                    }
                }) {
                    Text("Search")
                }
            }
        }
    })

    // single box to render search results and default list
    box {
        if (searching || showSpinner || showStats == null) {
            spinner()
        } else if (searchText.isNotEmpty() || platformInput != null) {
            if(searchResults.isNotEmpty()) {
                table<Show> {
                    items(searchResults)
                    column {
                        content { show ->
                            Div({
                                style {
                                    display(DisplayStyle.Flex)
                                }
                            }) {
                                Div({
                                    style {
                                        width(64.px)
                                        height(64.px)
                                        marginRight(5.px)
                                        overflow("hidden")
                                    }
                                }) {
                                    show.images?.firstOrNull()?.sizes?.firstOrNull()?.let {
                                        Img(it) {
                                            style {
                                                maxWidth(100.percent)
                                            }
                                        }
                                    }
                                }
                                Div {
                                    A(href = "/#podcasts/show/${show.id}", attrs = { attr("target", "_blank") }) {
                                        Text(show.titleOrUnknown())
                                    }
                                    Div {
                                        Text(show.showType.name)
                                    }
                                    Div({ classes(HomeStyles.applicationDescription) }) {
                                        show.configuration.directories?.forEach { it1 ->
                                            if(it1 == "connected-catalog") {
                                                Text("Pandora ")
                                            }
                                            if(it1 == "sxm") {
                                                Text("SXM ")
                                            }
                                        }
                                    }
                                    Div({
                                        style {
                                            fontSize((0.8).cssRem)
                                        }
                                    }) {
                                        show.added?.let { Text(formatUtcDate(it)) }
                                    }
                                }
                            }
                        }
                    }
                }
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        justifyContent(JustifyContent.SpaceBetween)
                        width(100.percent)
                        marginTop(1.em)
                    }
                }) {
                    if(currentDirShowsPageIndex != 0 ) {
                        Button({
                            classes(ButtonStyles.button)
                            style {
                                height(2.7.em)
                                lineHeight(1.7.em)
                            }
                            onClick {
                                searchByDirPrevious()
                                it.preventDefault()
                            }
                        }) {
                            Text("Previous")
                        }
                    }
                    if(showsByDirToken!=null || (currentDirShowsPageIndex < (showsByDirsPages.size -1))) {
                        Div({
                            style {
                                display(DisplayStyle.Flex)
                                justifyContent(JustifyContent.FlexEnd)
                                width(100.percent)
                            }
                        }) {
                            Button({
                                classes(ButtonStyles.button)
                                style {
                                    height(2.7.em)
                                    lineHeight(1.7.em)
                                }
                                onClick {
                                    coroutineScope.launch {
                                        searchByDirNext(platformInput)
                                    }
                                    it.preventDefault()
                                }
                            }) {
                                Text("Next")
                            }
                        }
                    }
                }
            } else {
                Text("No shows found")
            }

        } else if (searchText.isEmpty() && platformInput == null) {
            if(currentShowsList.isNotEmpty()) {
                table<Show> {
                    items(currentShowsList)
                    column {
                        content { show ->
                            if (
                                (authorInput == null || (authorInput!= null && authorInput == show.authors?.let { it[0].name }))
                            ) {
                                Div({
                                    style {
                                        display(DisplayStyle.Flex)
                                    }
                                }) {
                                    Div({
                                        style {
                                            width(64.px)
                                            height(64.px)
                                            marginRight(5.px)
                                            overflow("hidden")
                                        }
                                    }) {
                                        show.images?.firstOrNull()?.sizes?.firstOrNull()?.let {
                                            Img(it) {
                                                style {
                                                    maxWidth(100.percent)
                                                }
                                            }
                                        }
                                    }
                                    Div {
                                        A(href = "/#podcasts/show/${show.id}", attrs = { attr("target", "_blank") }) {
                                            Text(show.titleOrUnknown())
                                        }
                                        Div {
                                            show.authors?.let { it[0].name?.let { it1 -> Text(it1) } }
                                        }
                                        Div({ classes(HomeStyles.applicationDescription) }) {
                                            show.configuration.directories?.forEach { it1 ->
                                                if(it1 == "connected-catalog") {
                                                    Text("Pandora ")
                                                }
                                                if(it1 == "sxm") {
                                                    Text("SXM ")
                                                }
                                            }
                                        }
                                        Div({
                                            style {
                                                fontSize((0.8).cssRem)
                                            }
                                        }) {
                                            show.added?.let { Text(formatUtcDate(it)) }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                Div({
                    style {
                        display(DisplayStyle.Flex)
                        justifyContent(JustifyContent.SpaceBetween)
                        width(100.percent)
                    }
                }) {
                    if(currentShowsPageIndex != 0 ) {
                        Button({
                            classes(ButtonStyles.button)
                            style {
                                height(2.7.em)
                                lineHeight(1.7.em)
                            }
                            onClick {
                                showsPrevious()
                                it.preventDefault()
                            }
                        }) {
                            Text("Previous")
                        }
                    }
                    if(showToken!=null || (currentShowsPageIndex < (showsPages.size -1))) {
                        Div({
                            style {
                                display(DisplayStyle.Flex)
                                justifyContent(JustifyContent.FlexEnd)
                                width(100.percent)
                            }
                        }) {
                            Button({
                                classes(ButtonStyles.button)
                                style {
                                    height(2.7.em)
                                    lineHeight(1.7.em)
                                }
                                onClick {
                                    coroutineScope.launch {
                                        showsNext()
                                    }
                                    it.preventDefault()
                                }
                            }) {
                                Text("Next")
                            }
                        }

                    }
                }
            } else {
                Text("No shows found")
            }
        }
    }

    // box for stats
    box({
        header({
            title = "Stats"
        })
    }) {
        if(showStats == null) {
            spinner()
        } else {
            Span {
                Text("Total shows: ${showStats?.totalShows}")
            }
            Span({
                style {
                    marginLeft(1.em)
                }
            }) {
                Text("Stitcher Only shows:${showStats?.stitcherOnlyShows}")
            }
            Span({
                style {
                    marginLeft(1.em)
                }
            }) {
                Text("Active shows:${showStats?.activeShows}")
            }
            Span({
                style {
                    marginLeft(1.em)
                }
            }) {
                Text("Inactive shows:${showStats?.inactiveShows}")
            }
        }
    }
}
