package com.siriusxm.pia.utils

import kotlinx.serialization.json.*

fun diffJsonObjects(json1: JsonObject, json2: JsonObject): JsonObject {
    val result = mutableMapOf<String, JsonElement>()

    val allKeys = json1.keys + json2.keys // Combine keys from both JSON objects
    for (key in allKeys) {
        val value1 = json1[key]
        val value2 = json2[key]

        when {
            value1 is JsonObject && value2 is JsonObject -> {
                // Recursively check nested objects
                val diff = diffJsonObjects(value1, value2)
                if (diff.isNotEmpty()) {
                    result[key] = diff
                }
            }
            value1 is JsonArray && value2 is JsonArray -> {
                // Compare arrays for differences
                if (value1 != value2) {
                    result[key] = value1 // Keep the original array from the first JSON
                }
            }
            value1 != value2 -> {
                // Add fields that differ
                result[key] = value1 ?: JsonNull // Add only non-null values
            }
        }
    }
    return JsonObject(result)
}


fun diffJsonArrays(json1: JsonArray, json2: JsonArray, mapField: String = "id") : JsonArray? {
    console.log("json1", json1)
    console.log("json2", json2)
    val keys = mutableSetOf<String>()

    val keyMap1 = mutableMapOf<String, JsonElement>()
    val keyMap2 = mutableMapOf<String, JsonElement>()

    json1.forEach {
        val key = getString(it, mapField)
        if (key != null) {
            keys.add(key)
            keyMap1[key] = it
        }
    }

    json2.forEach {
        val key = getString(it, mapField)
        if (key != null) {
            keys.add(key)
            keyMap2[key] = it
        }
    }

    val unmatched = mutableListOf<JsonElement>()
    keyMap1.forEach {
        val otherValue = keyMap2[it.key]
        if (otherValue != null && !otherValue.equals(it.value)) {
            unmatched.add(it.value)
        }
    }
    keyMap2.forEach {
        val otherValue = keyMap1[it.key]
        if (otherValue == null) {
            unmatched.add(it.value)
        }
    }

    console.log("unmatched", unmatched)

    return if (unmatched.isNotEmpty()) {
        JsonArray(unmatched)
    } else {
        null
    }
}

private fun getString(it: JsonElement, mapField: String) =
    (it as? JsonObject)?.get(mapField)?.jsonPrimitive?.contentOrNull

data class ArrayComparison(
    val comparisonItems: List<ComparisonItem>
)

data class ComparisonItem(
    val item1: JsonElement?,
    val item2: JsonElement?,
    val item1Diffs: JsonElement?,
    val item2Diffs: JsonElement?,
    val matches: Boolean
)
