package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.utils.navigator
import kotlinx.browser.document
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Button
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.Node
import org.w3c.dom.events.Event

object ButtonStyles : StyleSheet() {
    val button by style {
        position(Position.Relative)
        display(DisplayStyle.LegacyInlineFlex)
        backgroundColor(SXMUI.buttonBackgroundNormal.value())
        color(SXMUI.buttonForegroundNormal.value())
        border(0.px, LineStyle.Solid, SXMUI.buttonBorderNormal.value(Color.black))
        fontWeight("bold")
        padding((0.4).cssRem, (1.5).cssRem, (0.2).cssRem)
        cursor("pointer")
        fontSize((0.9).cssRem)

        borderRadius(3.px)
        shadowBorder()

        self + hover style {
            backgroundColor(SXMUI.buttonBackgroundNormalHover.value())
            color(SXMUI.buttonForegroundNormalHover.value())
        }

        adjacent(self, self).style {
            marginLeft(10.px)
        }
    }

    val buttonWithPopup by style {
        paddingRight(0.7.cssRem)
    }

    val primaryButton by style {
        backgroundColor(SXMUI.buttonBackgroundPrimary.value())
        color(SXMUI.buttonForegroundPrimary.value())
        border(1.px, LineStyle.Solid, SXMUI.buttonBackgroundPrimary.value(Color.black))
        self + hover style {
            backgroundColor(SXMUI.buttonBackgroundPrimaryHover.value())
            color(SXMUI.buttonForegroundPrimary.value())
            border(1.px, LineStyle.Solid, SXMUI.buttonBackgroundPrimaryHover.value(Color.black))
        }
    }
    val disabledButton by style {
        color(SXMUI.disabledTextColor.value())
        cursor("inherit")

        border(1.px, LineStyle.Solid, SXMUI.disabledTextColor.value(Color.black))

        self + hover style {
            backgroundColor(SXMUI.buttonBackgroundNormal.value())
            color(SXMUI.disabledTextColor.value())
        }
    }

    val buttonPopup by style {
        position(Position.Absolute)
        top(100.percent)
        minWidth(100.percent)
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        shadowBorder()
        marginTop(5.px)
    }

    // these are used by the names are referenced with enums
    @Suppress("unused")
    val buttonPopupRight by style {
        right(0.px)
    }

    @Suppress("unused")
    val buttonPopupLeft by style {
        left(0.px)
    }

    val buttonPopupItem by style {
        display(DisplayStyle.Block)
        backgroundColor(SXMUI.buttonBackgroundNormal.value())
        color(SXMUI.buttonForegroundNormal.value())
        textAlign("left")
        whiteSpace("nowrap")
        fontWeight(500)

        padding((0.4).cssRem, (1.5).cssRem, (0.2).cssRem)

        self + hover style {
            backgroundColor(SXMUI.buttonBackgroundNormalHover.value())
            color(SXMUI.buttonForegroundNormalHover.value())
        }
    }

    val buttonPopupDivider by style {
        backgroundColor(SXMUI.defaultDivider.value())
        width(100.percent)
        height(2.px)
        display(DisplayStyle.Block)
        margin(3.px, 0.px)
    }
}

enum class PopupAlign {
    Left,
    Right
}

object SubActionDivider

/**
 * Defines the button configuration
 */
class ButtonConfiguration(
    var title: String? = null,
    var primary: Boolean = false,
    var action: suspend () -> Unit = {}
) {
    /**
     * If true, when the action is called, an indeterminate progress icon is
     * displayed on the button that will be removed when the action handler returns.
     * Since the action block is suspended, it should not return until the operation
     * is complete.
     */
    var showProgressOnAction: Boolean = false
    var popupContent: (@Composable (scope: CoroutineScope, onDismiss: () -> Unit) -> Unit)? = null
    var popupAlign: PopupAlign = PopupAlign.Left

    var subActions = mutableListOf<Any>()

    /**
     * Renders the button as a link
     */
    var link: String? = null

    var enabled: Boolean = true

    fun action(block: suspend () -> Unit) {
        action = block
    }

    fun popupContent(block: @Composable (scope: CoroutineScope, onDismiss: () -> Unit) -> Unit) {
        this.popupContent = block
    }

    fun subAction(actionBlock: ButtonConfiguration.() -> Unit) {
        popupContent = { scope, onDismiss ->
            renderPopup(scope, onDismiss)
        }

        subActions += ButtonConfiguration().apply(actionBlock)
    }

    fun subActionDivider() {
        subActions += SubActionDivider
    }

    @Composable
    fun renderPopup(scope: CoroutineScope, onDismiss: () -> Unit) {
        var isProcessingAction by mutableStateOf(false)

        Div {
            subActions.forEach { subAction ->
                if (subAction is ButtonConfiguration) {
                    Span({
                        classes(ButtonStyles.buttonPopupItem)
                        onClick {
                            if (subAction.enabled) {
                                if (subAction.link != null) {
                                    navigator.navigate(subAction.link!!)
                                } else {
                                    scope.launch {
                                        isProcessingAction = true
                                        subAction.action()
                                        isProcessingAction = false
                                        onDismiss()
                                    }
                                }
                            }
                        }
                    }) {
                        subAction.title?.let {
                            Text(it)
                        }
                    }
                } else if (subAction == SubActionDivider) {
                    Span({
                        classes(ButtonStyles.buttonPopupDivider)
                    })
                }
            }
        }
    }
}

/**
 * Render a button with a configuration callback block.
 */
@Composable
fun button(block: ButtonConfiguration.() -> Unit) {
    val config = ButtonConfiguration().apply(block)
    button(config)
}

/**
 * Render a button with a provided button configuration.
 */
@Composable
fun button(config: ButtonConfiguration) {
    var isProcessingAction by mutableStateOf(false)
    var showPopup by mutableStateOf(false)
    val coroutineScope = rememberCoroutineScope()

    Button({

        classes(ButtonStyles.button)
        if (!config.enabled) {
            classes(ButtonStyles.disabledButton)
        } else if (config.primary) {
            classes(ButtonStyles.primaryButton)
        }
        if (config.popupContent != null) {
            classes(ButtonStyles.buttonWithPopup)
        }
        onClick {
            it.preventDefault()
            if (config.enabled && !isProcessingAction) { // don't allow multiple clicks
                if (config.popupContent != null) {
                    showPopup = !showPopup
                } else if (config.link != null) {
                    navigator.navigate(config.link!!)
                } else {
                    coroutineScope.launch {
                        isProcessingAction = true
                        config.action()
                        isProcessingAction = false
                    }
                }
            }
        }
    }) {
        Span {
            config.title?.let {
                Text(it)
            }
            config.popupContent?.let {
                icon(if (showPopup) "arrow_drop_up" else "arrow_drop_down") {
                    style {
                        lineHeight(12.px)
                    }
                }
            }
            if (config.showProgressOnAction && isProcessingAction) {
                Span({
                    style {
                        position(Position.Absolute)
                        top(1.px)
                        right(1.px)
                    }
                }) {
                    spinner(
                        style = if (config.primary) Style.LIGHT else Style.DARK,
                        size = Size.SMALL
                    )
                }
            }
        }

        if (showPopup) {
            Div({
                ref { popupElement ->
                    val listener: (Event) -> Unit = { event ->
                        val target = event.target as? Node
                        if (target != null) {
                            val isInsidePopup = popupElement.contains(target)
                            if (!isInsidePopup) {
                                event.preventDefault()
                                event.stopPropagation()
                                showPopup = false
                            }
                        }
                    }
                    // Attach the listener
                    document.addEventListener("click", listener)
                    object : DisposableEffectResult {
                        override fun dispose() {
                            document.removeEventListener("click", listener)
                        }
                    }
                }
                classes(ButtonStyles.buttonPopup)
                classes(ButtonStyles.buttonPopup + config.popupAlign.name)
            }) {
                config.popupContent?.invoke(coroutineScope) {
                    showPopup = false
                }
            }
        }
    }
}

/**
 * Simplified button rendered where a title and action is provided.
 */
@Composable
fun button(title: String, primary: Boolean = false, action: suspend () -> Unit) {
    button {
        this.title = title
        this.primary = primary
        this.action(action)
    }
}