package com.siriusxm.pia.views.unifiedaggregator.relationships

import androidx.compose.runtime.Composable
import com.siriusxm.pia.Application
import com.siriusxm.pia.UserGroup
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.RelationshipSchema
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import org.jetbrains.compose.web.css.em
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.marginBottom
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * View of all relationships schemas
 */
@Composable
fun relationships(context: AggregatorService, initialSelect: String? = null) {
    val selected = context.relationships.find { it.id == initialSelect }
    fun select(schema: RelationshipSchema) {
        context.push("aggregator/relationships/${schema.id}")
    }

    serviceContentView({
        breadcrumbs {
            crumb("Aggregator", "aggregator")
            crumb("Relationships", "aggregator/relationships".takeIf { selected != null })
            if (selected != null) {
                crumb(selected.name, null)
            }
        }
    }) {
        splitSelectionView<RelationshipSchema> {
            title = "Relationships"
            items = context.relationships
            selection = selected
            onSelect {
                select(it)
            }
            renderListItem {
                Text(it.name)
            }

            render { schema ->
                entityView({
                    title = schema.name

                    if (schema.editable && Application.viewer.isInGroup(UserGroup.AggregatorAdmin)) {
                        action("Create Relationship", true) {
                            context.navigate(
                                "relationships/edit?relationship=${
                                    encodeURIComponent(
                                        schema.id
                                    )
                                }&return=${encodeURIComponent("relationships/${schema.id}")}"
                            )
                        }
                    }

                }) {
                    box({
                        paddedContent = false
                        header {
                            Div({
                                style {
                                    fontSize(1.1.em)
                                    marginBottom(2.em)
                                }
                            }) {
                                Text(schema.description)
                            }
                            detailGrid {
                                detail("Subject", schema.subjectType.name)
                                detail("Object", schema.objectType.name)
                            }
                        }
                    }) {
                    }

                }
            }

            column {
                title = "Subject"
                content {
                    Text(it.subjectType.name)
                }
            }
            column {
                title = "Object"
                content {
                    Text(it.objectType.name)
                }
            }
            column {
                title = "Editable"
                content {
                    if (it.editable) {
                        icon("check")
                    }
                }
            }
        }
    }
}