package com.siriusxm.pia.cognito

import com.siriusxm.pia.TokenAccess
import com.soywiz.krypto.SHA256
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.*
import io.ktor.client.plugins.auth.*
import io.ktor.client.plugins.auth.providers.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.forms.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Deferred
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.async
import kotlinx.datetime.Clock
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.Instant
import kotlinx.datetime.plus
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.Json
import org.w3c.dom.get
import kotlin.io.encoding.Base64
import kotlin.io.encoding.ExperimentalEncodingApi
import kotlin.random.Random

private val tokenParser = Json {
    ignoreUnknownKeys = true
}


/**
 * Provides a browser based API for interacting with Cognito.
 */
class CognitoClient(
    private val authUrl: String,
    private val clientId: String,
    private val redirectUrl: String,
    private val cognitoPrefix: String,
    private val scopes: String = "email openid phone",
    private val pkceEnabled: Boolean
) : TokenAccess {
    override var accessToken: String? = null
    private var refreshToken: String? = null
    private var expiration: Instant = Clock.System.now()

    private val provider = CodeChallengeProvider(cognitoPrefix, CoroutineScope(Dispatchers.Default))

    private val jsonClient = HttpClient {
        install(ContentNegotiation) {
            json(Json {
                ignoreUnknownKeys = true
            })
        }
    }

    /**
     * Install an auth provider on a http client configuration.
     */
    override fun installAuth(config: HttpClientConfig<*>) {
        config.apply {
            install(Auth) {
                bearer {
                    loadTokens {
                        BearerTokens(accessToken!!, refreshToken!!)
                    }
                    refreshTokens {
                        if (refreshToken != null) {
                            refresh(refreshToken!!)
                        }
                        BearerTokens(
                            accessToken ?: throw IllegalStateException("No access token"),
                            this@CognitoClient.refreshToken ?: throw IllegalStateException("No refresh token")
                        )
                    }
                }
            }
        }
    }

    val idToken: IdToken? by lazy {
        localStorage["${cognitoPrefix}_id_token"]?.split(".")?.get(1)?.let {
            try {
                tokenParser.decodeFromString(IdToken.serializer(), window.atob(it))
            } catch (t: Throwable) {
                null
            }
        }
    }

    fun isLoggedIn(): Boolean {
        return accessToken != null && expiration > Clock.System.now()
    }

    suspend fun init() {
        val code = Url(window.location.href).parameters["code"]
        if (code != null) {
            exchangeCodeToTokens(code)

            // remove the code from the URL
            window.location.let {
                "${it.origin}${it.pathname}"
            }.let {
                window.history.replaceState(url = it, data = null, title = "")
            }
        } else {
            accessToken = localStorage["${cognitoPrefix}_access_token"]
            refreshToken = localStorage["${cognitoPrefix}_refresh_token"]
            expiration = localStorage["${cognitoPrefix}_expiration"]?.let {
                Instant.parse(it)
            } ?: Clock.System.now()

            if (accessToken != null && refreshToken != null && expiration < Clock.System.now()) {
                refresh(refreshToken!!)
            }
        }
    }

    /**
     * Redirects to the hosted login page.
     */
    suspend fun login() {
        val baseUrl = if (pkceEnabled) {
            "$authUrl/oauth2/authorize"
        } else {
            "$authUrl/login"
        }
        val url = URLBuilder(baseUrl).apply {
            this.parameters.apply {
                set("client_id", clientId)
                set("response_type", "code")
                set("scope", scopes)
                set("redirect_uri", redirectUrl)
                if (pkceEnabled) {
                    set("code_challenge", provider.getCodeChallenge())
                    set("code_challenge_method", provider.getCodeChallengeMethod())
                    set("state", "${Clock.System.now().toEpochMilliseconds()}")
                    set("redirect_uri", redirectUrl)
                } else {
                    set("redirect_uri", redirectUrl)
                }
            }
        }.buildString()

        window.location.href = url
    }

    /**
     * Clears all tokens.
     */
    fun logout() {
        localStorage.removeItem("${cognitoPrefix}_access_token")
        localStorage.removeItem("${cognitoPrefix}_id_token")
        localStorage.removeItem("${cognitoPrefix}_refresh_token")

        accessToken = null
        refreshToken = null
        expiration = Clock.System.now()

        val url = URLBuilder("$authUrl/logout").apply {
            this.parameters.apply {
                set("client_id", clientId)
                set("response_type", "code")
                set("scope", scopes)
                set("redirect_uri", redirectUrl)
            }
        }.buildString()

        window.location.href = url
    }

    private suspend fun refresh(refreshToken: String) {
        try {
            val token = jsonClient.submitForm(
                "$authUrl/oauth2/token",
                formParameters = Parameters.build {
                    append("grant_type", "refresh_token")
                    append("client_id", clientId)
                    append("redirect_uri", redirectUrl)
                    append("refresh_token", refreshToken)
                }) {
                expectSuccess = true
            }.body<Token>()

            processToken(token)
        } catch (e: ClientRequestException) {
            // if refresh fails, clear everything
            accessToken = null
            this.refreshToken = null
            expiration = Clock.System.now()
            localStorage.removeItem("${cognitoPrefix}_access_token")
            localStorage.removeItem("${cognitoPrefix}_id_token")
            localStorage.removeItem("${cognitoPrefix}_refresh_token")
            localStorage.removeItem("${cognitoPrefix}_expiration")
        }
    }

    private fun processToken(token: Token) {
        token.access_token.let { localStorage.setItem("${cognitoPrefix}_access_token", token.access_token) }
        token.id_token.let { localStorage.setItem("${cognitoPrefix}_id_token", token.id_token) }
        accessToken = token.access_token

        token.refresh_token?.let {
            localStorage.setItem("${cognitoPrefix}_refresh_token", token.refresh_token)
            refreshToken = it
        }
        expiration = Clock.System.now().plus(token.expires_in, DateTimeUnit.SECOND)
        localStorage.setItem("${cognitoPrefix}_expiration", expiration.toString())
    }

    private suspend fun exchangeCodeToTokens(code: String): String {
        val result = jsonClient.submitForm(
            "$authUrl/oauth2/token",
            formParameters = Parameters.build {
                append("grant_type", "authorization_code")
                append("client_id", clientId)
                append("redirect_uri", redirectUrl)
                append("code", code)
                if (pkceEnabled) {
                    console.log("code_verifier", provider.getCodeVerifier())
                    append("code_verifier", provider.getCodeVerifier())
                }
            }).body<Token>()

        processToken(result)

        // we are done with the verifier and can clear it
        provider.clearLocalStorage()
        return result.access_token
    }
}


@Suppress("PropertyName")
@Serializable
data class Token(
    val access_token: String,
    val refresh_token: String? = null,
    val id_token: String,
    val token_type: String,
    val expires_in: Int
)

class CodeChallengeProvider(
    private val cognitoPrefix: String,
    private val scope: CoroutineScope
) {
    private var codeChallengeDeferred: Deferred<Pair<String, String>>? = null

    private fun ensureInitialized() {
        if (codeChallengeDeferred == null) {
            codeChallengeDeferred = scope.async {
                generateCodeChallenge()
            }
        }
    }

    suspend fun getCodeVerifier(): String {
        ensureInitialized()
        return codeChallengeDeferred!!.await().first
    }

    suspend fun getCodeChallenge(): String {
        ensureInitialized()
        return codeChallengeDeferred!!.await().second
    }

    fun getCodeChallengeMethod() = "S256"

    fun clearLocalStorage() {
        localStorage.removeItem("${cognitoPrefix}_code_verifier")
    }

    @OptIn(ExperimentalEncodingApi::class)
    fun generateCodeChallenge(): Pair<String, String> {
        val codeVerifier = localStorage["${cognitoPrefix}_code_verifier"] ?: buildString(128) {
            val chars = ('a'..'z') + ('A'..'Z') + ('0'..'9')
            repeat(128) {
                append(chars[Random.nextInt(chars.size)])
            }
        }.also {
            localStorage.setItem("${cognitoPrefix}_code_verifier", it)
        }

        // Hash the code verifier using SHA-256
        val hash = SHA256.digest(codeVerifier.encodeToByteArray())

        // Convert the hash to a URL-safe Base64-encoded string
        val codeChallenge = Base64.UrlSafe.encode(hash.bytes).trimEnd('=')

        isValid(codeVerifier)

        // Return the code verifier and code challenge
        return codeVerifier to codeChallenge
    }

    private fun isValid(codeVerifier: String): Boolean {
        val regex = Regex("^[A-Za-z0-9_-]{43,128}\$")
        val regexMatches = regex.matches(codeVerifier)
        return regexMatches
    }

}