package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.attributes.multiple
import org.jetbrains.compose.web.attributes.selected
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Option
import org.jetbrains.compose.web.dom.Select
import org.jetbrains.compose.web.dom.Text
import org.w3c.dom.HTMLOptionElement

/**
 * Create a Select control that includes the "ALL" item.
 * @param optionTextMap     - (optional) but indicates when you want the display text to be different from the value
 *                            selected (ie id as the selected value and name as the displayed value)
 * @param sortSelectOptions - This indicates whether you want the options to be sorted (default, true)
 *                            or if you want them rendered in the passed-in order (false)
 */
@Composable
fun buildSelect(
    filter: String?,
    selectOptions: Set<String>?,
    optionTextMap: Map<String, String>? = null,
    sortSelectOptions: Boolean = true,
    setFilter: (filter: String?) -> Unit
) {
    Select({
        onChange {
            setFilter(it.value?.takeIf { filterValue -> filterValue != "ALL" })
        }
    }) {
        Option("ALL", {
            if (filter == null) selected()
        }) {
            Text("ALL")
        }

        val selectOptionsToRender = if (sortSelectOptions) {
            selectOptions?.sorted()
        } else {
            selectOptions
        }

        selectOptionsToRender?.forEach { optionValue ->
            Option(optionValue, {
                if (filter != null && optionValue == filter) selected()
            }) {
                val optionText = if (optionTextMap != null) {
                    optionTextMap[optionValue]
                } else {
                    null
                } ?: optionValue

                Text(optionText)
            }
        }
    }
}

/**
 * A version of a simple select that allows for a none option as the first item.
 */
@Composable
fun <T : Any> simpleSelectNullable(
    value: T?, options: List<Pair<String, T>>,
    noneLabel: String = "None",
    onUpdate: (T?) -> Unit
) {
    val noneValue = "__NONE"
    val allOptions = listOf(noneLabel to "__NONE") + options
    simpleSelect(value ?: noneValue, allOptions) {
        if (it == noneValue) {
            onUpdate(null)
        } else {
            onUpdate(it.unsafeCast<T>())
        }
    }
}

/**
 * A simple select popup.
 */
@Composable
fun <T> simpleSelect(
    value: T, options: List<Pair<String, T>>,
    onUpdate: (T) -> Unit
) {
    Select({
        onChange { update ->
            val updatedValue = options.find { it.second.hashCode().toString() == update.value }?.second
            if (updatedValue != null) {
                onUpdate(updatedValue)
            }
        }
    }) {
        options.forEach { (name, optionValue) ->
            Option(optionValue.hashCode().toString(), {
                if (optionValue == value) {
                    selected()
                }
            }) {
                Text(name)
            }
        }
    }
}

/**
 * A multi-select control
 */
@Composable
fun <T> multiSelect(
    items: List<T>,
    selectedItems: Collection<T>,
    onSelectionChange: (Collection<T>) -> Unit,
    itemRenderer: @Composable (T) -> Unit = {
        Text(it.toString())
    }
) {
    Select(
        attrs = {
            multiple()
            style {
                width(100.percent)
                height(200.px)
                padding(0.px)
            }
            onChange {
                val selectedOptions = it.target.selectedOptions
                val newSelectedItems = (0 until selectedOptions.length)
                    .map { index -> selectedOptions.item(index) as HTMLOptionElement }
                    .mapNotNull { option -> items.find { it.hashCode() == option.value.toInt() } }
                onSelectionChange(newSelectedItems)
            }
        }
    ) {
        items.forEach { item ->
            Option(
                item.hashCode().toString(),
                {
                    if (item in selectedItems) {
                        selected()
                    }
                }
            ) {
                itemRenderer(item)
            }
        }
    }
}