package com.siriusxm.pia.rest.unifiedaggregator

import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.ImageAspectRatio
import contentingestion.unifiedmodel.ImagePurpose
import contentingestion.unifiedmodel.ImageVariant
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*

/**
 * Encapsulates an aggregator entity
 */
class Entity(val data: JsonObject) : Map<String, JsonElement> by data {
    val id: String get() = data.entityId!!
    val type: String get() = data.entityType!!
    val version: String get() = data.entityVersion!!

    // A common field
    val name: String? get() = data.getString("name")

    // the default text label to use in the UI for this item.
    val label: String get() = name ?: data.getString("term") ?: id

    /**
     * Get a string value from the data
     */
    fun getString(key: String): String? = data.getString(key)

    /**
     * Get the images data
     */
    val images: Map<ImagePurpose, Map<ImageAspectRatio, ImageVariant>> get() = data["images"]?.decodeImages().orEmpty()

    /**
     * Get the default thumbnail to use
     */
    val thumbnail: Image?
        get() = images.flatten().find {
            it.ratio == ImageAspectRatio.ASPECT_1X1
        }?.image
}

fun JsonElement.asEntity(): Entity = Entity(jsonObject)

fun List<JsonElement>.asEntities(): List<Entity> {
    return map { it.asEntity() }
}

val imageDecoder = Json {
    ignoreUnknownKeys = true
}

fun JsonElement.decodeImages(): Map<ImagePurpose, Map<ImageAspectRatio, ImageVariant>>? {
    return try {
        imageDecoder.decodeFromJsonElement(this)
    } catch (e: Throwable) {
        null // perhaps just an incorrectly named field
    }
}

@Serializable
data class EventSearchStatus(
    val id: String,
    val state: SearchStatusState,
    val results: List<CloudEvent>? = null
)

enum class SearchStatusState {
    IN_PROGRESS,
    COMPLETED,
    FAILED
}