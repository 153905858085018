package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.ShowUpdateInput
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.*
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.dom.Text
import org.jetbrains.compose.web.dom.TextArea

/**
 * Enumerates the supported directories.
 */
enum class Directories(val id: String, val viewableName: String) {
    STITCHER("stitcher", "Stitcher"),
    CONNECTED_CATALOG("connected-catalog", "Pandora Connected Catalog"),
    SIRIUSXM("sxm", "SiriusXM"),
}

@Composable
fun editShow(showId: String, returnPath: String? = null) {
    var show by remember { mutableStateOf<Show?>(null) }
    var podswagId by remember { mutableStateOf<String?>(null) }
    var seoKey by remember { mutableStateOf<String?>(null) }
    var note by remember { mutableStateOf<String?>(null) }
    var transcribe by remember { mutableStateOf<Boolean?>(null) }
    var publishTranscriptions by remember { mutableStateOf<Boolean?>(null) }
    var streamOnly by remember { mutableStateOf<Boolean?>(null) }
    var audioBook by remember { mutableStateOf<Boolean?>(null) }
    var directories by remember { mutableStateOf(emptySet<String>()) }

    LaunchedEffect(showId) {
        Podcasts.api.query {
            show(showId) {
                id
                title

                slugs

                configuration {
                    active
                    directoryDetails {
                        this.showId
                        this.showUrl
                        this.directory {
                            this.directoryId
                            this.directoryName
                        }
                    }
                    this.transcribe
                    this.transcriptionsPublished
                    this.streamOnly
                    this.audioBook
                }

                csShow {
                    csId
                }

                podswag {
                    id
                    url
                }
            }

        }.show.let {
            show = it
            podswagId = it.podswag?.id
            seoKey = it.slugs?.firstOrNull()
            directories = if (it.configuration.active == false) emptySet() else {
                it.configuration.directoryDetails.map {
                    it.directory.directoryId
                }.toSet()
            }
            transcribe = it.configuration.transcribe
            publishTranscriptions = it.configuration.transcriptionsPublished
            streamOnly = it.configuration.streamOnly
            audioBook = it.configuration.audioBook
        }
    }

    suspend fun save() {
        val input = ShowUpdateInput(
            podswagId = if (show?.podswag?.id != podswagId) (podswagId ?: "") else null,
            directories = directories.toList(),
            slug = if (show?.slugs?.firstOrNull() != seoKey) seoKey else null,
            active = if (directories.isEmpty()) false else null,
            note = note,
            transcribe = transcribe,
            publishTranscriptions = publishTranscriptions,
            streamOnly = streamOnly,
            audioBook = audioBook
        )
        try {
            Podcasts.api.mutate("UpdateConfiguration") {
                show(showId) {
                    update(input) {
                        id
                    }
                }
            }.show.update!!.id
            Podcasts.navigate(returnPath ?: "podcasts/show/${showId}")
        } catch (t: Throwable) {
            Podcasts.context.showError(
                "When save was attempted, an error was returned.",
                t.message
            )
        }
    }

    fun cancel() {
        Podcasts.navigate(returnPath ?: "podcasts/show/${showId}")
    }

    if (show == null) {
        spinner()
        return
    }

    dialogView("Edit Show Configuration") {
        content {
            box {
                dialogField("ID") {
                    Text(show?.csShow?.csId ?: "-")
                }

                dialogField("Title") {
                    Text(show?.title ?: "-")
                }

                dialogField("SEO Key") {
                    simpleTextField(seoKey) {
                        seoKey = it
                    }
                }

                dialogField("Podswag Identifier") {
                    simpleTextField(podswagId) {
                        podswagId = it
                    }
                }

                dialogField("Transcriptions") {
                    simpleCheckBox(
                        "Transcribe this show",
                        transcribe ?: false
                    ) { selected ->
                        transcribe = selected
                    }
                    simpleCheckBox(
                        "Publish transcriptions",
                        publishTranscriptions ?: true
                    ) { selected ->
                        publishTranscriptions = selected
                    }
                }

                dialogField("Directories") {
                    Directories.entries.forEach { directory ->
                        simpleCheckBox(
                            directory.viewableName,
                            directories.contains(directory.id)
                        ) { selected ->
                            val newDirectories =
                                if (selected) {
                                    directories + directory.id
                                } else {
                                    directories - directory.id
                                }
                            directories = newDirectories
                        }
                    }
                }

                dialogField("Other") {
                    simpleCheckBox(
                        "Stream only",
                        streamOnly ?: false
                    ) { selected ->
                        streamOnly = selected
                    }
                    simpleCheckBox(
                        "Audio Book",
                        audioBook ?: false
                    ) { selected ->
                        audioBook = selected
                    }
                }

                dialogField("Comment") {
                    TextArea(note) {
                        placeholder("Describe why this update is being made.")
                        onInput {
                            note = it.value.ifBlank { null }
                        }
                    }
                }
            }
        }

        action("Cancel") {
            cancel()
        }

        action {
            title = "Save"
            primary = true
            showProgressOnAction = true
            action(::save)
        }
    }
}