package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.utils.toLocalDayVerbose
import com.siriusxm.pia.views.channelguide.aggregatorIconLink
import com.siriusxm.unifiedcontent.sports.CompetitionLeague
import com.siriusxm.unifiedcontent.sports.CompetitionParticipant
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.unifiedmodel.*
import kotlinx.datetime.Clock
import org.jetbrains.compose.web.css.cursor
import org.jetbrains.compose.web.css.height
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.width
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days

/**
 * The view of a league
 */
@Composable
fun leagueView(app: SportsApplication, leagueId: String) {
    var league by remember { mutableStateOf<CompetitionLeague?>(null) }
    var entities by remember { mutableStateOf<List<CompetitionParticipant>?>(null) }
    var liveEvents by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }
    var upcomingEvents by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }
    var previousEvents by remember { mutableStateOf<List<CompetitiveEvent>?>(null) }

    var selectedDay by remember { mutableStateOf<String?>(null) }
    var selectedPreviousDay by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(leagueId) {
        league = app.client.leagues().entities.find { it.id == leagueId }
        entities = app.client.leagueParticipants(leagueId).entities.sortedBy { it.name }
        val liveAndUpcomingEvents = app.client.leagueCompetitions(
            leagueId,
            Clock.System.now().minus(1.days), Clock.System.now().plus(14.days), max = 250).entities.sortedBy { it.start }

        liveEvents = liveAndUpcomingEvents.filter { it.state == ScoreState.IN_PROGRESS }
        upcomingEvents = liveAndUpcomingEvents.filter { it.upcoming() }

        previousEvents = app.client.leagueCompetitions(
            leagueId,
            Clock.System.now().minus(14.days), Clock.System.now(), max = 250).entities.filter { it.completed() }.sortedByDescending { it.start }
    }

    serviceContentView({
        title = league?.name
        breadcrumbs {
            crumb("Sports", "sports") {
                crumb("League", null)
            }
        }
        actionArea {
            aggregatorIconLink(leagueId, IconSize.NORMAL, showOnlyOnHover = false)
        }
//        action {
//            title = "View in aggregator"
//            link = "#aggregator/entity/${leagueId}"
//        }
    }) {
        if (entities?.isEmpty() == true) {
            messageBox(
                "No entity with id $leagueId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (entities.isNullOrEmpty()) {
            spinner(size = Size.LARGE)
        } else {
//            league?.id?.let { aggregatorIconLink(it, IconSize.NORMAL) }

            tabView {
                if (!entities.isNullOrEmpty()) {
                    tab("Teams") {
                        entities?.let { teams ->
                            teamsGrid(app, teams)
                        }
                    }
                }
                if (!upcomingEvents.isNullOrEmpty()) {
                    tab("Live Events ${liveEvents?.size?.let { "($it)"} ?: "" }") {
                        eventsGridAdvanced(app, liveEvents!!)
                    }
                }
                if (!upcomingEvents.isNullOrEmpty()) {
                    tab("Upcoming Events ${upcomingEvents?.size?.let { "($it)"} ?: "" }") {
                        val upcomingEventsByDay = upcomingEvents!!.filter { it.start != null }.groupBy { it.start!!.toLocalDateTime().date.toLocalDayVerbose() }
                        val optionTextMap = upcomingEventsByDay.map { it.key to "${it.key} (${it.value.size})" }.toMap()

                        eventsGridAdvanced(app, upcomingEvents!!, upcomingEventsByDay, selectedDay, optionTextMap) {
                            selectedDay = it
                        }
                    }
                }
                if (!previousEvents.isNullOrEmpty()) {
                    tab("Prior Events ${previousEvents?.size?.let { "($it)"} ?: "" }") {
                        val previousEventsByDay = previousEvents!!.filter { it.start != null }.groupBy { it.start!!.toLocalDateTime().date.toLocalDayVerbose() }
                        val optionTextMap = previousEventsByDay.map { it.key to "${it.key} (${it.value.size})" }.toMap()

                        eventsGridAdvanced(
                            app,
                            previousEvents!!,
                            previousEventsByDay,
                            optionTextMap = optionTextMap,
                            selectedKey = selectedPreviousDay,
                            sortFn = { events -> events.sortedByDescending { it.start }}) {
                            selectedPreviousDay = it
                        }
                    }
                }
            }
        }
    }
}

private fun League?.shortName(): String? {
    return this?.additionalNames?.get(NameType.SHORT.name)?.name
}

/**
 * Render an entity list item.
 * @param includeLink if true, renders the entire item as clickable with a link to the entity page
 * @param onClick if includeLink is false, pass this if you'd like to receive a click event on the item.
 */
@Composable
fun teamItem(
    app: SportsApplication,
    team: CompetitionParticipant,
    includeLink: Boolean,
    onClick: (() -> Unit)? = null
) {
    if (includeLink) {
        A(href = "#sports/team/${team.id}") {
            teamContent(app, team)
        }
    } else {
        Div({
            if (onClick != null) {
                style {
                    cursor("pointer")
                }
                this.onClick {
                    onClick()
                }
            }
        }) {
            Div({ classes(SportsStyles.metadata) }) {
                Div({ classes(SportsStyles.entityInfo) }) {
                    Text(getTeamName(team))
                }
                Div({ classes(SportsStyles.entitySubInfo) }) {
                    Text(team.id)
                    aggregatorIconLink(team.id)
                }
            }
        }
    }
}

@Composable
fun teamContent(app: SportsApplication, team: CompetitionParticipant) {
    Div({ classes(SportsStyles.listItem) }) {
        Div {
            val preferredImage = getPreferredTeamImage(team)
            if (preferredImage != null) {
                Img(src = getAbsoluteUrl(app.context, preferredImage)) {
                    style {
                        width(100.px)
                        height(100.px)
                    }
                }
            }
        }

        Div({ classes(SportsStyles.metadata) }) {
            Div({ classes(SportsStyles.entityInfo) }) { Text(getTeamName(team)) }
            Div({ classes(SportsStyles.entitySubInfo) }) {
                Text(team.id)
            }
        }
    }

}

fun getDefaultTeamImages(team: CompetitionParticipant): List<ImageDetails> {
    val images = mutableListOf<ImageDetails>()
    team.images?.entries?.map {
        val purpose = it.key
        val aspectRatioMap = it.value
        aspectRatioMap.entries.map {
            val aspectRadio = it.key
            val defaultImage = it.value.default
            if (defaultImage != null) {
                images.add(ImageDetails(purpose, aspectRadio, defaultImage))
            }
        }
    }
    return images
}

data class ImageDetails(val purpose: ImagePurpose, val aspectRatio: ImageAspectRatio, val image: Image)

fun getPreferredTeamImage(team: CompetitionParticipant): Image? {
    return team.images?.get(ImagePurpose.TILE_CIRCLE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: team.images?.get(ImagePurpose.TILE)?.get(ImageAspectRatio.ASPECT_1X1)?.default
        ?: team.images?.get(ImagePurpose.LOGO)?.get(ImageAspectRatio.ASPECT_1X1)?.default
}

fun getTeamName(team: CompetitionParticipant): String {
    return listOfNotNull(team.name, team.nickname).joinToString(" ")
}

/**
 * Render a teams grid.
 */
@Composable
fun teamsGrid(
    app: SportsApplication,
    entities: List<CompetitionParticipant>,
    includeLinks: Boolean = true,
    onClick: ((CompetitionParticipant) -> Unit)? = null
) {
    Div({ classes(SportsStyles.entityInfo) }) {
        Div({ classes(SportsStyles.subheader) }) {
            Text("${entities.size} Teams")
        }
        Div({ classes(SportsStyles.gridList) }) {
            entities.forEach { entity ->
                teamItem(app, entity, includeLinks && onClick == null) {
                    onClick?.invoke(entity)
                }
            }
        }
    }
}