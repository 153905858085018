package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H1
import org.jetbrains.compose.web.dom.Text

open class ServiceContentViewConfiguration {
    var type: EntityViewType = EntityViewType.NORMAL
    var title: String? = null
    var subTitle: String? = null
    var settings: (@Composable DialogOverlayAttributes.() -> Unit)? = null
    internal var content: (@Composable () -> Unit)? = null
    internal var sidebar: (@Composable () -> Unit)? = null
    internal var help: (@Composable () -> Unit)? = null
    internal var breadcrumbConfig: (BreadcrumbConfiguration.() -> Unit)? = null
    internal val actions = mutableListOf<ButtonConfiguration>()
    fun breadcrumbs(config: BreadcrumbConfiguration.() -> Unit) {
        breadcrumbConfig = config
    }

    internal var actionArea: (@Composable () -> Unit)? = null

    fun content(block: @Composable () -> Unit) {
        content = block
    }

    fun action(block: ButtonConfiguration.() -> Unit) {
        actions += ButtonConfiguration().apply(block)
    }

    fun action(title: String, primary: Boolean = false, impl: suspend () -> Unit) {
        actions += ButtonConfiguration(title, primary, impl)
    }

    fun settings(block: @Composable DialogOverlayAttributes.() -> Unit) {
        settings = block
    }

    fun actionArea(block: @Composable () -> Unit) {
        actionArea = block
    }

    /**
     * Install a sidebar. Show/hide will be managed by this view.
     */
    fun navigationSidebar(defaultVisibility: Boolean = false, block: @Composable () -> Unit) {
        sidebar = block
    }

    /**
     * Install a help sidebar. Show/hide will be managed by this view.
     */
    fun helpSidebar(block: @Composable () -> Unit) {
        help = block
    }
}

@Composable
fun serviceContentView(builder: ServiceContentViewConfiguration.() -> Unit = {}, content: @Composable () -> Unit) {
    val config = ServiceContentViewConfiguration().apply(builder)
    config.content = content
    serviceContentView(config)
}

@Composable
fun serviceContentView(
    config: ServiceContentViewConfiguration
) {
    // Main content
    Div({
        classes(ServiceViewStyles.entityView)
        style {
            flex(1)
        }
    }) {
        primaryContentArea {
            if (config.breadcrumbConfig != null) {
                breadcrumbs(config.breadcrumbConfig!!)
            }

            if (config.settings != null) {
                Div({
                    style {
                        position(Position.Absolute)
                        top(5.px)
                        right(5.px)
                    }
                }) {
                    dialogIcon("settings", config.settings!!)
                }
            }

            if (config.title != null || config.actionArea != null || config.actions.isNotEmpty()) {
                Div({ classes(ServiceViewStyles.titleArea) }) {
                    Div {
                        config.title?.let { title ->
                            H1 {
                                Text(title)
                            }
                            config.subTitle?.let {
                                Div({ classes(ServiceViewStyles.subHead) }) {
                                    Text(it)
                                }
                            }
                        }
                    }
                    if (config.actionArea != null) {
                        Div {
                            config.actionArea!!()
                        }
                    } else if (config.type == EntityViewType.NORMAL && config.actions.isNotEmpty()) {
                        Div {
                            config.actions.forEach {
                                button(it)
                            }
                        }
                    }
                }
            }

            config.content?.let { content ->
                Div {
                    content()
                }
            }

            if (config.type == EntityViewType.DIALOG && config.actions.isNotEmpty()) {
                Div({
                    style {
                        textAlign("right")
                    }
                }) {
                    config.actions.forEach {
                        button(it)
                    }
                }
            }
        }
    }
}