package com.siriusxm.pia.views.unifiedaggregator.management

import androidx.compose.runtime.Composable
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.aggregator.ProducerDetails
import org.jetbrains.compose.web.attributes.ATarget
import org.jetbrains.compose.web.attributes.target
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text

/**
 * The view for browsing and editing producers.
 */
@Composable
fun producers(aggregator: AggregatorService, selectedId: String? = null) {
    val producers = aggregator.producers

    serviceContentView({
        title = "Producers"
        if (aggregator.isAdmin() && selectedId == null) {
            this.action {
                title = "New Producer"
                primary = true
                action {
                    aggregator.navigate("producers/edit")
                }
            }
        }
    }) {
        splitSelectionView<ProducerDetails> {
            items = producers
            selection = producers.find { it.id == selectedId }
            onSelect {
                aggregator.navigate("producers/${encodeURIComponent(it.id)}")
            }
            renderListItem {
                Text(it.name ?: it.id)
            }
            render { producer ->
                producer(aggregator, producer)
            }
        }
    }
}

@Composable
fun producer(aggregator: AggregatorService, producer: ProducerDetails) {
    entityView({
        title = producer.name

        if (aggregator.isAdmin()) {
            action("Edit", true) {
                aggregator.navigate("producers/${encodeURIComponent(producer.id)}/edit")
            }
        }
    }) {
        box({
            title = "Producer Details"
        }) {
            detailGrid {
                detail("ID", producer.id)
                producer.clientId?.let {
                    detail("OAuth ClientId", it)
                }

                if (producer.awsAccountId != null) {
                    detail("AWS Account") {
                        Text(producer.awsAccountId!!)
                    }
                }
                if (producer.contact?.isNotEmpty() == true) {
                    detail("Contact") {
                        producer.contact?.forEach {
                            Div {
                                Text(it.email)
                            }
                        }
                    }
                }
                producer.feedbackTargetArn?.let {
                    detail("Feedback Target ARN", producer.feedbackTargetArn)
                }

                producer.slackChannel?.let {
                    detail("Slack") {
                        A(
                            href = "https://siriusxm-pandora.slack.com/channels/${it}",
                            { target(ATarget.Blank) }) {
                            Img(src = "images/slack.png") {
                                style {
                                    width(20.px)
                                    marginRight(.5.em)
                                    display(DisplayStyle.InlineBlock)
                                    property("vertical-align", "middle")
                                }
                            }
                            Text(it)
                        }
                    }
                }
            }
        }

        box({
            title = "Types"
            paddedContent = false
            header({
                instruction = "This producer is permitted to publish these types."
            })
        }) {
            table<String> {
                items(producer.allowedTypes?.toList().orEmpty())

                column {
                    content { allowedType ->
                        Div {
                            A(href = "#aggregator/types/entities/${encodeURIComponent(allowedType)}") {
                                Text(aggregator.entityType(allowedType)?.name ?: allowedType)
                            }
                        }
                    }
                }

            }
        }

        if (!producer.allowedPartials.isNullOrEmpty()) {
            box({
                title = "Partials"
                paddedContent = false
                header({
                    instruction = "This producer is permitted to publish these partials."
                })
            }) {
                table<String> {
                    items(producer.allowedPartials?.toList().orEmpty())

                    column {
                        content { allowedPartial ->
                            Div {
                                val partialName = aggregator.partial(allowedPartial)?.name
                                    ?: allowedPartial
                                A(href = "#aggregator/types/partials/${encodeURIComponent(allowedPartial)}") {
                                    Text(partialName)
                                }
                            }
                        }
                    }
                }
            }
        }

        ServiceUsersBox(aggregator, producer.id, ServiceUsersType.PRODUCER)
    }
}