package com.siriusxm.pia.views.unifiedaggregator.management

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.addTarget
import com.siriusxm.pia.showError
import com.siriusxm.pia.utils.validateArn
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.aggregator.AddConsumerTarget
import contentingestion.aggregator.ConsumerDetails
import contentingestion.aggregator.ConsumerTarget
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

/**
 * Displays a list of targets along with an interface for editing.
 */
@Composable
fun consumerTargetsView(
    aggregator: AggregatorService,
    initialConsumer: ConsumerDetails,
    editable: Boolean,
    onUpdate: suspend (ConsumerDetails) -> Unit
) {
    var consumer by remember { mutableStateOf(initialConsumer) }
    var addingTarget by remember { mutableStateOf(false) }

    box({
        title = "Targets"
        paddedContent = false

        if (editable) {
            action {
                title = "Add"
                enabled = !addingTarget
                this.action {
                    addingTarget = true
                }
            }
        }
    }) {
        val hasTargets = !consumer.targets.isNullOrEmpty()
        if (!addingTarget && !hasTargets) {
            boxMessage("This consumer has no targets.")
        }
        if (hasTargets) {
            table<ConsumerTarget> {
                items(consumer.targets.orEmpty())

                column {
                    title = "Target"
                    content { target ->
                        Text(target.arn)
                    }
                }
                column {
                    title = "Role"
                    content { target ->
                        target.role?.let {
                            Text(it)
                        }
                    }
                }
                column {
                    width = 20.px
                    content {

                    }
                }
            }
        }

        if (addingTarget) {
            Div({ style { padding(10.px) } }) {
                consumerTargetEditor(aggregator, consumer) { updatedConsumer ->
                    addingTarget = false
                    if (updatedConsumer !=null) {
                        consumer = updatedConsumer
                        onUpdate(updatedConsumer)
                    }
                }
            }
        }
    }
}

/**
 * Editor for adding a new target to a consumer
 */
@Composable
fun consumerTargetEditor(
    aggregator: AggregatorService, consumer: ConsumerDetails,
    onComplete: suspend (ConsumerDetails?) -> Unit
) {
    var targetArn by remember { mutableStateOf<String?>(null) }
    var targetRole by remember { mutableStateOf<String?>(null) }

    suspend fun addTarget(arn: String, role: String?) {
        try {
            val newConsumer = aggregator.api.addTarget(
                consumer.id, AddConsumerTarget(
                    arn = arn,
                    roleArn = role,
                    rule = "INHERIT"
                )
            )

            val newTarget = ConsumerTarget(
                id = newConsumer.targets?.lastOrNull()?.id ?: arn,
                arn = arn,
                role = role,
                rule = "INHERIT",
                legacy = false
            )

            val updatedConsumer = consumer.copy(
                targets = consumer.targets.orEmpty() + newTarget
            )

            onComplete(updatedConsumer)
        } catch (e: Throwable) {
            aggregator.context.showError("Unable to add target", e)
        }
    }

    dialogView("Add target") {
        content {
            dialogField("Target ARN", "The ARN of the target") {
                simpleTextField(targetArn) {
                    targetArn = it
                }
            }
            dialogField("Target Role", "The role to be used to publish to this target") {
                simpleTextField(targetRole) {
                    targetRole = it
                }
            }
        }

        action {
            title = "Add"
            showProgressOnAction = true
            enabled = targetArn?.let { validateArn(it) } == true
            this.action {
                addTarget(targetArn!!, targetRole)
            }
        }
        action {
            title = "Cancel"
            enabled = true
            this.action {
                onComplete(null)
            }
        }
    }
}