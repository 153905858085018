package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import contentingestion.aggregator.PartialSchema
import contentingestion.aggregator.ServiceType
import contentingestion.aggregator.ServiceUserMembershipList
import contentingestion.unifiedmodel.EntityType

/**
 * The model that holds user roles and information
 */
class UserRoles(val app: AggregatorApplication) {
    private var serviceRoles: ServiceUserMembershipList = emptyList()

    suspend fun load() {
        serviceRoles = app.aggregatorClient.serviceRoles()
    }

    /**
     * Get the list of partials that are available to be edited by our viewer.
     */
    @Composable
    fun availablePartials(): State<List<PartialSchema>> {
        val appData by app.aggregatorContext()

        return remember(appData) {
            derivedStateOf {
                if (appData != null) {
                    val producers = appData?.producers.orEmpty()
                    val partials = appData?.partials.orEmpty()
                    val producerRolesId = serviceRoles.filter {
                        it.type == ServiceType.PRODUCER
                    }.map { it.serviceId }
                    producers
                        .filter {
                            producerRolesId.contains(it.id)
                        }
                        .flatMap {
                            it.allowedPartials.orEmpty()
                        }
                        .distinct()
                        .mapNotNull { allowedId ->
                            partials.find { it.id == allowedId }
                        }
                } else {
                    emptyList()
                }
            }
        }
    }

    /**
     * Get the list of memberships of this user.
     */
    fun serviceMemberships(): ServiceUserMembershipList {
        return serviceRoles
    }
}


/**
 * Provides the available partials list.
 */
@Composable
fun AggregatorApplication.availablePartials(type: EntityType?): State<List<PartialSchema>?> {
    val userRoles by userRoles()
    return if (userRoles != null) {
        val partialsState = userRoles?.availablePartials()
        derivedStateOf {
            if (type == null) {
                null
            } else {
                partialsState?.value?.filter { partial ->
                    // Replace this with your actual filtering condition.
                    partial.types.map { it.toEntityType() }.contains(type)
                }.orEmpty()
            }
        }
    } else {
        // When userRoles is null, provide a default state.
        remember { mutableStateOf(emptyList()) }
    }
}