package com.siriusxm.pia.rest

import com.siriusxm.pia.ApplicationContext
import io.ktor.client.*

/**
 * Install API authentication.
 */
fun HttpClientConfig<*>.installAuth(context: ApplicationContext) {
    context.installAuth(this)
}
