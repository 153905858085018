package com.siriusxm.content.management.console.applications.transcription

import androidx.compose.runtime.Composable
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.ConsoleApplication
import com.siriusxm.pia.IngestionAPI
import com.siriusxm.pia.Viewer
import com.siriusxm.pia.components.NotificationsConfig
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.rest.unifiedaggregator.unifiedAggregatorClient
import com.siriusxm.pia.utils.Navigator
import com.siriusxm.pia.utils.Route

/**
 * A global context for the transcription application
 */
class TranscriptionContext(
    val api: TranscriptionAPI,
    val aggregator: UnifiedAggregatorClient,
    val podcastApi: IngestionAPI,
    val editorBaseUrl: String?,
    val appContext: ApplicationContext
) {
    val navigation: Navigator get() = appContext
    val notifications: NotificationsConfig
        get() = object : NotificationsConfig {
            override fun showError(message: String, description: String?) {
                appContext.showError(message, description)
            }

            override fun info(message: String, description: String?) {
                appContext.info(message, description)
            }
        }
    val viewer: Viewer get() = appContext.viewer
}

/**
 * A global variable to hold the Transcriptions context. Might be useful
 * to pass as a parameter instead, but works fine as is.
 */
lateinit var Transcriptions: TranscriptionContext

class TranscriptionApplication() : ConsoleApplication {
    override fun initialize(context: ApplicationContext) {
        val transcriptionAPI = TranscriptionAPI(context.configuration.transcriptionApiUrl) {
            context.accessToken!!
        }
        val aggregator = unifiedAggregatorClient(context)
        val podcastApi = IngestionAPI(context.configuration.apiUrl) {
            context.accessToken!!
        }


        Transcriptions = TranscriptionContext(
            transcriptionAPI,
            aggregator,
            podcastApi,
            context.configuration.transcriptionEditorBaseUrl,
            context
        )

    }

    @Composable
    override fun route(route: Route) {
        route.transcriptions()
    }
}