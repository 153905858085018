package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.ColorizableField
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.colorListViewer
import com.siriusxm.pia.components.colorValueWithHash
import com.siriusxm.pia.rest.unifiedaggregator.Entity
import com.siriusxm.pia.rest.unifiedaggregator.getFloat
import com.siriusxm.pia.rest.unifiedaggregator.getString
import com.siriusxm.pia.views.sports.ImageDetails
import com.siriusxm.pia.views.sports.getAbsoluteUrl
import contentingestion.aggregator.*
import contentingestion.unifiedmodel.ChannelColors
import contentingestion.unifiedmodel.Color
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.TeamColors
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import kotlinx.datetime.Clock
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.seconds

@Composable
fun entityColors(aggregator: AggregatorService, entity: Entity, showEntityImages: Boolean? = true,
                 reloadCb: suspend () -> Unit) {
    val coroutineScope = rememberCoroutineScope()

    var fetchedColorList by remember { mutableStateOf<List<ColorizableField>?>(null) }
    var saving by remember { mutableStateOf(false) }

    suspend fun saveColors(colors: List<ColorizableField>): Boolean {
        try {
            saving = true

            val partialUpdate = entity.getColorPartialUpdate(colors)
            if (partialUpdate != null) {
                aggregator.api.partialUpdate(
                    partialUpdate
                )
                fetchedColorList = colors
                Application.notifications.info("Colors saved. " +
                        "If the app doesn't reload, you may need to refresh the page.")
                delay(3.seconds)
                reloadCb()
            }
            return true
        } catch (t: Throwable) {
            Application.notifications.showError(
                "When save was attempted, an error was returned.",
                t.message
            )
        } finally {
            saving = false
        }
        return false
    }

    LaunchedEffect(entity.id, entity.type, entity.version) {
        fetchedColorList = entity.fetchColorList()
    }

    val colors = fetchedColorList
    if (colors != null) {
        if (showEntityImages == true) {
            // images for reference
            val images = extractImages(entity)

            box("Images for Reference", {
                paddedContent = false
            }) {
                images.forEach {
                    Div({ classes(AggregatorStyles.imageBox) }) {
                        Div({ classes(AggregatorStyles.imageContainer) }) {
                            Img(getAbsoluteUrl(aggregator.context, it.image))
                        }
                        Div({ classes(AggregatorStyles.imageLabel) }) {
                            Text("${it.purpose} ${it.aspectRatio.name.removePrefix("aspect_")}")
                        }
                    }
                }
            }
        }
        colorListViewer(colors, true) {
            coroutineScope.launch {
                saveColors(it)
            }
        }
    }
}

fun Entity.getColorPartialUpdate(colors: List<ColorizableField>) : PartialUpdate? {
    val partialJson = extractColorsJson(this, colors)
    if (partialJson != null) {
        return buildPartialUpdate(this, Application.viewer.email, partialJson)
    }
    return null
}

val typeToColorFieldsMap = mapOf(
    Pair("channel-linear", listOf("lightBackgroundColor", "darkBackgroundColor")),
    Pair("channel-xtra", listOf("lightBackgroundColor", "darkBackgroundColor")),
    Pair("team", listOf("primary", "secondary", "background")))
fun Entity.fetchColorList() : List<ColorizableField>? {
    val colorFields = typeToColorFieldsMap[this.type]
    val colorsElement = this["colors"]?.jsonObject
    val colorList = colorFields?.map {
        extractColorizableField(it, colorsElement)
    } ?: listOf()

    return colorList.ifEmpty {
        null
    }
}

private fun extractImages(entity: Entity) : List<ImageDetails>{
    val images = mutableListOf<ImageDetails>()

    val imagesMap = entity.images
    imagesMap.entries.map {
        val purpose = it.key
        val aspectRatioMap = it.value
        aspectRatioMap.entries.map {
            val aspectRadio = it.key

            val possibleImages = mutableListOf<Image>()
            it.value.default?.let { img -> possibleImages.add(img) }
            it.value.auto?.let { img -> possibleImages.add(img) }
            it.value.child?.let { img -> possibleImages.add(img) }

            possibleImages.forEach { img ->
                images.add(ImageDetails(purpose, aspectRadio, Image(img.width, img.height, img.url)))
            }
        }
    }
    return images
}

private fun extractColorsJson(entity: Entity, colors: List<ColorizableField>?) : JsonElement? {
    val type = entity.type

    var result: JsonElement? = null
    if (type == "channel-linear" || type == "channel-extra") {
        val lightBackgroundColor = colors?.find { it.field == "lightBackgroundColor" }
        val darkBackgroundColor = colors?.find { it.field == "darkBackgroundColor" }
        val channelColors = ChannelColors(
            lightBackgroundColor = lightBackgroundColor?.toColor(),
            darkBackgroundColor = darkBackgroundColor?.toColor()
        )
        result = Json.encodeToJsonElement(channelColors)
    } else if (type == "team") {
        // "primary", "secondary", "background"
        val primaryColor = colors?.find { it.field == "primary" }
        val secondaryColor = colors?.find { it.field == "secondary" }
        val backgroundColor = colors?.find { it.field == "background" }
        val teamColors = TeamColors(
            primary =  primaryColor?.toColor(),
            secondary = secondaryColor?.toColor(),
            background = backgroundColor?.toColor()
        )
        result = Json.encodeToJsonElement(teamColors)
    }
    return result
}

private fun buildPartialUpdate(entity: Entity, viewerEmail: String?, colorsJson: JsonElement) : PartialUpdate {
    val id = entity.id
    val type = entity.type
    val version = entity.version

    val partialUpdate = PartialUpdate(
        type = ContentUpdateType.UPDATE,
        timestamp = Clock.System.now(),
        modification = buildJsonObject {
            put("id", id)
            put("type", type)
            put("version", version)
            put("colors", colorsJson)
        },
        partialId = "cmc-${type}-colors",
        operations = listOf(
            PartialUpdateOperation(
                type = PartialUpdateOperationType.REPLACE,
                sourcePath = "$.colors",
                targetPath = "$.colors"
            )
        ),
        auditContext = viewerEmail?.let {
            AuditContext(viewerEmail)
        }
    )
    return partialUpdate
}

private fun extractColorizableField(label: String, colorsElement: JsonObject?) : ColorizableField {
    val color = colorsElement?.get(label)?.let { extractColor(it)}
    return ColorizableField(label, color?.hex, color?.opacity)
}

private fun extractColor(jsonElement: JsonElement) : Color? {
    val hex = jsonElement.getString("hex")
    val label = jsonElement.getString("label")
    val opacity = jsonElement.getFloat("opacity")

    return hex?.let { hexValue ->
        Color(
            label = label,
            hex = hexValue ,
            opacity = opacity
        )
    }
}

fun ColorizableField.toColor(): Color? {
    return color?.let { c ->
        Color(
            label = null, // we dont need to save the label
            hex = colorValueWithHash(c),
            opacity = opacity
        )
    }
}