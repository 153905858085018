package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.Application
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.*
import kotlinx.serialization.Serializable
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.disabled
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Input
import org.jetbrains.compose.web.dom.Text

@Composable
fun featureFlags(aggregatorService: AggregatorService, editable: Boolean) {
    var featureFlags by remember { mutableStateOf<AppConfigFeatureFlagsDocument?>(null) }
    var updates by remember { mutableStateOf<Map<String, JsonObject>>(emptyMap()) }
    var description by remember { mutableStateOf<String?>(null) }

    LaunchedEffect(true) {
        featureFlags = aggregatorService.api.features()
    }

    suspend fun save() {
        if (updates.isNotEmpty()) {
            try {
                aggregatorService.api.updateFeatures(
                    FeatureFlagsUpdate(
                        updates.map { (key, value) ->
                            val isEnabled = value["enabled"]?.jsonPrimitive?.booleanOrNull
                            FeatureFlagUpdate(key, isEnabled ?: false,
                                value.filterKeys { it != "enabled" })
                        },
                        deploymentDescription = description
                    )
                )
                updates = emptyMap()
                description = null
                Application.notifications.info("Feature flags updated. Please wait up to a minute to allow them to propagate.")
            } catch (e: Throwable) {
                Application.notifications.showError("Feature flags didn't update: ${e.message}")
            }
        }
    }

    dialogView("Feature Flags") {
        if (updates.isNotEmpty()) {
            action {
                title = "Save"
                primary = true
                showProgressOnAction = true
                action {
                    save()
                }
            }
            action {
                title = "Reset"
                action {
                    updates = emptyMap()
                }
            }
        }

        content {
            waitUntilNonNull(featureFlags) { flags ->
                val flagsAndValues = flags.flags.map { (key, value) ->
                    val updatedValue = updates[key] ?: flags.values[key]
                    FlagsAndValues(key, value, updatedValue)
                }

                box({
                    paddedContent = false
                }) {
                    table<FlagsAndValues> {
                        items(flagsAndValues)

                        column {
                            style {
                                property("vertical-align", "top")
                            }
                            width = 2.cssRem
                            content { flag ->
                                val isEnabled = flag.value?.get("enabled")?.jsonPrimitive?.booleanOrNull == true
                                Input(type = InputType.Checkbox) {
                                    checked(isEnabled)
                                    if (!editable) {
                                      disabled()
                                    }
                                    onClick {
                                        if (editable) {
                                            val updated = flag.value.copyOrBuild {
                                                put("enabled", !isEnabled)
                                            }
                                            updates = updates + (flag.id to updated)
                                        }
                                    }
                                }
                            }
                        }

                        column {
                            content {
                                Div({
                                    style {
                                        fontWeight(700)
                                        fontSize(14.px)
                                    }
                                }) {
                                    Text(it.descriptor.name)
                                }
                                it.descriptor.description?.let {
                                    Div({ style { fontSize(.9.em) } }) {
                                        Text(it)
                                    }
                                }

                                it.descriptor.attributes?.let { attrs ->
                                    Div({
                                        style {
                                            marginTop(1.em)
                                        }
                                    }) {
                                        attrs.forEach { (key, value) ->
                                            Div {
                                                Text(key)
                                            }
                                            Div {
                                                it.value?.getString(key)?.let {
                                                    Text(it)
                                                }

                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }


                if (updates.isNotEmpty()) {
                    dialogField("Description", "Description the changes and purpose of the update.") {
                        simpleTextField(description) {
                            description = it
                        }
                    }
                }
            }
        }
    }
}

@Serializable
data class FlagsAndValues(
    val id: String,
    val descriptor: AppConfigFeatureFlag,
    val value: JsonObject?
)

/**
 * Copy the given object, allowing for augmenting or replacing fields.
 */
fun JsonObject.copy(block: JsonObjectBuilder.() -> Unit): JsonObject {
    return buildJsonObject {
        this@copy.forEach { (key, value) ->
            this.put(key, value)
        }
        block()
    }
}

/**
 * Copy and apply the update or create a new object
 */
fun JsonObject?.copyOrBuild(block: JsonObjectBuilder.() -> Unit): JsonObject {
    return this?.copy(block) ?: buildJsonObject(block)
}