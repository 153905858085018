package com.siriusxm.pia.views.unifiedaggregator.smithy

import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.JsonViewStyles
import com.siriusxm.pia.components.shadowBorder
import org.jetbrains.compose.web.css.*

/**
 * Styles for the Smithy Viewer classes
 */
object SmithyViewerStyles : StyleSheet() {
    val memberDocumentation by style {
        property("visibility", "hidden")
        display(DisplayStyle.InlineBlock)
        marginLeft(3.px)
        color(SXMUI.disabledTextColor.value())
    }

    val memberLabel by style {
        display(DisplayStyle.InlineBlock)
        width(100.percent)
        fontWeight(700)
        (self + hover).style {
            className(memberDocumentation) style {
                property("visibility", "visible")
            }
        }
    }

    val fieldValue by style {
        child(self, className(JsonViewStyles.copyContentIcon)) style {
            display(DisplayStyle.InlineBlock)
            marginLeft(5.px)
            property("visibility", "hidden")
        }

        (self + hover).style {
            child(self, className(JsonViewStyles.copyContentIcon)) style {
                property("visibility", "visible")
            }
        }
    }

    val listViewerContainer by style {
        display(DisplayStyle.Flex)
        flexDirection(FlexDirection.Column)
        gap(10.px)
        padding(10.px, 10.px, 10.px, 0.px)
    }

    val listViewerItem by style {
        shadowBorder()
        borderRadius(SXMUI.boxRadius.value())
        overflow("hidden")
    }
}