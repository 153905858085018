// Generated by Ktorfit
@file:Suppress("warnings")

package com.siriusxm.pia.rest.transcription

import de.jensklingenberg.ktorfit.Ktorfit
import de.jensklingenberg.ktorfit.`internal`.InternalKtorfitApi
import de.jensklingenberg.ktorfit.`internal`.KtorfitConverterHelper
import de.jensklingenberg.ktorfit.`internal`.KtorfitInterface
import de.jensklingenberg.ktorfit.`internal`.TypeData
import io.ktor.client.request.HttpRequestBuilder
import io.ktor.client.request.headers
import io.ktor.client.request.parameter
import io.ktor.client.request.setBody
import io.ktor.http.HttpMethod
import io.ktor.http.URLBuilder
import io.ktor.http.decodeURLQueryComponent
import io.ktor.http.encodeURLPath
import io.ktor.http.takeFrom
import io.ktor.util.reflect.typeInfo
import kotlin.Any
import kotlin.OptIn
import kotlin.String
import kotlin.Suppress
import kotlin.collections.Map

@OptIn(InternalKtorfitApi::class)
public class _TranscriptionClientImpl : TranscriptionClient, KtorfitInterface {
  override lateinit var _converter: KtorfitConverterHelper

  override suspend fun status(headerMap: Map<String, String>): String {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "status")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.String",
    typeInfo = typeInfo<kotlin.String>())

    return _converter.suspendRequest<kotlin.String, kotlin.String>(_typeData,_ext)!!
  }

  override suspend fun preferredVersion(typedEntityId: String, headerMap: Map<String, String>):
      TranscriptionDetailSummary {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl +
            "entity/${"$typedEntityId".encodeURLPath()}/transcriptions/detailsWithPreferredVersion")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.TranscriptionDetailSummary",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.TranscriptionDetailSummary>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.TranscriptionDetailSummary,
        com.siriusxm.pia.rest.transcription.TranscriptionDetailSummary>(_typeData,_ext)!!
  }

  override suspend fun hasTranscriptions(request: HasTranscriptionsRequest,
      headerMap: Map<String, String>): HasTranscriptionsResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "entity/hasTranscriptions")
        }
        setBody(request)
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.HasTranscriptionsResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.HasTranscriptionsResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.HasTranscriptionsResponse,
        com.siriusxm.pia.rest.transcription.HasTranscriptionsResponse>(_typeData,_ext)!!
  }

  override suspend fun activeJobs(headerMap: Map<String, String>): PaginatedTranscriptionJobs {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "jobs")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs,
        com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>(_typeData,_ext)!!
  }

  override suspend fun completedJobs(headerMap: Map<String, String>): PaginatedTranscriptionJobs {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "jobs/completed")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs,
        com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>(_typeData,_ext)!!
  }

  override suspend fun failedJobs(headerMap: Map<String, String>): PaginatedTranscriptionJobs {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "jobs/failed")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs,
        com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>(_typeData,_ext)!!
  }

  override suspend fun getJobsForEntityId(typedEntityId: String, headerMap: Map<String, String>):
      PaginatedTranscriptionJobs {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "jobs/entity/${"$typedEntityId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs,
        com.siriusxm.pia.rest.transcription.PaginatedTranscriptionJobs>(_typeData,_ext)!!
  }

  override suspend fun getJobsById(jobId: String, headerMap: Map<String, String>):
      TranscriptionJob? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "jobs/${"$jobId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.TranscriptionJob?",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.TranscriptionJob?>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.TranscriptionJob?,
        com.siriusxm.pia.rest.transcription.TranscriptionJob?>(_typeData,_ext)
  }

  override suspend fun submitJob(request: TranscriptionRequest, headerMap: Map<String, String>):
      TranscriptionJob? {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "jobs")
        }
        setBody(request)
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.TranscriptionJob?",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.TranscriptionJob?>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.TranscriptionJob?,
        com.siriusxm.pia.rest.transcription.TranscriptionJob?>(_typeData,_ext)
  }

  override suspend fun fetchVocabulary(vocabularyId: String, headerMap: Map<String, String>):
      VocabularyResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("GET")
        url{
        takeFrom(_converter.baseUrl + "vocabularies/${"$vocabularyId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.VocabularyResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.VocabularyResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.VocabularyResponse,
        com.siriusxm.pia.rest.transcription.VocabularyResponse>(_typeData,_ext)!!
  }

  override suspend fun upsertVocabulary(
    vocabularyId: String,
    vocabularyUpdateRequest: VocabularyUpdateRequest,
    headerMap: Map<String, String>,
  ): VocabularyResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("PUT")
        url{
        takeFrom(_converter.baseUrl + "vocabularies/${"$vocabularyId".encodeURLPath()}")
        }
        setBody(vocabularyUpdateRequest)
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.VocabularyResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.VocabularyResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.VocabularyResponse,
        com.siriusxm.pia.rest.transcription.VocabularyResponse>(_typeData,_ext)!!
  }

  override suspend fun createVocabulary(vocabularyRequest: VocabularyRequest,
      headerMap: Map<String, String>): VocabularyResponse {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("POST")
        url{
        takeFrom(_converter.baseUrl + "vocabularies")
        }
        setBody(vocabularyRequest)
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "com.siriusxm.pia.rest.transcription.VocabularyResponse",
    typeInfo = typeInfo<com.siriusxm.pia.rest.transcription.VocabularyResponse>())

    return _converter.suspendRequest<com.siriusxm.pia.rest.transcription.VocabularyResponse,
        com.siriusxm.pia.rest.transcription.VocabularyResponse>(_typeData,_ext)!!
  }

  override suspend fun deleteVocabulary(vocabularyId: String, headerMap: Map<String, String>): Any {
    val _ext: HttpRequestBuilder.() -> Unit = {
        method = HttpMethod.parse("DELETE")
        url{
        takeFrom(_converter.baseUrl + "vocabularies/${"$vocabularyId".encodeURLPath()}")
        }
        headers{
        headerMap.forEach{ append(it.key , it.value)}
        append("Content-Type", "application/json")
        } 
        }
    val _typeData = TypeData.createTypeData(
    qualifiedTypename = "kotlin.Any",
    typeInfo = typeInfo<kotlin.Any>())

    return _converter.suspendRequest<kotlin.Any, kotlin.Any>(_typeData,_ext)!!
  }
}

public fun Ktorfit.createTranscriptionClient(): TranscriptionClient =
    this.create(_TranscriptionClientImpl().apply { _converter=
    KtorfitConverterHelper(this@createTranscriptionClient) })
