package com.siriusxm.pia.rest.transcription

import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable

@Serializable
data class HasTranscriptionsRequest(
    val typedEntityIds: List<String>
)

@Serializable
data class HasTranscriptionsResponse(
    val results: List<TranscriptionVersion>
)

@Serializable
data class TranscriptionDetailSummary(
    val details: List<TranscriptionDetail>? = null,
    val preferredVersion: TranscriptionVersion? = null
)
@Serializable
data class TranscriptionDetail(
    val transcription: Transcription? = null,
    val versions: List<TranscriptionVersion>? = null
)

@Serializable
data class Transcription(
    val typedEntityId: String,
    val transcriptionId: String,
    val formats: List<TranscriptionFormat>,
    val sourceUrl: String,
    val updated: String, // ISO8601 formatted datetime
    val fidelity: Int,

    // may not be present
    val provider: TranscriptionProvider?,
    val providerData: TranscriptionProviderData?,

    // need to add to spec
    val created: String, // ISO8601 formatted datetime
    val activeVersion: Int? = null,
    val activeVersionDescription: String? = null,
    val inactive: Boolean? = null,
    val userData: String? = null,
    val filterProfanity: Boolean? = null
)

@Serializable
data class TranscriptionFormatSummary(
    val typedEntityId: String,
    val transcriptionId: String,
    val versionNumber: Int,
    val format: TranscriptionFormatType,
    val url: String,
    val updated: String?,
    val active: Boolean,
    val preferredVersion: Boolean,
    val episodeName: String? = null,
    val episodeType: String? = null,
    val episodeDescription: String? = null,
    val showTypedEntityId: String? = null
)

@Serializable
data class TranscriptionVersion(
    val versionNumber: Int,
    val typedEntityId: String,
    val transcriptionId: String,
    val created: String,
    val formats: List<TranscriptionFormat>,
    val updated: String, // ISO8601 formatted datetime
    val fidelity: Int? = null,
    val description: String? = null,
    val data: String? = null,
    val inactive: Boolean? = null
)

@Serializable
data class TranscriptionFormat(
    val format: TranscriptionFormatType,
    val contentType: TranscriptionContentType,
    val url: String
)

@Serializable
enum class TranscriptionFormatType() {
    @SerialName("plain")
    PLAIN,

    @SerialName("html")
    HTML,

    @SerialName("vtt")
    VTT,

    @SerialName("timestamps")
    TIMESTAMPS;
}

@Serializable
enum class TranscriptionContentType(val s3Suffix: String) {
    @SerialName("text/plain")
    TEXT_PLAIN(".txt"),

    @SerialName("text/html")
    TEXT_HTML(".html"),

    @SerialName("text/vtt")
    TEXT_VTT(".vtt"),

    @SerialName("application/json")
    JSON(".json");
}

@Serializable
data class TranscriptionProvider(val id: String)

@Serializable
data class TranscriptionProviderData(val data: String)

@Serializable
data class TranscriptionJob(
    val jobId: String,
    val request: TranscriptionRequest,
    val start: String? = null, // ISO8601 timestamp
    val end: String? = null, // ISO8601 timestamp of completion
    val provider: TranscriptionProvider? = null,
    val providerData: TranscriptionProviderData? = null,
    val fidelity: Int? = null,
    val status: TranscriptionJobStatus? = null,
    val statusDetail: String? = null,
    val transcriptionId: String? = null,

    val statusDetails: String? = null,
    val vocabularyPhrasesTruncated: String? = null,
    var vocabularyPhrasesLink: String? = null
)


@Serializable
data class TranscriptionRequest(
    val typedEntityId: String,
    val sourceUrl: String,
    val data: String? = null,
    val callbackUrl: String? = null,
    val fidelity: Int,

    // this is whether the transcription defaults to inactive
    val inactive: Boolean? = false,
    val autoVersion: Boolean? = true,
    val description: String? = null,
    val filterProfanity: Boolean? = null,

    val customVocabularyPhrases: List<String>? = null,
    val customVocabularyIds: List<String>? = null,

    // allow the client to be set after the fact
    var clientId: String? = null
) {
    fun requiresHumanTranscription(): Boolean = fidelity >= Companion.HUMAN_LEVEL_FIDELITY

    val useRevDotComForHumanTranscription = true
    val transcriptionProvider: String
        get() {
            return if (!requiresHumanTranscription()) {
                "rev.ai"
            } else if (useRevDotComForHumanTranscription) {
                "rev.com"
            } else {
                "rev.ai"
            }
        }

    companion object {
        const val HUMAN_LEVEL_FIDELITY = 100
    }
}

@Serializable
enum class TranscriptionJobStatus() {
    @SerialName("Queued")
    QUEUED,

    @SerialName("InProgress")
    IN_PROGRESS,

    @SerialName("Transcribed")
    TRANSCRIBED,

    @SerialName("Completed")
    COMPLETED,

    @SerialName("Canceled")
    CANCELED,

    @SerialName("Failed")
    FAILED;
}

@Serializable
data class PaginatedTranscriptionJobs(
    val jobs: List<TranscriptionJob>,
    val paginationToken: String? = null
)
