package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.*
import com.siriusxm.pia.client.api.client.ShowAuditEntry
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.boxMessage
import com.siriusxm.pia.components.table
import com.siriusxm.pia.utils.toLocalDateTimeString
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.fontSize
import org.jetbrains.compose.web.css.marginTop
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

@Composable
fun showAudit(showId: String) {
    var auditEntries by remember { mutableStateOf<List<ShowAuditEntry>?>(null) }

    LaunchedEffect(showId) {
        Podcasts.api.query {
            show(showId) {
                audit {
                    entries(null, 25) {
                        entries {
                            ts
                            message
                            updater {
                                identifier
                                email
                                name
                                uri
                            }
                            note
                        }
                    }
                }
            }
        }.show.audit.entries.entries.let {
            auditEntries = it
        }
    }

    box({
        paddedContent = false
        title = "Configuration Change Log"
    }) {
        if (auditEntries == null) {
//            boxSpinner()
        } else if (auditEntries.isNullOrEmpty()) {
            boxMessage("This show has no audit entries")
        } else {
            table<ShowAuditEntry> {
                items(auditEntries.orEmpty())
                column("Date") {
                    width = 175.px
                    content {
                        val instant = Instant.parse(it.ts)
                        val timeString = instant.toLocalDateTimeString()
                        Text(timeString)
                    }
                }

                column("Update") {
                    content {
                        Div {
                            Text(it.message)
                        }

                        it.note?.let { note ->
                            Div({ style { marginTop(5.px) } }) {
                                Div({ style { fontSize(11.px) } }) {
                                    Text(note)
                                }
                            }
                        }
                    }
                }

                column("Updated By") {
                    content { entry ->
                        Text(entry.updater?.name ?: entry.updater?.email ?: entry.updater?.uri ?: "-")
                    }
                }
            }
        }
    }
}