package com.siriusxm.pia.components

import androidx.compose.runtime.Composable
import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

object PillStyles : StyleSheet() {
    val pill by style {
        backgroundColor(SXMUI.containerContentBackgroundColor.value())
        padding(2.px, 1.em)
        borderRadius(20.px)
        fontSize(0.9.em)
        fontWeight(600)
        boxShadow("0 0 3px rgba(0,0,0,.2)")
        display(DisplayStyle.InlineBlock)
    }
}

@Composable
fun pill(name: String) {
    Span({
        classes(PillStyles.pill)
    }) {
        Text(name)
    }
}