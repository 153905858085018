package com.siriusxm.pia.views.podcasts

import androidx.compose.runtime.Composable
import com.siriusxm.pia.Application
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.client.api.client.Feed
import com.siriusxm.pia.client.api.client.Show
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.icon
import com.siriusxm.pia.components.iconAction
import com.siriusxm.pia.components.table
import com.siriusxm.pia.utils.encodeURIComponent
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

/**
 * Renders a view for navigating and working with a show's sources.
 */
@Composable
fun showSources(show: Show, sources: List<Feed>) {
    box({
        title = "Sources"
        paddedContent = false

        if (Application.viewer.contentEditor) {
            action("Add", true) {
                Podcasts.navigate(
                    "podcasts/feeds?action=add&showId=${show.id}&return=${
                        encodeURIComponent(
                            "podcasts/show/${show.id}/sources"
                        )
                    }"
                )
            }
        }
    }) {
        table<Feed> {
            items(sources)

            if (sources.any { !it.bundles.isNullOrEmpty() }) {
                column {
                    width = 50.px
                    content {
                        val bundleId = it.bundleId ?: it.bundles?.firstOrNull()?.id
                        if (bundleId != null) {
                            icon("lock")
                        }
                    }
                }
            }

            column {
                content { feed ->
                    Div {

                        A(href = "#podcasts/feeds/${encodeURIComponent(feed.url.orEmpty())}", {
                            style {
                                property("word-break", "break-all")
                            }
                        }) {
                            Text(feed.url ?: "-")
                        }

                        if (!feed.active) {
                            Span({
                                style {
                                    marginLeft(5.px)
                                    fontStyle("italic")
                                    color(SXMUI.subtleTextColor.value())
                                }
                            }) {
                                Text("(inactive)")
                            }
                        }
                    }
                }
            }

            if (Application.viewer.contentEditor) {
                column {
                    width = 100.px
                    content { feed ->
                        Div({
                            style {
                                textAlign("right")
                            }
                        }) {
                            iconAction("edit") {
                                Podcasts.push(
                                    "podcasts/feeds/${encodeURIComponent(feed.url!!)}?action=edit&return=${
                                        encodeURIComponent(
                                            "podcasts/show/${show.id}/sources"
                                        )
                                    }", "Edit Feed", true
                                )
                            }
                            iconAction("delete") {
                                Podcasts.push(
                                    "podcasts/feeds/${encodeURIComponent(feed.url!!)}?action=detach&return=${
                                        encodeURIComponent(
                                            "podcasts/show/${show.id}/sources"
                                        )
                                    }"
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}