package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.Composable
import com.siriusxm.pia.Application.cognito
import com.siriusxm.pia.ApplicationContext
import com.siriusxm.pia.ConsoleApplication
import com.siriusxm.pia.EntityAugmenter
import com.siriusxm.pia.rest.unifiedaggregator.UnifiedAggregatorClient
import com.siriusxm.pia.rest.unifiedaggregator.unifiedAggregatorClient
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.views.mddb.MddbAPI
import com.siriusxm.pia.views.mddb.MddbAugmenter

/**
 * The aggregator application
 */
class AggregatorApplication() : ConsoleApplication {
    lateinit var aggregatorClient: UnifiedAggregatorClient
    lateinit var context: ApplicationContext
    lateinit var augmenters: List<EntityAugmenter>

    override fun initialize(context: ApplicationContext) {
        aggregatorClient = unifiedAggregatorClient(context)
        this.context = context
        val mddbAPI = MddbAPI(context.configuration.mddbApiUrl) {
            cognito.accessToken!!
        }
        augmenters = listOf(
            MddbAugmenter(mddbAPI)
        )
    }

    @Composable
    override fun route(route: Route) {
        route.contentAggregator(context, aggregatorClient, augmenters)
    }
}