package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.asEntities
import com.siriusxm.pia.views.unifiedaggregator.smithy.SmithyStructEditor
import com.siriusxm.pia.views.unifiedaggregator.smithy.SmithyViewOptions
import com.siriusxm.pia.views.unifiedaggregator.smithy.SmithyViewerStyles
import com.siriusxm.smithy4kt.*
import com.siriusxm.unifiedcontent.entityTypeObj
import contentingestion.aggregator.*
import contentingestion.unifiedmodel.entityId
import kotlinx.coroutines.TimeoutCancellationException
import kotlinx.coroutines.delay
import kotlinx.coroutines.withTimeout
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.css.Style
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.seconds

/**
 * The list of producers that could apply this partial by this user.
 */
fun producersForPartial(
    app: AggregatorService, partialId: String,
    roles: ServiceUserMembershipList
): List<ProducerDetails> {
    val services = roles.map { it.serviceId }.distinct()
    return app.producers.filter {
        it.allowedPartials.orEmpty().contains(partialId)
    }.filter {
        services.contains(it.id)
    }
}

/**
 * An editor to submit partial updates.
 */
@Composable
fun partialEditor(
    app: AggregatorService,
    entityId: entityId,
    partialId: PartialId? = null
) {
    val roles by app.app.userRoles()
    var base by remember { mutableStateOf<com.siriusxm.pia.rest.unifiedaggregator.Entity?>(null) }

    // When base is not null, call availablePartials with the parameter from base.
    // Otherwise, fall back to an empty state.
    val availablePartials: State<List<PartialSchema>?> = if (base != null && roles != null) {
        app.app.availablePartials(base!!.data.entityTypeObj!!)
    } else {
        remember { mutableStateOf(null) }
    }

    var selectedPartial by remember { mutableStateOf<PartialSchema?>(null) }
    var availableProducers by remember { mutableStateOf<List<ProducerDetails>?>(null) }
    var submittingProducer by remember { mutableStateOf<ProducerDetails?>(null) }
    var schedule by remember { mutableStateOf<Instant?>(null) }

    // the modification that we'll be submitting.
    var modification by remember { mutableStateOf(buildJsonObject { }) }
    var isModificationValid by remember { mutableStateOf(false) }

    suspend fun submit() {
        if (!isModificationValid) {
            return
        }
        val selectedPartialId = selectedPartial?.id
        val producerId = submittingProducer?.id
        val path = selectedPartial?.paths?.firstOrNull()
        if (selectedPartialId != null && modification.isNotEmpty() && path != null && producerId != null) {
            try {
                val ts = Clock.System.now()
                val update = PartialUpdate(
                    ContentUpdateType.ADD,
                    Clock.System.now(),
                    modification.copy {
                        put("id", entityId)
                        put("type", base?.type)
                        put("version", base?.version)
                    },
                    selectedPartialId,
                    operations = listOf(
                        PartialUpdateOperation(
                            PartialUpdateOperationType.REPLACE,
                            path, path
                        )
                    ),
                    producer = producerId,
                    publishTs = schedule,
                    auditContext = AuditContext(
                        app.context.viewer.email
                    )
                )
                app.api.partialUpdate(update)

                // now we'll wait until we see this update
                try {
                    withTimeout(20.seconds) {
                        var found = false
                        while (!found) {
                            delay(1.seconds)
                            found = app.api.incomingEntity(
                                entityId,
                                base?.type!!,
                                base?.version!!,
                                "partials"
                            )?.partials?.any {
                                it.ts == ts
                            } ?: false
                        }
                    }
                } catch (e: TimeoutCancellationException) {
                    app.app.context.showError(
                        "The partial was published but hasn't been saved yet. It might take more time to propagate.",
                        e.message
                    )

                }
                app.navigate("entity/${entityId}/incoming")
            } catch (e: Throwable) {
                app.app.context.showError("Unable to publish partial", e.message)
            }
        }
    }

    /**
     * Load the base data of the entity
     */
    LaunchedEffect(entityId) {
        val loadedEntities = app.api.fetchEntityById(entityId).asEntities()
        base = loadedEntities.maxByOrNull { it.version }
    }

    /**
     * If a partial is provided, select it.
     */
    LaunchedEffect(partialId, availablePartials.value) {
        availablePartials.value?.find {
            it.id == partialId
        }?.let {
            selectedPartial = it
        }
    }

    /**
     * When the selected partial changes, get the list of producers for that partial
     * (for the viewer), to populate a select control (or just display if there is
     * only one).
     */
    LaunchedEffect(selectedPartial) {
        if (selectedPartial != null) {
            val producers = producersForPartial(app, selectedPartial?.id!!, roles?.serviceMemberships().orEmpty())
            if (producers.size == 1) {
                submittingProducer = producers.first()
            } else {
                submittingProducer = null
            }
        } else {
            availableProducers = null
            submittingProducer = null
        }
    }

    waitUntilNonNull(availablePartials.value) { partials ->
        dialogView(base?.name ?: "") {
            listOfNotNull("Update")

            breadcrumbs {
                crumb("Aggregator", "aggregator")
                crumb("Entities", "aggregator/entity")
                base?.name?.let {
                    crumb(it, "aggregator/entity/${entityId}")
                }
            }

            action {
                title = "Cancel"
                action {
                    app.navigate("entity/${entityId}")
                }
            }

            action {
                title = "Reset"
                action {
                    modification = buildJsonObject { }
                    schedule = null
                }
            }
            action {
                title = "Submit"
                enabled = modification.isNotEmpty() && isModificationValid
                primary = true
                showProgressOnAction = true
                action {
                    submit()
                }
            }

            content {
                if (partials.isNotEmpty()) {
                    // only allow selection of a partial if it wasn't provided
                    if (partialId == null) {
                        dialogField("Partial") {
                            if (partials.size == 1) {
                                Text(partials.first().let { it.name ?: it.id })
                            } else {
                                simpleSelectNullable(selectedPartial, partials.map {
                                    (it.name ?: it.id) to it
                                }) {
                                    selectedPartial = it
                                }
                            }
                        }
                    }


                    val partial = selectedPartial
                    if (partial != null) {
                        val producers = producersForPartial(app, partial.id, roles?.serviceMemberships().orEmpty())
                        dialogField("Publish As") {
                            if (producers.size == 1) {
                                Text(producers.first().name ?: producers.first().id)
                            } else {
                                simpleSelectNullable(submittingProducer, producers.map {
                                    (it.name ?: it.id) to it
                                }) {
                                    submittingProducer = it
                                }
                            }
                        }

                        if (submittingProducer != null) {
                            dialogField(
                                "Schedule (Your local time)",
                                instruction = "Leave blank to publish immediately"
                            ) {
                                instantPicker(schedule) {
                                    schedule = it
                                }
                            }

                            val allowedPaths = partial.paths
                            val shapeId = base?.type?.let {
                                app.entityType(it)
                            }?.shapeId

                            val entityShape = if (shapeId != null) {
                                app.entityModel?.getShape(shapeId)
                            } else null

                            if (entityShape != null) {
                                dialogField("Updates") {
                                    allowedPaths.forEach { jsonPath ->
                                        partialShapeEditor(
                                            base!!.data,
                                            modification,
                                            jsonPath,
                                            entityShape
                                        ) { mod, isValid ->
                                            modification = mod
                                            isModificationValid = isValid
                                        }
                                    }
                                }
                            }
                        }
                    }

                } else {
                    messageBox("You do not have enough permissions to publish a partial update.")
                }
            }
        }
    }
}

/**
 * The root of the partial editor control. Delegates most work to the SmithyView hierarchy.
 */
@Composable
fun partialShapeEditor(
    entity: JsonElement, modification: JsonObject, jsonPath: String, rootShape: SmithyShape,
    onUpdate: (JsonObject, Boolean) -> Unit
) {
    Style(SmithyViewerStyles)

    box({
        paddedContent = false
    }) {
        val rootOfEdits = rootShape.resolveJsonPath(jsonPath)
        if (rootOfEdits is SmithyMember) {
            val parent = rootOfEdits.parent
            if (parent is SmithyStructure) {
                val baseData = entity.querySingle(jsonPath)
                val editor = SmithyStructEditor(parent).apply {
                    includeFields(listOf(rootOfEdits.name))

                    this.onUpdate = { updatedValue ->
                        val editValue = updatedValue?.jsonObject?.get(rootOfEdits.name)
                        val isValid = try {
                            editValue?.let {
                                rootOfEdits.validate(it, true)
                                true
                            } ?: false
                        } catch (e: Throwable) {
                            false
                        }

                        onUpdate(updatedValue as? JsonObject ?: buildJsonObject { }, isValid)
                    }
                }
                editor.edit(buildJsonObject {
                    if (baseData != null) {
                        put(rootOfEdits.name, baseData)
                    }
                }, modification, SmithyViewOptions())
            }
        }
    }
}