package com.siriusxm.pia.components

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H2
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text

/**
 * Attributes for the dialog overlay
 */
interface DialogOverlayAttributes {
    /**
     * The main title of the dialog
     */
    var title: String?

    /**
     * Called when closing the dialog
     */
    fun onClose(cb: suspend (Boolean) -> Unit)

    /**
     * Install a content rendering function
     */
    fun content(block: @Composable () -> Unit)

    /**
     * Install a callback to save the content of the dialog.
     */
    fun save(block: () -> Unit)

    /**
     * Install an action button
     */
    fun action(config: ButtonConfiguration.() -> Unit)
}

/**
 * Render a dialog overlay.
 */
@Composable
fun dialogOverlay(attrs: @Composable DialogOverlayAttributes.() -> Unit = {}) {
    var onClose: suspend (Boolean) -> Unit = {}
    var content: @Composable () -> Unit = {}
    var save: (() -> Unit)? = null
    val actions = mutableListOf<ButtonConfiguration.() -> Unit>()
    val attributes = object : DialogOverlayAttributes {
        override var title: String? = null
        override fun onClose(cb: suspend (Boolean) -> Unit) {
            onClose = cb
        }

        override fun content(block: @Composable () -> Unit) {
            content = block
        }

        override fun save(block: () -> Unit) {
            save = block
        }

        override fun action(config: ButtonConfiguration.() -> Unit) {
            actions += config
        }
    }
    attributes.attrs()

    Div({
        style {
            display(DisplayStyle.Block)
            position(Position.Fixed)
            top(0.px)
            bottom(0.px)
            right(0.px)
            left(0.px)
            backgroundColor(rgba(35, 43, 55, 0.7))
        }
    }) {
        Div({
            style {
                display(DisplayStyle.Block)
                width(100.percent)
                maxWidth(820.px)
                margin(100.px)
                property("margin-left", "auto")
                property("margin-right", "auto")
                backgroundColor(SXMUI.containerContentBackgroundColor.value())
                border(1.px, LineStyle.Solid, SXMUI.tabsBorder.value())
                borderRadius(SXMUI.boxRadius.value())
                overflow("hidden")
            }
        }) {
            Div({
                style {
                    padding(16.px, 20.px)
                    position(Position.Relative)
                    backgroundColor(SXMUI.containerHeaderBackgroundColor.value())
                    border(0.px, 0.px, 1.px, 0.px, SXMUI.defaultDivider.value())
                }
            }) {
                attributes.title?.let { title ->
                    H2({
                        style {
                            margin(0.px)
                        }
                    }) {
                        Text(title)
                    }
                }

                Span({
                    style {
                        position(Position.Absolute)
                        top(4.px)
                        right(4.px)
                    }
                }) {
                    icon("close") {
                        action {
                            onClose(false)
                        }
                    }
                }
            }

            Div {
                content()
            }

            Div({
                style {
                    borderTop()
                    padding(12.px, 20.px)
                    textAlign("right")
                    backgroundColor(SXMUI.containerHeaderBackgroundColor.value())
                }
            }) {
                button("Close", false) {
                    onClose(false)
                }

                actions.forEach {
                    button {
                        it()
                    }
                }

                save?.let { save ->
                    button("Save", true) {
                        save()
                        onClose(false)
                    }
                }
            }
        }
    }
}

/**
 * Display an icon that, when clicked, displays a dialog.
 */
@Composable
fun dialogIcon(icon: String = "settings", config: @Composable DialogOverlayAttributes.() -> Unit) {
    var showDialog by remember { mutableStateOf(false) }

    icon(icon) {
        action {
            showDialog = true
        }
    }
    if (showDialog) {
        dialogOverlay {
            config.invoke(this)
            onClose {
                showDialog = false
            }
        }
    }
}


/**
 * Display a confirmation dialog that requires the viewer to enter some confirmation text.
 * @param cb if called with true, the action should be completed, otherwise, just close the dialog.
 */
@Composable
fun confirmationDialog(
    title: String, message: String,
    action: String,
    confirmationText: String,
    cb: suspend (Boolean) -> Unit
) {
    var confirmation by remember { mutableStateOf("") }
    dialogOverlay {
        this.title = title
        this.action {
            primary = true
            this.title = action
            enabled = confirmation == confirmationText
            showProgressOnAction = true
            action {
                cb(true)
            }
        }
        this.content {
            Div({
                style {
                    padding(10.px)
                    display(DisplayStyle.Flex)
                    flexDirection(FlexDirection.Column)
                    gap(10.px)
                }
            }) {
                Text(message)
                simpleTextField(confirmation) {
                    confirmation = it.orEmpty()
                }
            }
        }
        onClose {
            cb(false)
        }
    }
}