package com.siriusxm.pia.views.unifiedaggregator.smithy

import androidx.compose.runtime.*
import com.siriusxm.pia.components.HeaderSize
import com.siriusxm.pia.components.box
import com.siriusxm.pia.rest.unifiedaggregator.decodeImages
import com.siriusxm.pia.rest.unifiedaggregator.flatten
import com.siriusxm.pia.views.unifiedaggregator.AggregatorStyles
import com.siriusxm.pia.views.unifiedaggregator.resizedUrl
import com.siriusxm.smithy4kt.SmithyEntity
import contentingestion.unifiedmodel.Image
import contentingestion.unifiedmodel.LegacyImage
import contentingestion.unifiedmodel.LegacyImageList
import kotlinx.serialization.builtins.ListSerializer
import kotlinx.serialization.json.JsonElement
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.css.keywords.auto
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Img
import org.jetbrains.compose.web.dom.Text


/**
 * Renders an image purposes map
 */
class ImagePurposeMapViewer(shape: SmithyEntity) : SmithyViewer<SmithyEntity>(shape) {
    @Composable
    override fun view(value: JsonElement?, options: SmithyViewOptions) {
        val flattened = value?.decodeImages()?.flatten() ?: return

        val imageGroups = flattened.groupBy {
            it.imageVariantType
        }

        Div({
            style {
                paddingTop(10.px)
                paddingRight(10.px)
            }
        }) {
            imageGroups.forEach { (type, flattenedImages) ->
                Div {
                    box(type.name, {
                        header({
                            titleSize = HeaderSize.SMALL
                        }, null)
                    }) {
                        Div {
                            flattenedImages.forEach {
                                expandableImageContainer(
                                    it.image.resizedUrl(),
                                    "${it.purpose} ${it.ratio.name.removePrefix("aspect_")}"
                                )
                            }
                        }
                    }
                }
            }
        }
    }
}

class ImageViewer(shape: SmithyEntity) : CustomTypeRenderer<Image>(shape, Image.serializer()) {
    @Composable
    override fun viewType(value: Image?, options: SmithyViewOptions) {
        if (value == null) return

        Div({ classes(AggregatorStyles.imageContainer) }) {
            Img(value.resizedUrl())
        }
    }
}

@Composable
fun expandableImageContainer(url: String, label: String? = null, title: String? = null) {
    Div({ classes(AggregatorStyles.imageBox) }) {
        var showingFull by remember { mutableStateOf(false) }

        Div({ classes(AggregatorStyles.imageContainer) }) {
            Img(url) {
                onClick {
                    showingFull = true
                }
            }
        }
        if (label != null) {
            Div({ classes(AggregatorStyles.imageLabel) }) {
                Text(label)
            }
        }

        if (showingFull) {
            Div({
                style {
                    position(Position.Fixed)
                    top(0.px)
                    left(0.px)
                    bottom(0.px)
                    right(0.px)
                    width(100.percent)
                    padding(20.px)
                    display(DisplayStyle.Flex)
                    justifyContent(JustifyContent.Center)
                    alignItems(AlignItems.Center)
                    backgroundColor(rgba(0, 0, 0, .8))
                    property("z-index", "100")
                }
                onClick {
                    showingFull = false
                }
            }) {
                Img(url) {
                    if (title != null) {
                        title(title)
                    }
                    style {
                        background("repeating-conic-gradient(#ccc 0% 25%, #fff 0% 50%) 50% 50% / 20px 20px")
                        width(auto)
                        height(auto)
                        maxWidth(100.percent)
                        maxHeight(100.percent)
                    }
                }
            }
        }
    }
}

class LegacyImagesViewer(shape: SmithyEntity) :
    CustomTypeRenderer<LegacyImageList>(shape, ListSerializer(LegacyImage.serializer())) {
    @Composable
    override fun viewType(value: LegacyImageList?, options: SmithyViewOptions) {

        if (value == null) return
        Div {
            value.forEach {
                expandableImageContainer(it.url, it.name)
            }
        }


    }
}