package com.siriusxm.pia.views

import androidx.compose.runtime.Composable
import com.siriusxm.pia.Application
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.border
import com.siriusxm.pia.components.box
import com.siriusxm.pia.components.serviceContentView
import com.siriusxm.pia.components.serviceView
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Text

object HomeStyles : StyleSheet() {
    val applicationList by style {

        backgroundColor(SXMUI.containerContentBackgroundColor.value())
    }

    val application by style {
        padding(1.cssRem)
        border(bottom = 1.px, color = SXMUI.borderDividerDefault.value())
        cursor("pointer")

        type("h4") style {
            fontSize((1.2).em)
            fontWeight(700)
            margin(0.px, 0.px, 4.px, 0.px)
        }

        self + hover style {
            backgroundColor(SXMUI.borderDividerDefault.value())
        }
    }

    val applicationDescription by style {
        color(SXMUI.subtleTextColor.value())
        fontSize((0.9).cssRem)
    }
}

@Composable
fun application(application: Application, name: String, description: String?, path: String) {
    Div({
        classes(HomeStyles.application)
        onClick {
            application.navigate(path)
        }
    }) {
        A("/#${path}") {
            H4 {
                Text(name)
            }
        }
        if (description != null) {
            Div({ classes(HomeStyles.applicationDescription) }) {
                Text(description)
            }
        }
    }
}


@Composable
fun homeDashboard(application: Application) {
    serviceView {
        serviceContentView {
            Div({
                style {
                    maxWidth(600.px)
                }
            }) {
                box({
                    title = "Applications"
                    paddedContent = false
                }) {
                    applicationsList(application)
                }
            }
        }
    }
}

@Composable
fun applicationsList(application: Application) {
    Div({ classes(HomeStyles.applicationList) }) {
        application.configuration.applications?.forEach {
            val allowed = it.requiredGroup.isNullOrEmpty() ||
                    it.requiredGroup?.any {
                        application.viewer.isInGroup(it)
                    } ?: false
            if (allowed) {
                application(application, it.name, it.description, it.path ?: "")
            }
        }
    }
}