package com.siriusxm.pia.views.sports

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.ResizeParams
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.channelguide.aggregatorIconLink
import com.siriusxm.unifiedcontent.sports.Airing
import com.siriusxm.unifiedcontent.sports.CompetitionParticipant
import com.siriusxm.unifiedcontent.sports.CompetitionParticipantStatus
import com.siriusxm.unifiedcontent.sports.CompetitiveEvent
import contentingestion.unifiedmodel.BoxScore
import contentingestion.unifiedmodel.ExtendedMetadata
import contentingestion.unifiedmodel.Score
import contentingestion.unifiedmodel.ScoreState
import kotlinx.coroutines.delay
import kotlinx.datetime.internal.JSJoda.Duration
import kotlinx.datetime.internal.JSJoda.Instant
import kotlinx.datetime.toInstant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.*
import kotlin.time.Duration.Companion.seconds

data class BoxScoreRow(
    val teamId: String,
    val teamName: String,
    val imageUrl: String?,
    val periodValues: List<String>,
    val finalValue: String
)

@Composable
fun scoresForEvent(app: SportsApplication, eventId: String, autoPolling: Boolean = false, returnUrl: String? = null) {
    var event by remember { mutableStateOf<CompetitiveEvent?>(null) }
    var homeTeam by remember { mutableStateOf<CompetitionParticipant?>(null) }
    var awayTeam by remember { mutableStateOf<CompetitionParticipant?>(null) }
    var entities by remember { mutableStateOf<List<Score>?>(null) }
    var polling by remember { mutableStateOf(autoPolling) }

    LaunchedEffect(eventId) {
        event = app.client.competitionEvent(eventId)

        if (event?.state == ScoreState.IN_PROGRESS) {
            polling = true
        }

        if (event != null) {
            homeTeam = event?.homeTeam()
            awayTeam = event?.awayTeam()
        }
        entities = app.client.competitionScoreEvents(eventId).entities

        if (polling) {
            do {
                delay(10.seconds)

                // in case the event changed state
                event = app.client.competitionEvent(eventId)

                // in case we have any new scores
                entities = app.client.competitionScoreEvents(eventId).entities

            } while (polling && !eventIsFinal(event, entities))
            polling = false

            // fetch one more time for good measure
            event = app.client.competitionEvent(eventId)
        }
    }

    fun toBoxScoreRows(
        homeTeam: CompetitionParticipant,
        awayTeam: CompetitionParticipant,
        boxScore: BoxScore,
        homeFinal: Int?,
        awayFinal: Int?
    ): List<BoxScoreRow> {
        val rows = mutableListOf<BoxScoreRow>()
        rows.add(
            BoxScoreRow(
                homeTeam.id,
                getTeamName(homeTeam),
                getPreferredTeamImage(homeTeam)?.let {
                    getAbsoluteUrl(
                        app.context,
                        it,
                        resizeParams = ResizeParams(128, 128)
                    )
                },
                boxScore.periods?.map { it.homeScore?.points?.toString() ?: "" } ?: listOf(),
                homeFinal?.toString() ?: ""))

        rows.add(
            BoxScoreRow(
                awayTeam.id,
                getTeamName(awayTeam),
                getPreferredTeamImage(awayTeam)?.let {
                    getAbsoluteUrl(
                        app.context,
                        it,
                        resizeParams = ResizeParams(128, 128)
                    )
                },
                boxScore.periods?.map { it.awayScore?.points?.toString() ?: "" } ?: listOf(),
                awayFinal?.toString() ?: ""))

        return rows
    }

    @Composable
    fun boxScoreItem(score: Score, homeTeam: CompetitionParticipant, awayTeam: CompetitionParticipant) {
        Div {
            val boxScore: BoxScore? =
                score.extendedMetadata?.baseball?.boxScore ?: score.extendedMetadata?.basketball?.boxScore
                ?: score.extendedMetadata?.football?.boxScore ?: score.extendedMetadata?.hockey?.boxScore
            if (boxScore != null) {
                val boxScoreRows = toBoxScoreRows(homeTeam, awayTeam, boxScore, score.homeScore, score.awayScore)
                val columnTitles = boxScore.periods?.map { it.periodName }
                boxTable<BoxScoreRow> {
                    items(boxScoreRows)
                    column {
                        content {
                            Div({
                                style {
                                    display(DisplayStyle.Flex)
                                    flexDirection(FlexDirection.Row)
                                    alignItems(AlignItems.Center)
                                }
                            }) {
                                Div({ classes(SportsStyles.metadata) }) {
                                    Div({ classes(SportsStyles.entityInfo) }) {
                                        A("#sports/team/${it.teamId}") {
                                            Text(it.teamName)
                                        }
                                    }
                                }
                            }
                        }
                    }

                    columnTitles?.forEachIndexed { index, columnName ->
                        column(title = columnName) {
                            content {
                                Div({ classes(SportsStyles.entityInfo) }) { Text(it.periodValues.get(index)) }
                            }
                        }
                    }

                    if (score.state == ScoreState.FINAL) {
                        // Spacing between period scores and final score
                        column("") { }

                        column("F") {
                            content {
                                Div({ classes(SportsStyles.entityInfo) }) { Text(it.finalValue) }
                            }
                        }
                    }
                }
            }
        }
    }

    @Composable
    fun participantStatus(participantStatus: CompetitionParticipantStatus) {
        Div {
            Text(participantStatus.competitor.name)
        }
        Div {
            Text(participantStatus.value?.toString() ?: "")
        }
    }

    @Composable
    fun scoreItem(score: Score, liveStart: Instant?) {
        Div({
            style {
                display(DisplayStyle.Flex)
                flexDirection(FlexDirection.Row)
                marginLeft(10.px)
                padding(10.px)
                alignItems(AlignItems.Center)
            }
        }) {
            Div({
                style {
                    width(120.px)
                    fontWeight("bold")
                }
            }) {
                Text(score.state?.name ?: "")
                aggregatorIconLink(score.id, IconSize.TINY, true, {})
            }
            Div({ classes(SportsStyles.metadata) }) {
                if (score.eventHasStarted()) {
                    Div({ classes(SportsStyles.entityInfo) }) {
                        val period = getPeriod(score.period ?: "")
                        val outs: Int? = score.extendedMetadata?.baseball?.outs
                        val periodDetail = if (outs != null) {
                            "$period - $outs Outs"
                        } else {
                            period
                        }
                        Text(periodDetail)
                    }
                    Div({ classes(SportsStyles.entityInfo) }) {
                        Text(score.homeScore?.toString() ?: "")
                    }
                    Div({ classes(SportsStyles.entityInfo) }) {
                        Text(score.awayScore?.toString() ?: "")
                    }
                    if (score.timestamp != null) {
                        val timeDiff = getTimeDiff(liveStart, Instant.parse(score.timestamp!!))
                        val text = if (timeDiff != null) {
                            "${score.timestamp!!} - $timeDiff"
                        } else {
                            score.timestamp!!
                        }
                        Div({ classes(SportsStyles.entitySubInfo) }) {
                            Text(text)
                        }
                    }
                } else {
                    Div({ classes(SportsStyles.entitySubInfo) }) {
                        Text(score.timestamp?.toInstant()?.toLocalDateTimeString() ?: "")
                    }
                }
            }
        }
    }

    serviceContentView({
//        title = event.name()
//        subTitle = event?.state?.name ?: ""
//
        actionArea {
            aggregatorIconLink(eventId, IconSize.SMALL, showOnlyOnHover = false)
        }

        breadcrumbs {
            crumb("Sports", "sports")
            val leagueId = event?.participants()?.map { it.league }?.firstOrNull()
            val league = leagueId?.let { app.leagueById(it) }
            if (league != null) {
                crumb(league.name, "sports/leagues/${league.id}")
            }
        }

    }) {
        if (polling) {
            button {
                title = "Stop Polling"
                action {
                    polling = false
                }
            }
        }

        if (event == null && entities?.isEmpty() == true) {
            messageBox(
                "No entity with id $eventId was found. When an entity is removed, the reference is maintained for 60 days, after which is it completely removed from the system",
                MessageType.WARNING
            )
        } else if (event == null) {
            spinner(size = Size.LARGE)
        } else {
            val latestScore = entities?.latestScoreWithBoxScore()
            if (homeTeam != null && awayTeam != null) {
                Div({
                    style {
                        display(DisplayStyle.Flex)
                    }
                }) {
                    @Composable
                    fun participantHeader(participant: CompetitionParticipant) {
                        Div({
                            style {
                                flex(1)
                                textAlign("center")
                            }
                        }) {
                            // there is no box score, so we can render something static
                            val imageUrl =
                                getPreferredTeamImage(participant)?.let { getAbsoluteUrl(app.context, it) }
                                    ?: "images/blank.png"
                            Div {
                                A("#sports/team/${participant.id}") {
                                    Img(imageUrl) {
                                        style {
                                            width(128.px)
                                            height(128.px)
                                        }
                                    }
                                }
                            }
                            Div {
                                A("#sports/team/${participant.id}") {
                                    H3({
                                        style {
                                            fontSize(32.px)
                                            fontWeight(800)
                                        }
                                    }) {
                                        Text(participant.name)
                                    }
                                }
                            }
                        }
                    }

                    participantHeader(awayTeam!!)
                    Div({
                        style {
                            textAlign("center")
                        }
                    }) {
                        Div({
                            style {
                                fontSize(32.px)
                                fontWeight(700)
                                marginTop(50.px)
                                marginBottom(20.px)
                            }
                        }) {
                            Text("@")
                        }
                        Div {
                            event?.state?.name?.let { Text(it) }
                        }
                    }
                    participantHeader(homeTeam!!)
                }
            }

            if (latestScore != null && homeTeam != null && awayTeam != null) {
                boxScoreItem(score = latestScore, homeTeam!!, awayTeam!!)
            }

            val liveStart = entities?.firstOrNull { it.state == ScoreState.IN_PROGRESS }?.timestamp?.let { timestamp ->
                Instant.parse(timestamp)
            }

            val airings = event?.airings()
            if (airings != null) {
                boxTable<Airing> {
                    items(airings.sortedBy { it.channelNumber })
                    column("Airings") {
                        content {
                            Text(it.channelName ?: "")
                        }
                    }
                    column("Channel Number") {
                        content {
                            Text(it.channelNumber?.toString() ?: "")
                        }
                    }
                    column("Coverage") {
                        content {
                            Text(it.coverageType.name)
                        }
                    }
                    column {
                        content {
                            A(href = "#channelguide/channels/${it.channelId}") {
                                icon("lists") {
                                    size = IconSize.SMALL
                                }
                            }
                            aggregatorIconLink(it.channelId, IconSize.SMALL, true, {})
                        }
                    }

                }
            }

            if (entities?.isNotEmpty() == true) {
                boxTable {
                    items(entities?.sortedByDescending { it.timestamp })
                    column {
                        content { score ->
                            scoreItem(score, liveStart)
                        }
                    }
                }
            } else {
                val scores = event!!.status?.scores
                boxTable {
                    items(scores)
                    column {
                        content { participantStatus ->
                            participantStatus(participantStatus)
                        }
                    }
                }
            }
        }
    }
}

private fun Score.eventHasStarted(): Boolean {
    return !listOf(ScoreState.SCHEDULED, ScoreState.PRE_GAME).contains(this.state)
}

fun CompetitiveEvent?.airings(): List<Airing>? {
    // deal with the fact that sometimes we get duplicates for the same channel id
    return this?.airings?.distinctBy { it.channelId }?.let {
        if (it.isEmpty()) {
            null
        } else {
            it
        }
    }
}

fun CompetitiveEvent?.homeTeam(): CompetitionParticipant? {
    val homeTeamId = this?.host
    if (homeTeamId != null) {
        this?.status?.scores?.forEach {
            if (it.competitor.id == homeTeamId) {
                return it.competitor
            }
        }
        return null
    } else {
        return this?.status?.scores?.get(1)?.competitor
    }
}

fun CompetitiveEvent?.awayTeam(): CompetitionParticipant? {
    val homeTeamId = this?.host
    if (homeTeamId != null) {
        this?.status?.scores?.forEach {
            if (it.competitor.id != homeTeamId) {
                return it.competitor
            }
        }
        return null
    } else {
        return this?.status?.scores?.first()?.competitor
    }
}

fun CompetitiveEvent?.name(): String {
    if (this == null) return ""
    return "${awayTeam()?.name} @ ${homeTeam()?.name}"
}

fun eventIsFinal(event: CompetitiveEvent?, entities: List<Score>?): Boolean {
    if (entities == null) return false
    if (event == null) return false

    if (event.state == ScoreState.FINAL) {
        return true
    }
    return entities.any { it.state == ScoreState.FINAL }
}


fun CompetitiveEvent?.live(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.IN_PROGRESS).contains(this.state)
}

fun CompetitiveEvent?.completed(): Boolean {
    if (this == null) return false

    if (this.state == ScoreState.FINAL || this.state == ScoreState.TERMINATED) {
        return true
    }
    return false
}

fun CompetitiveEvent?.upcoming(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.PRE_GAME, ScoreState.SCHEDULED, ScoreState.DELAYED).contains(this.state)
}

fun CompetitiveEvent?.liveAndUpcoming(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.PRE_GAME, ScoreState.IN_PROGRESS).contains(this.state)
}

fun CompetitiveEvent?.future(): Boolean {
    if (this == null) return false
    return listOf(ScoreState.SCHEDULED, ScoreState.DELAYED).contains(this.state)
}

private fun getTimeDiff(liveStart: Instant?, current: Instant): String? {
    return if (liveStart != null) {
        val duration = Duration.between(liveStart, current)
        if (duration.isNegative()) {
            null
        } else {
            duration.asString()
        }
    } else {
        null
    }
}

private fun Duration.asString(): String {
    val hours = toHours().toString().padStart(2, '0')
    val minutes = (toMinutes().toInt() % 60).toString().padStart(2, '0')
    val seconds = (seconds().toInt() % 60).toString().padStart(2, '0')

    return "$hours:$minutes:$seconds"
}

private fun List<Score>.latestScoreWithBoxScore(): Score? {
    return this.sortedByDescending { it.timestamp }.firstOrNull { it.extendedMetadata?.boxScore() != null }
}

private fun ExtendedMetadata?.boxScore(): BoxScore? {
    return this?.hockey?.boxScore ?: this?.baseball?.boxScore ?: this?.basketball?.boxScore ?: this?.football?.boxScore
}

private fun getPeriod(period: String): String {
    val parsedPeriod = parsePeriod(period)
    val num = parsedPeriod.second.toString()
    val ordinal = getOrdinal(parsedPeriod.second)

    return when (parsedPeriod.first) {
        "q" -> "$ordinal Quarter"
        "ht" -> "Halftime"
        "numot" -> "${num}OT"
        "f" -> "Final"
        "fot" -> "Final/${num}OT"
        "p" -> "$ordinal Period"
        "i" -> "$ordinal Intermission"
        "so" -> "Shootout"
        "fso" -> "Final/Shootout"
        "h" -> "$ordinal Half"
        "t" -> "Top $ordinal"
        "m" -> "Middle $ordinal"
        "b" -> "Bottom $ordinal"
        "e" -> "End $ordinal"
        "fnum" -> "Final/$num"
        else -> period
    }
}

private fun parsePeriod(input: String): Pair<String, Int> {
    var letters = ""
    var number = 0

    input.forEach { char ->
        if (char.isDigit()) {
            number = number * 10 + char.digitToInt()
        } else if (char.isLetter()) {
            letters += char
        }
    }

    // Return a Pair containing the letters and the number
    return Pair(mapKey(letters, number), number)
}

private fun mapKey(key: String, num: Int): String {
    return when {
        key == "OT" -> "numot"
        key == "F" && num > 0 -> "fnum"
        else -> key.lowercase()
    }
}

private fun getOrdinal(number: Int): String {
    val suffix = when {
        number % 100 in 11..13 -> "th" // Special case for 11th, 12th, 13th
        number % 10 == 1 -> "st"       // 1st, 21st, 31st, etc.
        number % 10 == 2 -> "nd"       // 2nd, 22nd, 32nd, etc.
        number % 10 == 3 -> "rd"       // 3rd, 23rd, 33rd, etc.
        else -> "th"                   // 4th, 5th, 6th, etc.
    }
    return "$number$suffix"
}