package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.SXMUI
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.*
import com.siriusxm.pia.utils.toLocalDateTimeString
import com.siriusxm.pia.views.unifiedaggregator.smithy.*
import com.siriusxm.smithy4kt.SmithyMember
import com.siriusxm.smithy4kt.query
import com.siriusxm.smithy4kt.resolveJsonPath
import contentingestion.aggregator.AuditContext
import contentingestion.aggregator.ContentUpdateType
import contentingestion.aggregator.EventQueryOption
import contentingestion.aggregator.PartialUpdate
import kotlinx.coroutines.delay
import kotlinx.serialization.json.*
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.H4
import org.jetbrains.compose.web.dom.Span
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours

/**
 * Styles for the events view
 */
object EntityEventsStyles : StyleSheet() {
    val eventsHeader by style {
        display(DisplayStyle.Flex)
        width(100.percent)
        padding(5.px, 0.px)
        gap(5.px)
    }

    val loadingBlock by style {
        display(DisplayStyle.Flex)
        flex(1)
        gap(5.px)
        padding(5.px)
        alignItems(AlignItems.Center)
        fontWeight(700)
    }
}

val eventPeriods = listOf(
    Period("1d", 24.hours, 1440),
    Period("3d", 3.days, 4320),
    Period("1w", 7.days, 10080),
    Period("2w", 12.days, 20160),
    Period("1mo", 31.days, 43200),
    Period("3mo", 93.days, 129600),
)

fun getHumanReadableEventType(event: CloudEvent): String {
    return when (event.type) {
        "Content Update" -> {
            val updateType = event.tracingcontext?.getString("updateType")?.let {
                ContentUpdateType.fromName(it)
            }
            when (updateType) {
                ContentUpdateType.ADD -> "Add Request"
                ContentUpdateType.REMOVE -> "Remove Request"
                ContentUpdateType.UPDATE -> "Update Request"
                else -> "Unknown Content Request"
            }
        }

        "Content Update Partial" -> {
            val updateType = event.data?.jsonObject?.getString("type")?.let {
                ContentUpdateType.fromName(it)
            }
            when (updateType) {
                ContentUpdateType.ADD -> "Partial Add Request"
                ContentUpdateType.REMOVE -> "Partial Remove Request"
                ContentUpdateType.UPDATE -> "Partial Update Request"
                else -> "Unknown Partial Request"
            }
        }

        "com.siriusxm.unifiedcontentbus.add" -> "Add Published"
        "com.siriusxm.unifiedcontentbus.update" -> "Update Published"
        "com.siriusxm.unifiedcontentbus.remove" -> "Remove Published"
        else -> event.type
    }
}

/**
 * Displays events for an entity.
 */
@Composable
fun entityEvents(aggregatorContext: AggregatorService, entityId: String) {
    var loading by remember { mutableStateOf(false) }
    var result by remember { mutableStateOf<EventSearchStatus?>(null) }
    var failureMessage by remember { mutableStateOf<String?>(null) }
    var expanded by remember { mutableStateOf<Set<String>>(emptySet()) }

    var eventsPeriod by remember { mutableStateOf<Period?>(null) }

    var onlyShowDiffs by remember { mutableStateOf(false) }
    var cloudEventChangeMap by remember { mutableStateOf<Map<String, CloudEventChanges>?>(null) }
    var queryType by remember { mutableStateOf<EventQueryOption>(EventQueryOption.Output) }

    Style(EntityEventsStyles)

    LaunchedEffect(entityId, eventsPeriod, queryType) {
        if (eventsPeriod != null) {
            failureMessage = null
            loading = true
            result = null
            val startTime = eventsPeriod!!.start()
            var query = aggregatorContext.api.entityEvents(entityId, null, startTime, queryType)
            try {
                while (query.state == SearchStatusState.IN_PROGRESS) {
                    delay(500)
                    query = aggregatorContext.api.entityEvents(entityId, query.id, startTime)
                    result = query
                }

                if (query.state == SearchStatusState.COMPLETED) {
                    result = query
                }

            } catch (e: Throwable) {
                failureMessage = e.message ?: "The event search failed for an unknown reason."
            }
            // after we have loaded, we want to save off the processing of the diffs
            val cloudEventChangesMap = mutableMapOf<String, CloudEventChanges>()
            val publishedCloudEvents =
                result?.results.orEmpty().filter { getHumanReadableEventType(it).contains("Published") }
            publishedCloudEvents.windowed(size = 2, step = 1) { it[0] to it[1] }.forEach {
                val cloudEventChanges = getCloudEventChanges(it.first, it.second)
                if (cloudEventChanges.changedKeys.isNotEmpty()) {
                    cloudEventChangesMap[it.first.id] = cloudEventChanges
                    console.log(it.first.id + " ", cloudEventChanges)
                }
            }

            cloudEventChangeMap = cloudEventChangesMap
            loading = false
        }
    }

    Div({
        classes(EntityEventsStyles.eventsHeader)
    }) {
        Div({
            classes(EntityEventsStyles.loadingBlock)
        }) {
            if (loading) {
                spinner()
                Text("Loading events...")
            }
        }

        if (eventsPeriod != null && result?.results?.isNotEmpty() == true && queryType == EventQueryOption.Output) {
            Div({
                classes(AggregatorStyles.entitySubInfo)
            }) {
                simpleCheckBox("Only show differences", onlyShowDiffs) {
                    onlyShowDiffs = it
                }
            }
        }

        Div {
            simpleSelect(
                queryType, listOf(
                    "Outgoing Events" to EventQueryOption.Output,
                    "Incoming Events" to EventQueryOption.Incoming
                )
            ) {
                queryType = it
            }
        }

        Div {
            timeRangeSelect(periods = eventPeriods.map {
                RelativeDuration(it.name, it.duration)
            }, selected = TimeRange(eventsPeriod?.duration)) { range ->
                eventsPeriod = eventPeriods.find { it.duration == range.relative }
            }
        }
    }

    box({
        paddedContent = false
    }) {
        if (failureMessage != null) {
            boxMessage(failureMessage!!)
        } else if (eventsPeriod == null) {
            boxMessage("Please select a time period.")
        }

        val totalResultCount = result?.results.orEmpty().size
        val filteredResults = result?.results.orEmpty().filter {
            !onlyShowDiffs || cloudEventChangeMap?.containsKey(it.id) == true
        }

        if (filteredResults.size != totalResultCount) {
            Div({
                classes(AggregatorStyles.entitySubInfo)
            }) {
                Text("Showing ${filteredResults.size} of $totalResultCount")
            }
        }

        if (filteredResults.isNotEmpty()) {
            cloudEventTable(
                aggregatorContext,
                filteredResults,
                expanded,
                onlyShowDiffs,
                cloudEventChangeMap,
                { expanded = it })
        } else {
            val oldestResult = result?.results.orEmpty().lastOrNull()
            val newestResult = result?.results.orEmpty().firstOrNull()

            if (oldestResult != null && newestResult != null) {
                Div {
                    Div({
                        style {
                            fontWeight("bold")
                            margin(10.px)
                        }
                    }) {
                        Text("No changes were made to the entity within these event timestamps:")
                    }
                    cloudEventTable(
                        aggregatorContext,
                        listOf(newestResult, oldestResult),
                        emptySet(),
                        false,
                        null,
                        {},
                        onlyShowTimeStamps = true
                    )
                }
            } else {
                if (!loading && eventsPeriod != null) {
                    boxMessage("No events were found")
                }
            }
        }
    }
}

/**
 * Get the audit context for the event. If not found, returns an empty one.
 */
val CloudEvent.auditContext: AuditContext
    get() {
        return this.tracingcontext?.get("auditContext")?.let {
            Json.decodeFromJsonElement(it)
        } ?: AuditContext()
    }


/**
 * Displays a series of cloud events in a table
 */
@Composable
private fun cloudEventTable(
    aggregator: AggregatorService,
    filteredResults: List<CloudEvent>,
    expanded: Set<String>,
    onlyShowDiffs: Boolean,
    cloudEventChangeMap: Map<String, CloudEventChanges>?,
    expandCb: (Set<String>) -> Unit,
    onlyShowTimeStamps: Boolean = false
) {
    table<CloudEvent> {
        items(filteredResults)
        expandable { event ->
            if (!onlyShowDiffs) {
                Div {
                    if (event.type == "Content Update") {
                        event.data?.let {
                            Div {
                                aggregatorSmithyEntityView(aggregator, it.jsonObject)
                            }
                        }
                    } else if (event.type == "Content Update Partial") {
                        (event.data as? JsonObject)?.let {
                            val partial = try {
                                smithyViewDecoder.decodeFromJsonElement<PartialUpdate>(it)
                            } catch (t: Throwable) {
                                t.printStackTrace()
                                null
                            }
                            if (partial != null) {
                                partialEventView(aggregator, partial)
                            } else {
                                jsonView(it, true)
                            }
                        }
                    } else if (event.type.startsWith("com.siriusxm.unifiedcontentbus.")) {
                        event.data?.let {
                            Div {
                                aggregatorSmithyEntityView(aggregator, it.jsonObject)
                            }
                        }
                    } else {
                        (event.data as? JsonObject)?.let {
                            jsonView(it, true)
                        }
                    }
                }
            } else {
                Div {
                    event.data?.let {
                        val changes = cloudEventChangeMap?.get(event.id)
                        val changedKeys = changes?.changedKeys ?: listOf()
                        val diffs = it.jsonObject.filter { changedKeys.contains(it.key) }
                        val diffsJson = JsonObject(diffs)

                        Div({
                            style {
                                backgroundColor(SXMUI.containerContentBackgroundColor.value())
                            }
                        }) {
                            Div {
                                Text("New")
                            }
                            jsonTable(diffsJson, false)
                        }

                        Div({
                            style {
                                backgroundColor(SXMUI.containerContentBackgroundColor.value())
                            }
                        }) {
                            val previousMap = mutableMapOf<String, JsonElement>()
                            changedKeys.forEach {
                                previousMap[it] = (changes?.previousValues?.get(it) ?: JsonNull)
                            }
                            val previousJson = JsonObject(previousMap)
                            Div {
                                Text("Previous")
                            }
                            jsonTable(previousJson, false)
                        }
                    }
                }
            }
        }
        column {
            content { event ->
                Div {
                    Div({ style { margin(3.px, 0.px) } }) {
                        Span({
                            style {
                                fontStyle("italic")
                                opacity(.8)
                            }
                        }) { Text(event.time.toLocalDateTimeString()) }

                        if (!onlyShowTimeStamps) {

                            Span({
                                style {
                                    marginLeft(5.px)
                                    fontWeight(700)
                                }
                            }) {
                                Text(getHumanReadableEventType(event))
                            }
                        }

                        if (onlyShowDiffs) {
                            Span({
                                style {
                                    marginLeft(5.px)
                                    fontWeight(700)
                                }
                            }) {
                                Text(event.id)
                            }

                            if (cloudEventChangeMap?.containsKey(event.id) == true) {
                                Span({
                                    style {
                                        marginLeft(5.px)
                                        fontWeight(700)
                                    }
                                }) {
                                    val changedKeys =
                                        cloudEventChangeMap.get(event.id)?.changedKeys ?: listOf()
                                    Text("Fields changed: (${changedKeys.joinToString(", ")})")
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

/**
 * Renders a partial event.
 * @param onDelete if set, the view will include controls to allow the viewer to send a REMOVE event for this
 * partial. The callback will be invoked if the REMOVE has taken place.
 */
@Composable
fun partialEventView(
    aggregator: AggregatorService,
    partial: PartialUpdate,
    onDelete: (suspend () -> Unit)? = null
) {
    jsonView(partial.modification.jsonObject, true, object : DefaultJsonViewRenderer(true) {
        @Composable
        override fun renderRoot(element: JsonElement) {
            var deleting by remember { mutableStateOf(false) }

            Div({
                style {
                    position(Position.Relative)
                    padding(5.px)
                }
            }) {
                if (onDelete != null) {
                    Div({
                        style {
                            position(Position.Absolute)
                            top(5.px)
                            right(5.px)
                        }
                    }) {
                        icon("delete") {
                            title = "Delete this partial"
                            action {
                                deleting = true
                            }
                        }
                    }
                }

                detailGrid {
                    detail(
                        "Partial",
                        aggregator.partial(partial.partialId)?.name
                            ?: partial.partialId
                    )

                    partial.auditContext?.author?.let {
                        detail("Author", it)
                    }
                    partial.producer?.let { producerId ->
                        aggregator.producerById(producerId)?.name ?: producerId
                    }?.let {
                        detail("Producer", it)
                    }
                }
            }
            Div {
                val entity = partial.modification
                val model = aggregator.entityModel
                val shape = entity.entityType?.let {
                    aggregator.entityType(it)
                }?.shapeId?.let {
                    model?.getShape(it)
                }

                if (!partial.operations.isNullOrEmpty()) {
                    partial.operations?.forEach { operation ->
                        Div {
                            H4({
                                style { margin(0.px, 5.px) }
                            }) {
                                Text(operation.type.name)
                            }
                            val targetShape = shape?.resolveJsonPath(operation.targetPath)
                            val sourceData = (operation.sourcePath ?: operation.targetPath).let {
                                partial.modification.query(it)
                            }.firstOrNull()

                            val viewerOption = SmithyViewOptions()
                            if (targetShape != null && sourceData != null) {
                                if (targetShape is SmithyMember) {
                                    smithyTableView(
                                        listOf(targetShape.name to targetShape),
                                        viewerOption,
                                        buildJsonObject {
                                            put(targetShape.name, sourceData)
                                        }
                                    )
                                } else {
                                    smithyEntityView(targetShape, sourceData)
                                }
                            } else {
                                super.renderRoot(partial.modification)
                            }
                        }
                    }
                } else if (shape != null) {
                    smithyEntityView(shape, partial.modification)
                } else {
                    super.renderRoot(partial.modification)
                }
            }

            if (deleting) {
                confirmationDialog(
                    "Delete this partial?",
                    "Are you sure you'd like to remove this partial? There is no way to undo this. Type 'delete' in the field below to confirm.",
                    "Delete",
                    "delete"
                ) { deleted ->
                    if (deleted) {
                        aggregator.api.partialUpdate(
                            partial.copy(
                                ContentUpdateType.REMOVE,
                                auditContext = aggregator.context.viewer.email?.let {
                                    AuditContext(it)
                                }
                            )
                        )
                        onDelete?.invoke()
                    }
                    deleting = false
                }

            }
        }
    })
}