package com.siriusxm.pia.components

import androidx.compose.runtime.*
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import kotlin.time.Duration
import kotlin.time.Duration.Companion.milliseconds

enum class TooltipLocation {
    TOP,
    BOTTOM,
    LEFT,
    RIGHT
}

/**
 * Renders a tooltip. Wrap this around a component.
 */
@Composable
fun tooltip(
    tooltipContent: @Composable () -> Unit,
    position: TooltipLocation = TooltipLocation.TOP,
    delay: Duration = 750.milliseconds,
    content: @Composable () -> Unit
) {
    var isHovered by remember { mutableStateOf(false) }
    var showTooltip by remember { mutableStateOf(false) }

    LaunchedEffect(isHovered) {
        if (isHovered) {
            delay(delay)
            showTooltip = true
        } else {
            showTooltip = false
        }
    }

    Div({
        style {
            position(Position.Relative)
            display(DisplayStyle.InlineBlock)
        }
        onMouseOver {
            isHovered = true
        }
        onMouseOut {
            isHovered = false
        }
    }) {
        content()

        if (showTooltip) {
            Div({
                style {
                    position(Position.Absolute)
                    backgroundColor(Color("#333"))
                    color(Color.white)
                    padding(5.px)
                    borderRadius(4.px)
                    fontSize(12.px)
                    whiteSpace("nowrap")

                    @OptIn(ExperimentalComposeWebApi::class)
                    when (position) {
                        TooltipLocation.TOP -> {
                            bottom(100.percent)
                            left(50.percent)
                            transform { translateX((-50).percent) }
                        }

                        TooltipLocation.BOTTOM -> {
                            top(100.percent)
                            left(50.percent)
                            transform { translateX((-50).percent) }
                        }

                        TooltipLocation.LEFT -> {
                            right(100.percent)
                            top(50.percent)
                            transform { translateY((-50).percent) }
                        }

                        TooltipLocation.RIGHT -> {
                            left(100.percent)
                            top(50.percent)
                            transform { translateY((-50).percent) }
                        }
                    }
                }
            }) {
                tooltipContent()
            }
        }
    }
}