package com.siriusxm.pia.views.unifiedaggregator

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.utils.Route
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.utils.toAMPMTimeString
import com.siriusxm.pia.utils.toLocalDateTime
import com.siriusxm.pia.views.unifiedaggregator.entitymodel.EntityModel
import contentingestion.aggregator.*
import kotlinx.datetime.Clock
import kotlinx.datetime.Instant
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.A
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text
import kotlin.time.Duration
import kotlin.time.Duration.Companion.days
import kotlin.time.Duration.Companion.hours
import kotlin.time.Duration.Companion.seconds

/**
 * Renders the main data types view, which includes entity types and partials.
 */
@Composable
fun dataTypes(
    context: AggregatorService,
    route: Route
) {
    var selection = "entities"
    var initialSelect: String? = null
    route.switch {
        select("entities") {
            switch {
                select(Regex(".+")) {
                    initialSelect = match.takeIf { it.isNotBlank() }
                }
                default {
                }
            }
        }
        select("partials") {
            selection = "partials"
            switch {
                select(Regex(".*")) {
                    initialSelect = match.takeIf { it.isNotBlank() }
                }
                default {
                }
            }
        }
    }

    serviceContentView({
        title = "Data Types"
        breadcrumbs {
            crumb("Aggregator", "aggregator")
            crumb("Types", null)
        }
    }) {
        tabView {
            this.onSelect {
                context.navigate("aggregator/types/${it}")
                false
            }
            this.selection = selection
            tab("Entity Types", "entities") {
                entityTypes(context, initialSelect = initialSelect)
            }
            if (context.partials.isNotEmpty()) {
                tab("Partial Schemas", "partials") {
                    partials(context, initialSelect = initialSelect)
                }
            }
        }
    }
}

/**
 * Renders the entity types
 */
@Composable
fun entityTypes(
    context: AggregatorService,
    initialSelect: String? = null
) {
    val consumers = context.consumers
    val producers = context.producers

    splitSelectionView<EntityTypeConfiguration> {
        items = context.entityTypes
        selection = initialSelect?.let {
            context.entityTypes.find { it.type == initialSelect }
        }

        renderListItem {
            Text(it.name)
        }

        render {
            entityType(context, it, producers, consumers)
        }

        onSelect {
            context.context.navigate("aggregator/types/entities/${it.type}")
        }

        column {
            title = "Version"
            content {
                Text(it.version)
            }
        }
    }
}

data class Period(
    val name: String,
    val duration: Duration,
    val period: Int
)

val periods = listOf(
    Period("1h", 1.hours, 60),
    Period("3h", 3.hours, 300),
    Period("6h", 6.hours, 600),
    Period("12h", 12.hours, 600),
    Period("1d", 24.hours, 900),
    Period("1w", 7.days, 3600),
)

fun Period.end(ts: Instant = Clock.System.now()): Instant {
    val epochSeconds = ts.epochSeconds
    val truncatedEpochSeconds = epochSeconds - (epochSeconds % this.period)
    return Instant.fromEpochSeconds(truncatedEpochSeconds)
}

fun Period.start(ts: Instant = Clock.System.now()): Instant {
    return end(ts) - duration
}

fun Period.range(ts: Instant = Clock.System.now()): ClosedRange<Instant> {
    return start(ts)..end(ts)
}

fun TimeRange.period(): Period? {
    return periods.find { it.duration == this.relative }
}

data class DataPoint(val ts: Instant, val value: Double)

fun MetricData.allData(period: Period): List<DataPoint> {
    val range = period.range()
    var instant = range.endInclusive
    val results = arrayListOf<DataPoint>()
    do {
        val index = this.timestamps?.indexOfFirst { it == instant }?.takeIf { it >= 0 }
        val value = index?.let { this.values?.get(index) }

        // we don't include the most recent data point if it's null
        if (value != null || instant != range.endInclusive) {
            results += DataPoint(instant, value ?: 0.0)
        }
        instant -= period.period.seconds
    } while (instant > range.start)

    return results.toList().reversed()
}

fun sumMetricData(period: Period, metrics: MetricDataResponseList): List<DataPoint> {
    return metrics.map {
        it.allData(period)
    }.flatten().groupBy {
        it.ts
    }.map { (ts, points) ->
        DataPoint(ts, points.sumOf { it.value })
    }
}

val colors = mapOf(
    "updateEvents" to arrayOf("rgba(200,0,0,1)"),
    "addEvents" to arrayOf("rgba(0, 200, 0, 1)"),
    "removeEvents" to arrayOf("rgba(0, 0, 200, 1)")
)

/**
 * Display information about an entity type
 */
@Composable
fun entityType(
    aggregator: AggregatorService,
    entityType: EntityTypeConfiguration,
    producers: List<ProducerDetails>,
    consumers: List<ConsumerDetails>
) {
    entityView({
        title = entityType.name
        subTitle = entityType.type + ":" + entityType.version
    }) {
        tabView {
            if (aggregator.entityModel != null) {
                tab("Definition") {
                    box({
                        paddedContent = false
                    }) {
                        EntityModel(aggregator, entityType)
                    }
                }
            }

            tab("Roles") {
                box("Producers", {
                    paddedContent = false
                }) {
                    val matchedProducers =
                        producers.filter { it.allowedTypes.orEmpty().contains(entityType.type) }
                    if (matchedProducers.isEmpty()) {
                        boxMessage("No producers found for this type")
                    } else {
                        table<ProducerDetails> {
                            items(matchedProducers)
                            column {
                                content {
                                    A("#aggregator/producers/${encodeURIComponent(it.id)}") {
                                        Text(it.name.orEmpty())
                                    }
                                }
                            }
                        }
                    }
                }

                box("Consumers", {
                    paddedContent = false
                }) {
                    val matchedConsumers = consumers.filter { it.types.orEmpty().contains(entityType.type) }
                    if (matchedConsumers.isEmpty()) {
                        boxMessage("No consumers found for this type.")
                    } else {
                        table<ConsumerDetails> {
                            items(matchedConsumers)
                            column {
                                content {
                                    A("#aggregator/consumers/${encodeURIComponent(it.id)}") {
                                        Text(it.name)
                                    }
                                }
                            }
                        }
                    }
                }
            }

            tab("Metrics") {
                EntityTypeMetrics(aggregator, entityType)
            }
        }
    }
}

@Composable
fun EntityTypeMetrics(aggregator: AggregatorService, entityType: EntityTypeConfiguration) {
    var metricsPeriod by remember {
        mutableStateOf(periods.first())
    }
    var loadedMetrics by remember { mutableStateOf<List<MetricData>?>(null) }

    LaunchedEffect(entityType.type, metricsPeriod) {
        loadedMetrics = null
        val range = metricsPeriod.range()
        try {
            loadedMetrics = aggregator.api.getMetrics(
                GetMetricDataRequest(
                    range.start, range.endInclusive, listOf("update", "add", "remove").map { updateType ->
                        MetricDataRequest(
                            "${updateType}Events",
                            null,
                            MetricStat(
                                "AtlasEntityPublished",
                                "aggregator/${aggregator.context.configuration.stage}",
                                listOf(
                                    Dimension("EntityType", entityType.type),
                                    Dimension("UpdateType", "com.siriusxm.unifiedcontentbus.${updateType}")
                                ),
                                metricsPeriod.period,
                                "Sum"
                            )
                        )
                    }
                )
            ).data
        } catch (t: Throwable) {
            t.printStackTrace()
        }
    }

    Div({
        style {
            display(DisplayStyle.Flex)
            justifyContent(JustifyContent.FlexEnd)
            width(100.percent)
            padding(5.px, 0.px)
        }
    }) {
        timeRangeSelect(selected = TimeRange(relative = metricsPeriod.duration)) {
            metricsPeriod = it.period() ?: periods.first()
        }
    }

    waitUntilNonNull(loadedMetrics) { metrics ->
        box({
            title = "Published Entities"
        }) {
            Chart(metrics.map {
                new {
                    label = it.id.replace("Events", "")
                    data = it.allData(metricsPeriod).map { value ->
                        new<DatasetPoint> {
                            y = value.value.toInt()
                            x = value.ts.toLocalDateTime().toAMPMTimeString(false, false)
                        }
                    }.toTypedArray()
                }
            })
        }
    }
}

/**
 * Renders partial schemas
 */
@Composable
fun partials(aggregator: AggregatorService, initialSelect: String? = null) {
    splitSelectionView<PartialSchema> {
        items = aggregator.partials
        selection = initialSelect?.let {
            aggregator.partials.find { it.id == initialSelect }
        }

        renderListItem {
            Text(it.name ?: it.id)
        }

        render {
            partial(aggregator, it)
        }

        onSelect {
            aggregator.context.navigate("aggregator/types/partials/${it.id}")
        }

        column {
            content {
                it.description?.let {
                    Text(it)
                }
            }
        }
    }
}

@Composable
fun partial(aggregator: AggregatorService, schema: PartialSchema) {
    entityView({
        title = schema.name ?: schema.id
        subTitle = schema.id
    }) {
        box("Producers", {
            paddedContent = false
        }) {
            val matchedProducers =
                aggregator.producers.filter { it.allowedPartials.orEmpty().contains(schema.id) }
            if (matchedProducers.isEmpty()) {
                boxMessage("No producers produce this partial")
            } else {
                table<ProducerDetails> {
                    items(matchedProducers)
                    column {
                        content {
                            A("#aggregator/producers/${encodeURIComponent(it.id)}") {
                                Text(it.name.orEmpty())
                            }
                        }
                    }
                }
            }
        }

        box("Types", {
            paddedContent = false
            header({
                instruction = "These types can be modified"
            })
        }) {
            table<String> {
                this.items(schema.types)
                column {
                    content {
                        val entityTypeId = it.substringAfterLast("/", "")
                            .substringBefore(":").ifBlank { null }
                        val entityType = entityTypeId?.let { aggregator.entityType(entityTypeId) }
                        val label = entityType?.name ?: entityTypeId
                        label?.let {
                            A(href = "#aggregator/types/entities/${entityTypeId}") {
                                Text(it)
                            }
                        }
                    }
                }
            }
        }

        box("Fields", {
            paddedContent = false
            header({
                instruction = "These fields are modified"
            })
        }) {
            table<String> {
                this.items(schema.paths)
                column {
                    content {
                        Text(it.removePrefix("$."))
                    }
                }
            }
        }
    }
}