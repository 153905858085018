package com.siriusxm.pia

import kotlinx.serialization.Serializable

@Serializable
data class Configuration(
    val stage: String,
    val authUrl: String,
    val clientId: String,
    val apiUrl: String,
    val transcriptionApiUrl: String,
    val transcriptionEditorBaseUrl: String?,
    val unifiedContentBusApiUrl: String,
    val atlasImageServerBaseUrl: String,
    val artistStationApiUrl: String,
    val mddbApiUrl: String,
    val unifiedAggregatorApiUrl: String,
    val epgApiUrl: String,
    val ingestionAuditUrl: String,
    val datadogLookupAtlasBusById: String,
    val sportsApiUrl: String? = null,
    val applications: List<ApplicationDescriptor>? = null,
    val datadogLookupByJobId: String,
    val pkceEnabled: String
)

@Serializable
data class ApplicationDescriptor(
    val name: String,
    val description: String? = null,
    val path: String? = null,
    val requiredGroup: List<String>? = null,
    val initOnStartup: Boolean = false
)
