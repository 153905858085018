package com.siriusxm.pia.rest.unifiedaggregator

import com.siriusxm.pia.utils.diffJsonArrays
import com.siriusxm.pia.utils.diffJsonObjects
import kotlinx.serialization.json.*

data class CloudEventChanges(
    val changedKeys: List<String>,
    val previousValues: Map<String, JsonElement?>
)

fun getCloudEventChanges(current: CloudEvent, previous: CloudEvent): CloudEventChanges {
    val currentJson = current.data?.jsonObject
    val previousJson = previous.data?.jsonObject

    val differingKeys = mutableListOf<String>()
    val previousValueMap = mutableMapOf<String, JsonElement?>()

    val allKeys = ((currentJson?.keys ?: listOf()) + (previousJson?.keys
        ?: listOf())).distinct() // Union of keys in both objects
    for (key in allKeys) {
        val newValue = currentJson?.get(key)
        val previousValue = previousJson?.get(key)

        // Check if the values differ
        if (newValue != previousValue) {
            console.log("${current.id} - $key: $previousValue -> $newValue")

            val newValueJsonObject = when (newValue) {
                is JsonPrimitive -> {
                    null
                }

                is JsonArray -> {
                    JsonArray(newValue.sortedBy { it.getString("id") })
                }

                else -> {
                    newValue?.jsonObject
                }
            }
            val previousValueJsonObject = when (previousValue) {
                is JsonPrimitive -> {
                    null
                }

                is JsonArray -> {
                    JsonArray(previousValue.sortedBy { it.getString("id") })
                }

                else -> {
                    previousValue?.jsonObject
                }
            }
            if (newValueJsonObject != null && newValueJsonObject is JsonObject &&
                previousValueJsonObject != null && previousValueJsonObject is JsonObject
            ) {
                val truncatedValue = diffJsonObjects(previousValueJsonObject, newValueJsonObject)
                previousValueMap[key] = truncatedValue
                differingKeys.add(key)
            } else if (newValueJsonObject != null && newValueJsonObject is JsonArray &&
                previousValueJsonObject != null && previousValueJsonObject is JsonArray
            ) {
                val truncatedValue = diffJsonArrays(previousValueJsonObject, newValueJsonObject)
                if (truncatedValue?.isEmpty() == false) {
                    previousValueMap[key] = truncatedValue
                    differingKeys.add(key)
                }
            } else {
                previousValueMap[key] = previousValue
                differingKeys.add(key)
            }
        }
    }

    return CloudEventChanges(differingKeys, previousValueMap)
}
