package com.siriusxm.pia.views.unifiedaggregator.management

import androidx.compose.runtime.*
import com.siriusxm.pia.components.*
import com.siriusxm.pia.rest.unifiedaggregator.getConsumerUsers
import com.siriusxm.pia.rest.unifiedaggregator.getProducerUsers
import com.siriusxm.pia.showError
import com.siriusxm.pia.utils.encodeURIComponent
import com.siriusxm.pia.views.unifiedaggregator.AggregatorService
import contentingestion.aggregator.ServiceUser
import contentingestion.aggregator.ServiceUserRole
import contentingestion.aggregator.ServiceUsersList
import org.jetbrains.compose.web.css.padding
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Div
import org.jetbrains.compose.web.dom.Text

enum class ServiceUsersType {
    CONSUMER,
    PRODUCER
}

/**
 * Displays the service users and allows for adding/removing.
 */
@Composable
fun ServiceUsersBox(
    aggregator: AggregatorService, serviceId: String,
    type: ServiceUsersType
) {

    val canEdit = aggregator.isAdmin()
    var editing by remember { mutableStateOf<ServiceUser?>(null) }
    var users by remember { mutableStateOf<ServiceUsersList?>(null) }

    LaunchedEffect(serviceId) {
        users = null
        users = when (type) {
            ServiceUsersType.CONSUMER -> {
                aggregator.api.getConsumerUsers(serviceId)
            }

            ServiceUsersType.PRODUCER -> {
                aggregator.api.getProducerUsers(serviceId)
            }
        }
    }

    suspend fun deleteUser(userId: String) {
        when (type) {
            ServiceUsersType.CONSUMER -> {
                aggregator.api.deleteConsumerUser(encodeURIComponent(serviceId), userId)
            }

            ServiceUsersType.PRODUCER ->
                aggregator.api.deleteProducerUser(encodeURIComponent(serviceId), userId)
        }
    }

    if (users != null && (aggregator.isAdmin() || !users.isNullOrEmpty())) {
        box("Authorized User Agents", {
            header({
                instruction = "These users can act as agents of this service within the Management Console."
            })
            paddedContent = false

            if (canEdit) {
                this.action {
                    title = "Add"
                    primary = false
                    action {
                        editing = ServiceUser("")
                    }
                }
            }

        }) {
            editing?.let {
                Div({ style { padding(10.px) } }) {
                    serviceUserEditor(aggregator, it, serviceId, type) { updated ->
                        if (updated != null) {
                            users = users.orEmpty().replaceOrAppend(updated) { o1, o2 ->
                                o1.id == o2.id
                            }
                        }
                        editing = null
                    }
                }
            }

            if (users.isNullOrEmpty()) {
                if (editing == null) {
                    boxMessage("This service has no agents.")
                }
            } else {
                table<ServiceUser> {
                    items(users)

                    column {
                        content {
                            Text(it.id)
                        }
                    }
                    column {
                        content {
                            Text(it.roles.orEmpty().joinToString(", "))
                        }
                    }

                    if (canEdit) {
                        column {
                            width = 20.px
                            content { user ->
                                iconAction("delete") {
                                    deleteUser(user.id)
                                    users = users.orEmpty().filter { it.id != user.id }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/**
 * Editor for adding a new target to a consumer
 */
@Composable
fun serviceUserEditor(
    aggregator: AggregatorService, user: ServiceUser,
    serviceId: String,
    serviceType: ServiceUsersType,
    onComplete: suspend (ServiceUser?) -> Unit
) {
    var id by remember { mutableStateOf<String?>(user.id) }
    var roles by remember { mutableStateOf(user.roles.orEmpty()) }

    suspend fun saveUser(serviceUser: ServiceUser) {
        try {
            when (serviceType) {
                ServiceUsersType.CONSUMER -> {
                    aggregator.api.addConsumerUser(encodeURIComponent(serviceId), serviceUser)
                }

                ServiceUsersType.PRODUCER -> {
                    aggregator.api.addProducerUser(encodeURIComponent(serviceId), serviceUser)
                }
            }
            onComplete(serviceUser)
        } catch (e: Throwable) {
            aggregator.context.showError("Unable to add user", e)
        }
    }

    dialogView("Add target") {
        content {
            dialogField("User id", "The user's id (the part of their email before the @)") {
                simpleTextField(id) {
                    id = it
                }
            }
            dialogField("Roles") {
                listOf(ServiceUserRole.ADMIN).forEach { role ->
                    simpleCheckBox("Admin", roles.contains(role), true) {
                        if (it) {
                            roles = (roles + role).distinct()
                        } else {
                            roles = roles - role
                        }
                    }
                }
            }
        }

        action {
            title = "Add"
            showProgressOnAction = true
            enabled = id.orEmpty().length > 5
            this.action {
                val validatedId = id?.substringBefore('@').orEmpty()
                if (validatedId.length > 4) {
                    saveUser(ServiceUser(validatedId, roles))
                }
            }
        }
        action {
            title = "Cancel"
            enabled = true
            this.action {
                onComplete(null)
            }
        }
    }
}

/**
 * Replace the item in the list (matching using the provided function), or
 * append it to the end.
 */
fun <T : Any> List<T>.replaceOrAppend(newItem: T, match: (T, T) -> Boolean): List<T> {
    return if (this.any { match(it, newItem) }) {
        this.map { if (match(it, newItem)) newItem else it }
    } else {
        this + newItem
    }
}