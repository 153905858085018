package com.siriusxm.pia.utils

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.State
import androidx.compose.runtime.collectAsState
import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlin.coroutines.CoroutineContext
import kotlin.coroutines.EmptyCoroutineContext

/**
 * A state flow that triggers on the first load.
 */
class LazyLoadedState<T>(
    val loader: suspend () -> T,
) {
    private val flow = MutableStateFlow<T?>(null)
    private val stateFlow = flow.asStateFlow()
    private var loaded: Boolean = false

    @Composable
    fun collect(context: CoroutineContext = EmptyCoroutineContext): State<T?> {
        LaunchedEffect(Unit) {
            if (!loaded) {
                flow.value = loader()
                loaded = true
            }
        }
        return stateFlow.collectAsState(context)
    }

    fun value(): T? = flow.value

    /**
     * Update the value.
     */
    fun update(value: T) {
        flow.value = value
    }

    /**
     * Invokes the loader.
     */
    suspend fun reload() {
        flow.value = loader()
    }
}